import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { Button, Tooltip } from "flowbite-react";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import BranchSwitcher from "../../components/BranchSwitcher";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import ProductSearch from "../../components/ProductSearch";
import toast from "react-hot-toast";
import { useReturnWithOutIdMutation } from "../../redux/queries/order";
import { GoTrash } from "react-icons/go";
import CustomSelect from "../../components/CustomSelect";
import { useGetCompaniesQuery } from "../../redux/queries/company";
import { updateInvoiceMeta } from "../../redux/slices/cart";
import { useLazyGetUsersQuery } from "../../redux/queries/users";

const Returns = () => {
  const dispatch = useAppDispatch();
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const { invoiceMeta } = useAppSelector((state) => state.cartAndDraft);
  const [customerFilter, setCustomerFilter] = useState({
    limit: "0,20",
    name: "",
    type: "pharmacy",
    status: 1,
  });
  const [returns, setReturns] = useState<RWIItem[]>([]);
  const [branch, setBranch] = useState(defaultBranchUUid);
  const [returnWithOutId, { isLoading }] = useReturnWithOutIdMutation();
  const { data: companies } = useGetCompaniesQuery(customerFilter);
  const [getCustomers, { data: customersList }] = useLazyGetUsersQuery();

  const addItem = (
    uuid: string,
    price: string | number,
    selectedItem?: Product
  ) => {
    const item = selectedItem;

    if (!item) return;

    let payload = {
      uuid: uuid,
      price: Number(price),
      name: item.name,
      quantity: item.quantity,
      company: item.company.uuid,
      category: item.category,
    };

    const check = returns.some((obj: RWIItem) => obj.uuid === uuid);

    if (check) {
      toast.error("Products already exist!!!");
    } else {
      const newReturns = [...returns, payload];
      setReturns(newReturns);
      utills._play_sound();
    }
  };

  const onSubmit = async () => {
    const payload = {
      user: invoiceMeta?.customer?.value,
      items: [...returns],
      refund_type: "cash",
    };
    const res = await returnWithOutId(payload);

    if ("data" in res) {
      if (res?.data?.status === 200) {
        toast.success(res.data.message);
        setReturns([]);
      }
    } else {
      toast.error("Failed to Return");
    }
  };

  const handleCustomerSelect = (data: any) => {
    dispatch(
      updateInvoiceMeta({
        delevery_fee: invoiceMeta.delevery_fee,
        customer: {
          label: "",
          value: "",
        },
        company: data,
      })
    );
    getCustomers({
      company: data.value,
      status: 1,
      limit: "0,50",
    });
  };

  return (
    <div>
      <Breadcrumb title="Returns Without Id" />

      <div className="mt-3">
        <div>
          <div className="flex-1 my-4">
            <div className="flex gap-3">
              <BranchSwitcher
                sizing="md"
                styling={{ borderRadius: 3 }}
                onChange={(text) => setBranch(text)}
              />
              <div className="w-[300px]">
                <CustomSelect
                  isClearable={false}
                  defaultValue={{ ...invoiceMeta.company, disabled: false }}
                  placeholder="Select Customer..."
                  inputClasses="h-[43px] pt-1 rounded-sm"
                  options={
                    companies?.data.map((customer) => ({
                      label: customer.name,
                      value: customer.uuid,
                    })) ?? []
                  }
                  onSelect={(val) =>
                    handleCustomerSelect({ label: val.label, value: val.value })
                  }
                  onSearch={(text) =>
                    setCustomerFilter((prev) => ({ ...prev, name: text }))
                  }
                />
              </div>
              <div className="w-[300px]">
                <CustomSelect
                  defaultValue={{ ...invoiceMeta.customer, disabled: false }}
                  isClearable={false}
                  placeholder="Select Staff..."
                  inputClasses="h-[43px] pt-1 rounded-sm"
                  isSearchable={false}
                  options={
                    customersList?.data.map((customer) => ({
                      label: customer.name,
                      value: customer.uuid,
                    })) ?? []
                  }
                  onSelect={(val) => {
                    const userName = customersList?.data.find(
                      (item) => item.uuid === val.value
                    );
                    if (userName) {
                      dispatch(
                        updateInvoiceMeta({
                          delevery_fee: invoiceMeta.delevery_fee,
                          company: invoiceMeta.company,
                          customer: {
                            label: userName.name,
                            value: val.value,
                          },
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center mt-5">
          <div className="flex-1 relative">
            <ProductSearch
              companyId={branch}
              onSelect={(item) => {
                addItem(item.uuid, item.price, item);
              }}
              error="Please Select Branch"
            />
          </div>
        </div>
      </div>

      <div className="mt-10">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-8">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Product
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                <th scope="col" className="px-6 py-3">
                  Rate
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {returns.map((item: RWIItem) => (
                <tr
                  key={item.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="p-4">
                    <ImageView className="w-8 h-8" url={utills._default_img} />
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.name}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <button
                        className="inline-flex items-center justify-center p-1 me-3 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => {
                          if (item.quantity === 1) {
                            utills._play_error_sound();
                            return;
                          } else {
                            const findItem = returns.find(
                              (obj) => obj.uuid === item.uuid
                            );

                            if (findItem) {
                              findItem.quantity -= 1;
                              const newReturns = [...returns];
                              setReturns(newReturns);
                              utills._play_sound();
                            }
                          }
                        }}
                      >
                        <span className="sr-only">Quantity button</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 2"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1h16"
                          />
                        </svg>
                      </button>
                      <div>
                        <input
                          type="number"
                          step="any"
                          id={item.uuid}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              const element =
                                document.getElementById("searchFields");
                              element?.focus();
                            }
                          }}
                          className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="1"
                          min={1}
                          value={item.quantity}
                          onChange={(e) => {
                            const findItem = returns.find(
                              (obj) => obj.uuid === item.uuid
                            );

                            if (findItem) {
                              findItem.quantity = Number(e.target.value);
                              const newReturns = [...returns];
                              setReturns(newReturns);
                              utills._play_sound();
                            }
                          }}
                        />
                      </div>
                      <button
                        className="inline-flex items-center justify-center h-6 w-6 p-1 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => {
                          const findItem = returns.find(
                            (obj) => obj.uuid === item.uuid
                          );

                          if (findItem) {
                            findItem.quantity += 1;
                            const newReturns = [...returns];
                            setReturns(newReturns);
                            utills._play_sound();
                          }
                        }}
                      >
                        <span className="sr-only">Quantity button</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 18"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 1v16M1 9h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    <div>
                      <input
                        type="number"
                        id={item.uuid}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            const element =
                              document.getElementById("searchFields");
                            element?.focus();
                          }
                        }}
                        className="bg-gray-50 w-1/2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        value={item.price}
                        onChange={(e) => {
                          const findItem = returns.find(
                            (obj) => obj.uuid === item.uuid
                          );

                          if (findItem) {
                            findItem.price = Number(e.target.value);
                            const newReturns = [...returns];
                            setReturns(newReturns);
                            utills._play_sound();
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {utills._currency_format(item.price * item.quantity, "NGN")}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-3">
                      <Tooltip content="Remove Item">
                        <span
                          onClick={() => {
                            const newReturn = returns.filter(
                              (obj) => obj.uuid !== item.uuid
                            );
                            setReturns(newReturn);
                          }}
                          className="font-medium text-red-600 dark:text-red-500"
                        >
                          <GoTrash />
                        </span>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-10 flex-1 flex flex-col items-end">
        <Button
          isProcessing={isLoading}
          disabled={returns.length === 0}
          onClick={onSubmit}
          className="rounded-sm"
        >
          Request Return
        </Button>
      </div>
    </div>
  );
};

export default Returns;
