import React, { useCallback, useEffect, useMemo, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Banner,
  Button,
  Label,
  Select,
  Spinner,
  Table,
  TextInput,
  Tooltip,
} from "flowbite-react";
import { IoIosAdd, IoMdRemoveCircleOutline } from "react-icons/io";
import { BsBookshelf, BsPlus } from "react-icons/bs";
import Modal from "react-responsive-modal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { LiaTimesSolid } from "react-icons/lia";
import BranchSwitcher from "../../components/BranchSwitcher";
import { useAppSelector } from "../../lib/hook";
import {
  useCreateShelfMutation,
  useDeleteShelfMutation,
  useDeleteShelfStockMutation,
  useGetShelfsQuery,
  useMoveItemMutation,
  useUpdateShelfMutation,
} from "../../redux/queries/shelf";
import { FaSearch } from "react-icons/fa";
import toast from "react-hot-toast";
import { MdOutlineLockReset } from "react-icons/md";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import emptyIcon from "../../assets/empty.png";
import { CiCircleMore } from "react-icons/ci";
import { IoTrashOutline } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import ShelfItems from "./ShelfItems";
import CustomDropdown from "../../components/CustomDropdown";
import { IoMoveSharp } from "react-icons/io5";

const Shelf = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [activeId, setActiveId] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedShelf, setSelectedShelf] = useState<number | null>(null);
  const [filters, setFilters] = useState({
    company: defaultBranchUUid,
  });
  const [filterFields, setFilterFields] = useState({
    company: "",
    product_name: "",
  });

  const { data: shelfs, isLoading } = useGetShelfsQuery(filters);
  // const [productsFilters, setProductsFilters] = useState({
  //   limit: "0,10",
  //   name: "",
  // });

  // const { data: products } = useGetProductsQuery({
  //   ...productsFilters,
  //   company: filters.company,
  // });

  const editShelf = (id: number) => {
    setSelectedShelf(id);
    setIsOpen(true);
  };

  const handleSearch = () => {
    if (filterFields.company.length > 1) {
      setFilters(filterFields);
    } else {
      setFilters((prev) => ({
        ...prev,
        product_name: filterFields.product_name,
      }));
    }
  }

  console.log(shelfs)

  useEffect(() => {
    if (!shelfs) return;
    if (shelfs.data.length > 0) {
      const [firstShelf] = shelfs.data;
      setActiveId(firstShelf.id);
    }
  }, [isLoading]);

  const activeItem = useMemo(() => {
    return shelfs?.data?.find((item) => item.id === activeId);
  }, [shelfs, activeId]);

  return (
    <div>
      <Breadcrumb
        title="Order List"
        rightButton={
          <Button size="xs" onClick={() => setIsOpen(true)}>
            <IoIosAdd className="mr-2 h-5 w-5" /> Create Shelf
          </Button>
        }
      />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Item Shelfs.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Manage shelfs and see all data of each shelf.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <BsBookshelf className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="mt-5 p-3 bg-white dark:bg-gray-800 rounded-sm mb-5">
        <div className="grid grid-cols-4 gap-3">
          <BranchSwitcher
            onChange={(val) =>
              setFilterFields((prev) => ({
                ...prev,
                company: val,
              }))
            }
          />
          <div>
            <TextInput
              value={filterFields.product_name}
              sizing="sm"
              type="text"
              placeholder="Search by Product Name"
              onChange={(e) => {
                setFilterFields((prev) => ({
                  ...prev,
                  product_name: e.target.value,
                }));
              }}
            />

            {/* <CustomSelect
              onSelect={(val) =>
                setFilterFields((prev) => ({
                  ...prev,
                  product_name: val.value,
                }))
              }
              inputClasses="h-[43px] pt-1"
              options={
                products?.data.map((item) => ({
                  label: item.name,
                  value: item.uuid,
                })) ?? []
              }
              onSearch={(text) =>
                setProductsFilters((prev) => ({ ...prev, name: text }))
              }
              placeholder="Search by Product Name"
            /> */}
          </div>
          <div>
            <button
              onClick={handleSearch}
              type="button"
              className="p-2.5 ms-2 text-sm font-medium text-white bg-[#167490] rounded-lg border border-[#167490] hover:bg-[#167490]/80 focus:ring-4 focus:outline-none"
            >
              <FaSearch />
            </button>
            <button
              onClick={() => {
                setFilters({ company: defaultBranchUUid });
                setFilterFields({
                  company: "",
                  product_name: "",
                });
              }}
              type="button"
              className="p-2.5 ms-2 text-sm font-medium text-[#167490] bg-transparent rounded-lg border border-[#167490] hover:bg-[#167490]/80 hover:text-white"
            >
              <MdOutlineLockReset />
            </button>
          </div>
        </div>
      </div>

      {shelfs?.data.length === 0 && (
        <div className="flex items-center justify-center my-5">
          <div className="flex flex-col items-center">
            <img src={emptyIcon} alt="empty record" className="w-20" />
            <span className="dark:text-gray-400 mt-2 text-xs">No record</span>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center items-center mt-5 gap-2">
          <Spinner />
          <Spinner />
          <Spinner />
        </div>
      )}

      <div className="flex gap-5">
        <div>
          {shelfs?.data.map((shelf) => (
            <ShelfCard
              key={shelf.id}
              shelf={shelf}
              activeId={activeId}
              clickShelf={(x) => setActiveId(x)}
              edit={editShelf}
            />
          ))}
        </div>
        <div className="flex-1">
          <ShelfDetails shelf={activeItem} />
        </div>
      </div>

      <Modal
        blockScroll={false}
        open={isOpen}
        showCloseIcon={false}
        onClose={() => {
          setIsOpen(false);
          setSelectedShelf(null);
        }}
        center
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
      >
        <ShelfForm
          close={() => {
            setIsOpen(false);
            setSelectedShelf(null);
          }}
          selectedShelf={selectedShelf}
          item={
            selectedShelf
              ? shelfs?.data.find((x) => x.id === selectedShelf)
              : undefined
          }
        />
      </Modal>
    </div>
  );
};

export default Shelf;

interface ISheldDetails {
  shelf?: SingleShelf;
}
const ShelfDetails = React.memo((props: ISheldDetails) => {
  const { shelf } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [screen, setScreen] = useState("");
  const [details, setDetails] = useState<any>();
  const [deleteStock] = useDeleteShelfStockMutation();

  const handleDelete = useCallback(
    (uuid: string) => {
      const action = window.confirm("Are you sure you want to delete");
      if (action)
        utills._asynchronous_toast(
          deleteStock,
          "Record Deleted",
          "Unable to delete",
          uuid
        );
    },
    [deleteStock]
  );

  if (!shelf) {
    return null;
  }

  return (
    <div className="w-full sticky top-16">
      <div className="bg-[#167490] text-white flex items-center justify-between h-10 px-3">
        <span>#{shelf.id}</span>
        <span>{shelf.name}</span>
      </div>
      <div className="py-3">
        <div className="flex justify-end">
          <Button
            size="xs"
            color="light"
            className="rounded-md"
            onClick={() => {
              setIsOpen(true);
              setScreen("ITEMS");
            }}
          >
            <BsPlus className="mr-2 h-5 w-5" />
            Add product to shelf
          </Button>
        </div>
        <div className="my-5">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell>#</Table.HeadCell>
              <Table.HeadCell>Item Name</Table.HeadCell>
              <Table.HeadCell>Unit</Table.HeadCell>
              <Table.HeadCell>Base Unit</Table.HeadCell>
              <Table.HeadCell>Total qty available</Table.HeadCell>
              <Table.HeadCell>Action</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y ">
              {shelf.stocks.map((stock) => {
                const imgurl =
                  stock.items.images_links[0] ===
                  "https://dev-assets.gohealthy.ng/items/"
                    ? utills._default_img
                    : stock.items.images_links[0];
                return (
                  <Table.Row
                    key={stock.id}
                    className="bg-white dark:border-gray-800 dark:bg-gray-800"
                  >
                    <Table.Cell>
                      <ImageView className="w-6 h-6" url={imgurl} />
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap truncate max-w-[250px] font-medium text-gray-900 dark:text-white">
                      {stock.items.name}
                    </Table.Cell>
                    <Table.Cell>{stock.items.unit}</Table.Cell>
                    <Table.Cell>{stock.items.base_unit}</Table.Cell>
                    <Table.Cell>{stock.items.quantity}</Table.Cell>
                    <Table.Cell>
                      <div onClick={(e) => e.stopPropagation()}>
                        <CustomDropdown
                          trigger={
                            <div className="">
                              <CiCircleMore className="text-2xl" />
                            </div>
                          }
                          menu={[
                            {
                              icon: IoTrashOutline,
                              title: "Delete",
                              action: () => handleDelete(stock.uuid),
                            },
                            {
                              icon: IoMoveSharp,
                              title: "Move",
                              action: () => {
                                setIsOpen(true);
                                setScreen("MOVE");
                                setDetails(stock);
                              },
                            },
                          ]}
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {shelf.stocks.length === 0 && (
            <div className="flex items-center justify-center my-5">
              <div className="flex flex-col items-center">
                <img src={emptyIcon} alt="empty record" className="w-20" />
                <span className="dark:text-gray-400 mt-2 text-xs">
                  No record
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={isOpen}
        showCloseIcon={false}
        onClose={() => setIsOpen(false)}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
      >
        {screen === "ITEMS" && (
          <ShelfItems close={() => setIsOpen(false)} shelf={shelf} />
        )}
        {screen === "MOVE" && (
          <MoveItemToShelf close={() => setIsOpen(false)} stock={details} />
        )}
      </Modal>
    </div>
  );
});

interface IMove {
  close: () => void;
  stock: any;
}

const MoveItemToShelf = (props: IMove) => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const { close, stock } = props;
  const [shelfId, setShelfId] = useState("");
  const { data: shelfs } = useGetShelfsQuery({ company: defaultBranchUUid });
  const [moveItem, { isLoading }] = useMoveItemMutation();

  const handleSubmit = async () => {
    const payload = {
      stock_uuid: stock?.uuid,
      item_uuid: stock?.item_uuid,
      to_shelf_uuid: shelfId,
    };
    const res = await moveItem(payload);
    if ("data" in res) {
      const { data } = res;
      if (data?.status === 200) {
        toast.success(data?.message);
        close();
      } else {
        toast.error("Failed to Move!!");
      }
    } else {
      toast.error("Failed to Move!!");
    }
  };

  return (
    <div className="w-[600px]">
      <div className="bg-[#167490] flex items-center px-3 justify-between h-10">
        <span className="text-white font-semibold text-sm">
          Move {stock?.items?.name} to Another Shelf
        </span>
        <button className="text-white" onClick={close}>
          <LiaTimesSolid />
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 pb-3">
        <div className="py-5 px-3">
          <Label>Shelfs</Label>

          <Select
            sizing="md"
            style={{ borderRadius: 0 }}
            className="mt-2"
            onChange={(e) => setShelfId(e.target.value)}
          >
            <option value="" hidden>
              Select Shelf
            </option>
            {shelfs?.data?.map((shelf) => (
              <option value={shelf.uuid} key={shelf.uuid}>
                {shelf.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="mt-5 flex justify-end items-end p-3">
          <Button
            disabled={!shelfId.length}
            isProcessing={isLoading}
            size="xs"
            className="rounded-sm"
            onClick={handleSubmit}
          >
            Move
          </Button>
        </div>
      </div>
    </div>
  );
};

interface IShelfCard {
  shelf: SingleShelf;
  activeId: number;
  clickShelf: (id: number) => void;
  edit: (id: number) => void;
}
const ShelfCard = (props: IShelfCard) => {
  const { shelf, activeId, clickShelf, edit } = props;
  const [deleteShelf] = useDeleteShelfMutation();

  const deleteHandler = (id: string) => {
    const action = window.confirm("Are you sure you want to delete");
    if (action)
      utills._asynchronous_toast(
        deleteShelf,
        "Record Deleted",
        "Unable to delete",
        id
      );
  };
  return (
    <div
      onClick={() => clickShelf(shelf.id)}
      className="border rounded-sm border-gray-400 dark:border-gray-700 bg-white dark:bg-gray-800 w-[300px] mb-4 cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-900"
    >
      <div
        className={`flex items-center justify-between gap-3 p-2 ${
          activeId === shelf.id
            ? "bg-[#167490] text-white"
            : "bg-transparent text-[#167490]"
        } rounded-t-sm border-b-2 dark:border-gray-700`}
      >
        <div className="flex flex-1 items-center gap-3">
          <BsBookshelf />{" "}
          <span className="text-[#12px] w-[200px] font-semibold truncate ...">
            {shelf.name}
          </span>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <CustomDropdown
            trigger={
              <div className="">
                <CiCircleMore className="text-2xl" />
              </div>
            }
            menu={[
              {
                icon: FaRegEdit,
                title: "Edit",
                action: () => edit(shelf.id),
              },
              {
                icon: IoTrashOutline,
                title: "Delete",
                action: () => deleteHandler(shelf.uuid),
              },
            ]}
          />
        </div>
      </div>
      <div className="p-3 dark:text-gray-200 text-xs flex justify-between items-center">
        <span>Total Quantity in shelf</span>
        <span>{shelf.quantity}</span>
      </div>
    </div>
  );
};

interface IFormInput {
  name: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Shelf name is required"),
});

interface IShelfForm {
  close: () => void;
  selectedShelf: number | null;
  item?: SingleShelf;
}

const ShelfForm = (props: IShelfForm) => {
  const [createShelf, { isLoading }] = useCreateShelfMutation();
  const { close, item } = props;
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [companyId, setCompanyId] = useState(defaultBranchUUid);
  const [updateShelf, { isLoading: uLoading }] = useUpdateShelfMutation();
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const loading = item ? uLoading : isLoading;

  const onSubmit = async (data: IFormInput) => {
    const payload = { ...data, company: companyId };
    if (item) {
      const updatePayload = { data: { ...item, ...payload }, uuid: item.uuid };
      const res = await updateShelf(updatePayload);
      if ("data" in res) {
        if (res?.data?.status === 200) {
          toast.success("Shelf Updated Successfully");
          close();
        } else toast.error("Unable to update record");
      } else toast.error("Unable to update record");
    } else {
      const res = await createShelf(payload);
      if ("data" in res) {
        if (res?.data?.status === 200) {
          toast.success("Shelf Created Successfully");
          close();
        } else toast.error("Unable to create record");
      } else toast.error("Unable to create record");
    }
  };

  useEffect(() => {
    if (item) {
      setValue("name", item.name);
      setCompanyId(item.company);
    }
  }, []);
  return (
    <form
      className="bg-white dark:bg-gray-800 w-[400px] rounded-lg"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="bg-[#167490] rounded-t-lg flex items-center px-3 justify-between h-10">
        <span className="text-white font-semibold text-sm">
          {item ? "Edit Shelf" : "Create Shelf"}
        </span>
        <button type="button" className="text-white" onClick={close}>
          <LiaTimesSolid />
        </button>
      </div>

      <div className="p-5 px-10">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="company" value="Select company or branch" />
          </div>
          <BranchSwitcher onChange={(val) => setCompanyId(val)} />
        </div>

        <div className="mt-2">
          <div className="mb-2 block">
            <Label htmlFor="name" value="Shelf name" />
          </div>
          <TextInput
            sizing="sm"
            id="name"
            className="hide-spin-buttons"
            type="text"
            disabled={loading}
            autoComplete="off"
            {...register("name")}
            color={errors?.name ? "failure" : "gray"}
            helperText={
              errors.name && (
                <span className="font-medium text-[10px]">
                  {" "}
                  {errors.name?.message}!
                </span>
              )
            }
          />
        </div>

        <div className="mt-8">
          <Button
            isProcessing={loading}
            disabled={loading}
            type="submit"
            size="xs"
            className="w-full rounded-md"
          >
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
};
