import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import utills from "../lib/functions";
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Button } from "flowbite-react";

interface IVariantModal {
  isOpen: boolean;
  close: () => void;
  item?: Product;
  onSelectItemVariant: (item: Product, variant: { id: number, name: string, price: number}) => void;
}
const VariantModal = (props: IVariantModal) => {
  const { isOpen, close, item, onSelectItemVariant } = props;
  const [image, setImage] = useState("");
  const [selectedUnitId, setSelectedUnitId] = useState({ id: 0, name: "", price: 0});

  useEffect(() => {
    if (item) {
      const img =
        item?.images_links.length > 0
          ? item?.images_links[0]
          : utills._default_img;
      setImage(img);
      if (item.variants.length > 0) {
        const [firstVariant] = item.variants;
        setSelectedUnitId({ id: firstVariant.id, name: firstVariant.name, price: firstVariant.price });
      }
    }
  }, [item]);
  return (
    <Modal
      open={isOpen}
      onClose={close}
      classNames={{
        modalContainer: "__remove_modal_bg",
      }}
      showCloseIcon={false}
    >
      <div className="w-[500px] bg-white dark:bg-gray-800">
        <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
          <span className="text-white font-semibold text-sm">
            Select Variant
          </span>
          <span className="text-white cursor-pointer" onClick={close}>
            <LiaTimesSolid />
          </span>
        </div>
        <form className="flex gap-3 p-5">
          <div>
            <div
              className="w-40 h-40 rounded-sm border border-gray-300"
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <div>
              <p className="text-[12px] dark:text-white font-semibold mt-2 leading-4">
                {item?.name}
              </p>
              <div className="text-[14px] font-semibold mt-3 dark:text-white">
                {utills._currency_format(item?.selling_price ?? 0, "NGN")}
              </div>
              <div className="flex justify-between items-center mt-2 font-semibold text-[12px]">
                <span
                  className={`${
                    item?.stock === 0 ? "text-red-700" : "text-green-700"
                  } font-bold`}
                >
                  {item?.stock === 0 ? "Out of stock" : "In stock"}
                </span>
                <span className="text-gray-600 dark:text-gray-300">
                  {item?.stock} left
                </span>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-2">
            <div className="mb-3 flex-1">
              {item?.variants.map((product) => {
                if (product.is_required === 0) return null;
                return (
                  <div
                      onClick={() => setSelectedUnitId({id: product.id, price: product.price, name: product.name})}
                    key={product.id}
                    className={`relative overflow-x-auto mb-2 sm:rounded-md ${
                      selectedUnitId.id === product.id &&
                      "outline outline-1 outline-gray-500"
                    } p-1 cursor-pointer`}
                  >
                    {selectedUnitId.id === product.id && (
                      <span className="absolute right-0 z-40">
                        <IoIosCheckmarkCircle className="text-green-500" />
                      </span>
                    )}
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-[10px] text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="">
                            Unit
                          </th>
                          <th scope="col" className="">
                            Qty
                          </th>
                          <th scope="col" className="">
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="bg-white border-b text-[10px] dark:bg-gray-800 dark:border-gray-700">
                          <th
                            scope="row"
                            className="p-1 font-medium truncate w-20 text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {product.name}
                          </th>
                          <td className="p-1">{product.quantity}</td>
                          <td className="p-1">
                            {utills._currency_format(product.price ?? 0, "NGN")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
            <Button
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                onSelectItemVariant(item as Product, selectedUnitId);
                close()
              }}
              size="xs"
              color="dark"
              className="w-full rounded-sm"
            >
              Add to invoice
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default VariantModal;
