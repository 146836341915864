import {
  Alert,
  Button,
  Datepicker,
  Label,
  Radio,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCash } from "react-icons/bs";
import { CgCheckO } from "react-icons/cg";
import { IoIosSave } from "react-icons/io";
import { MdCreditCard } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../lib/hook";
import utills from "../lib/functions";
import toast from "react-hot-toast";
import { useLazyGetProductQuery } from "../redux/queries/products";
import { useCreateOrderMutation } from "../redux/queries/order";
import { clearCart } from "../redux/slices/cart";
import { LuWallet } from "react-icons/lu";
import { GiReceiveMoney } from "react-icons/gi";
import { useGetUserQuery } from "../redux/queries/users";
import { FaEdit, FaTruck } from "react-icons/fa";
import { HiOutlineTicket } from "react-icons/hi";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import moment from "moment";

interface PlaceOrderComponent extends UI {
  from?: string;
  total?: string;
  items?: any;
  customer: {
    label: string;
    value: string;
  } | null;
  deliveryFee?: string;
  resetShipping: () => void;
  discount: string;
}
const PlaceOrder = (props: PlaceOrderComponent) => {
  const dispatch = useAppDispatch();
  const {
    close,
    customer,
    from,
    total,
    items,
    deliveryFee,
    open,
    resetShipping,
    discount,
  } = props;
  const { cart } = useAppSelector((state) => state.cartAndDraft);
  const [customerBalance, setCustomerBalance] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [fee, setFee] = useState("");
  const [paymentMode, setPaymentMode] = useState("0");
  const [getProduct] = useLazyGetProductQuery();
  const [createOrder] = useCreateOrderMutation();
  const [amountPaid, setAmountPaid] = useState("");
  const { data: user } = useGetUserQuery(customer?.value ?? "");
  const [chequeProps, setChequeProps] = useState({
    cheque_no: "",
    cheque_post_date: "",
  });
  const [chequeModal, setChequeModal] = useState(false);

  const getTotalPrice = () => {
    const totals = utills._total_item_in_cart(cart);
    return totals.totalPriceInNaira + Number(deliveryFee) - Number(discount ?? 0)
  };
  const calCustomerBal = (val: string) => {
    setAmountPaid(val);
    const totalPriceInNaira = total ?? getTotalPrice()
    // const { totalPriceInNaira } = utills._total_item_in_cart(cart);
    const bal = Number(val) - Number(totalPriceInNaira);
    setCustomerBalance(bal);
  };

  const placeOrder = async () => {
    if (Number(amountPaid) === 0) {
      toast.error("Please enter amount paid");
      utills._play_error_sound();
      return;
    }
    let p = [];
    setLoading(true);
    for (const item of cart) {
      const res = await getProduct(item.itemId);
      if ("data" in res) {
        if (res.data?.status === 200) {
          const product = res.data?.data;
          p.push({
            uuid: product.uuid,
            name: product.name,
            price: item.price_in_naira,
            quantity: item.quantity,
            company: product.company.uuid,
            variant_id: item.variant_id ?? undefined,
          });
        } else {
          setLoading(false);
          toast.error("One of the items in cart could not be found");
          return;
        }
      } else {
        setLoading(false);
        toast.error("Unable to validate items");
        return;
      }
    }
    const payload: OrderPayload = {
      type: 13,
      user: customer?.value ?? "",
      payment_mode: Number(paymentMode),
      items: items ?? p,
      amount_paid: Number(amountPaid),
      delivery_fee: Number(fee) ?? 0,
      discount: Number(discount) ?? 0,
    };
    if(paymentMode === "5"){
      payload.cheque_no = chequeProps.cheque_no
      payload.cheque_post_date = chequeProps.cheque_post_date
    }

    const res = await createOrder(payload);
    
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Order Placed");
        close();
        dispatch(clearCart());
        resetShipping();
        //@ts-ignore
      } else toast.error(res.data.message ?? "Error unable to place order!!!");
    } else toast.error("Failed to place order");
    setLoading(false);
  };

  const totalAmount = total ?? getTotalPrice();

  const handlePayment = (val: string) => {
    if (val === "5") {
      setChequeModal(true);
    }
    setPaymentMode(val);
  };

  useEffect(() => {
    if (open && deliveryFee) {
      setFee(deliveryFee);
    }
  }, [open]);

  useEffect(()=> {
    const totalPriceInNaira = total ?? getTotalPrice()
    // const { totalPriceInNaira } = utills._total_item_in_cart(cart);
    const bal = 0 - Number(totalPriceInNaira);
    setCustomerBalance(bal);
  },[])
  return (
    <div className="h-full flex flex-col bg-white dark:bg-gray-800 min-w-[450px]">
      <div className="flex justify-between items-center px-3 py-3 border-b border-gray-300">
        <h4 className="font-semibold dark:text-white">Place Order</h4>
        <span className="cursor-pointer" onClick={close}>
          <AiOutlineCloseCircle className="dark:text-white" />
        </span>
      </div>

      <div className="px-3 flex flex-col justify-between flex-1">
        <div>
          <fieldset className="flex max-w-md flex-col gap-4 mt-2">
            <Label value="Payment Method" />
            <div className="flex items-center gap-2">
              <Radio
                id="cash"
                name="payment"
                value={0}
                defaultChecked
                onChange={(e) => handlePayment(e.target.value)}
              />
              <Label className="flex items-center" htmlFor="cash">
                <BsCash className="mr-2" /> Cash
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="card"
                name="payment"
                value={2}
                onChange={(e) => handlePayment(e.target.value)}
              />
              <Label className="flex items-center" htmlFor="card">
                <MdCreditCard className="mr-2" /> Card
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="cheque"
                name="payment"
                value={5}
                onChange={(e) => handlePayment(e.target.value)}
              />
              <Label className="flex items-center" htmlFor="cheque">
                <HiOutlineTicket className="mr-2" /> Cheque{" "}
                {paymentMode === "5" && <span className="text-xs ml-2">
                  (
                  {`No: ${chequeProps.cheque_no} - Date: ${chequeProps.cheque_post_date}`}
                  )
                </span>}
                {paymentMode === "5" && <button
                  className="text-green-700 ml-2"
                  onClick={() => setChequeModal(true)}
                >
                  <FaEdit />
                </button>}
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="card"
                name="payment"
                value={4}
                onChange={(e) => handlePayment(e.target.value)}
              />
              <Label className="flex items-center" htmlFor="loan">
                <GiReceiveMoney className="mr-2" /> Loan{" "}
                <span className="text-xs ml-2">
                  (
                  {utills._currency_format(user?.data.company.loan ?? 0, "NGN")}
                  )
                </span>
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="card"
                name="payment"
                value={3}
                onChange={(e) => handlePayment(e.target.value)}
              />
              <Label className="flex items-center" htmlFor="wallet">
                <LuWallet className="mr-2" /> Wallet{" "}
                <span className="text-xs ml-2">
                  (
                  {utills._currency_format(
                    user?.data.company.wallet ?? 0,
                    "NGN"
                  )}
                  )
                </span>
              </Label>
            </div>
          </fieldset>
          <div className="border-t mt-3 border-gray-300 dark:border-gray-600">
            <Label value="Summary" className="ml-2" />
            <div className="text-[13px] dark:text-gray-300 mt-3">
              <div className="flex justify-between items-center bg-gray-200 dark:bg-gray-700 p-2 mb-1 rounded-sm">
                <span>Tax:</span>
                <span className="font-semibold">
                  {utills._currency_format(0, "NGN")}
                </span>
              </div>

              <div className="flex justify-between items-center bg-gray-200 dark:bg-gray-700 p-2 mb-1 rounded-sm">
                <span>Total Amount:</span>
                <span className="font-semibold">{utills._currency_format(Number(totalAmount), 'NGN')}</span>
              </div>
            </div>

            <div className="mt-3">
              <div className="mb-2 block">
                <Label htmlFor="amount" value="Amount Paid By Customer" />
              </div>
              <TextInput
                sizing="sm"
                id="amount"
                onChange={(e) => calCustomerBal(e.target.value)}
                value={amountPaid}
                type="number"
                step="any"
                placeholder="Amount Paid By Customer"
                shadow
                style={{ borderRadius: 2 }}
              />
            </div>
            <div className="flex justify-end items-center pt-1 dark:text-gray-300 text-[13px]">
              <span className="mr-2">Balance:</span>
              <span
                className={`font-semibold ${
                  customerBalance < 0  && "text-red-400"
                }`}
              >
                {utills._currency_format(customerBalance, "NGN")}
              </span>
            </div>

            {/* <div className="max-w-md mb-3">
            <Label htmlFor="fee" value="Delivery fee" className="text-xs" />
            <TextInput
              id="fee"
              icon={FaTruck}
              value={fee}
              onChange={(e)=> setFee(e.target.value)}
              type="number"
              className="flex-1 mt-1"
              sizing="sm"
              placeholder="Delivery fee"
              style={{ borderRadius: 2 }}
            />
          </div> */}
          </div>
        </div>
      </div>

      <div className="px-3 mb-2">
        {/* {<Alert className="px-3 py-1 mb-2 text-[13px]" color="failure" icon={HiInformationCircle}>
      <span className="font-medium">Error!</span> Insuffucient Funds.
    </Alert>} */}
        {from !== "order" && (
          <Button className="rounded-sm w-full mb-2" color="gray" size="xs">
            <IoIosSave className="mr-2 h-5 w-5" />
            Save To Draft
          </Button>
        )}
        <Button
          isProcessing={loading}
          disabled={loading}
          className="rounded-sm w-full"
          size="xs"
          onClick={() => placeOrder()}
        >
          <CgCheckO className="mr-2 h-5 w-5" />
          Place Order
        </Button>
      </div>
      <Modal
        open={chequeModal}
        onClose={() => setChequeModal(false)}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <div className="w-[400px]">
          <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
            <span className="text-white font-semibold text-sm">
              Enter Cheque Info
            </span>
            <span
              className="text-white cursor-pointer"
              onClick={() => setChequeModal(false)}
            >
              <LiaTimesSolid />
            </span>
          </div>
          <ChequeData
            chequeProps={chequeProps}
            handleCheckRecord={(data) =>
              setChequeProps((prev) => ({ ...prev, ...data }))
            }
            close={()=> setChequeModal(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PlaceOrder;

interface IChequeData {
  close: () => void
  chequeProps: {
    cheque_post_date: string;
    cheque_no: string;
  };
  handleCheckRecord: ({
    cheque_post_date,
    cheque_no,
  }: {
    cheque_post_date?: string;
    cheque_no?: string;
  }) => void;
}
export const ChequeData = (props: IChequeData) => {
  const { chequeProps, handleCheckRecord, close } = props;

  return (
    <div className="p-3 bg-white dark:bg-gray-800 flex flex-col justify-between h-[500px]">
      <div className="flex flex-col gap-3">
        <div className="max-w-sm">
          <Label value="Cheque Post Date" className="mb-1" />
          <TextInput
            type="date"
            size={10}
            style={{ borderRadius: 3 }}
            onChange={(e) => {
              let date = e.target.value
              handleCheckRecord({ cheque_post_date: `${date.split("-")[2]}-${date.split("-")[1]}-${date.split("-")[0]}` })
            }}
          />
        </div>
        <div className="max-w-sm">
          <Label value="Cheque Number" className="mb-1" />
          <TextInput
            type="text"
            value={chequeProps.cheque_no}
            onChange={(e) => handleCheckRecord({ cheque_no: e.target.value })}
          />
        </div>
      </div>
      <Button onClick={close} className="w-full rounded-md mt-5">Save</Button>
    </div>
  );
};
