import React, { useState, useRef, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Banner,
  Button,
  Datepicker,
  Label,
  Select,
  TextInput,
  Tooltip,
} from "flowbite-react";
import { MdLocalGroceryStore } from "react-icons/md";
import { useGetCompaniesQuery } from "../../redux/queries/company";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import toast from "react-hot-toast";
import { GoTrash } from "react-icons/go";
import { useCreateStockMutation } from "../../redux/queries/stock";
import moment from "moment";
import BranchSwitcher from "../../components/BranchSwitcher";
import ProductSearch from "../../components/ProductSearch";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import Attachment from "../../components/Attachment";
import CustomSelect from "../../components/CustomSelect";
import { RiStockLine } from "react-icons/ri";
import { useLazyGetProductsQuery } from "../../redux/queries/products";
import {
  addToPurchase,
  decreasePurchaseCartQty,
  emptyPurchaseCart,
  increasePurchaseCartQty,
  removePurchaseCartItem,
  setPurchaseCartQuantity,
  updatePurchaseCartItem,
} from "../../redux/slices/cart";
import { useInitializePaymentMutation } from "../../redux/queries/Payments";

interface Item {
  uuid: string;
  quantity: number;
  price: number;
}

export interface PurchaseItem extends InvoiceCart {
  batch_no?: string;
  expiring_date?: string;
  markup?: string;
  image_link?: string;
  cost_price?: string;
  selling_price?: string;
  flat?: number;
  verified: number;
}
interface IItem {
  uuid: string;
  cost_price?: string;
  selling_price?: string;
  batch_no?: string;
  markup?: number;
  expiring_date?: string;
  quantity: number;
  verified: number;
}

const AddPurchase = () => {
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const { user, defaultBranchUUid } = useAppSelector(
    (state) => state.appUserConfig
  );
  const { purchaseCart } = useAppSelector((state) => state.cartAndDraft);

  const [supplierParams, setSupplierParams] = useState("");
  const { data: sellers } = useGetCompaniesQuery({
    type: "pharmacy,manufacturer",
    limit: "0,10",
    name: supplierParams,
  });

  const [ getLowStockProducts, { isLoading: ploading } ] = useLazyGetProductsQuery()
  const [createStock, { isLoading }] = useCreateStockMutation();

  const [seller, setSeller] = useState("");
  const [qty, setQty] = useState("1");
  const [atachments, setAttachment] = useState<string[]>([]);
  const [branch, setBranch] = useState(defaultBranchUUid);

  const addItemInterceptor = (product: Product) => {
    let payload: PurchaseItem = {
      uuid: product.uuid,
      price: Number(product.price),
      name: product.name,
      quantity: product.reorder_quantity ?? Number(qty),
      company: product.company.uuid,
      markup:
        product.flat === 1
          ? (product.selling_price - Number(product.price)).toString()
          : product.markup.toString(),
      flat: product.flat,
      image: product.images_links,
      selling_price: product.selling_price.toString(),
      batch_no: product.batch_no,
      verified: product.search_by_barcode ? 1 : 0,
    };
    dispatch(addToPurchase(payload));
    utills._play_sound();
    setQty("1");
  };

  const createPurchase = async () => {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const entries = Object.fromEntries(formData.entries());
      const payload = {
        ...entries,
        items: [] as IItem[],
        attachments: atachments[0] ?? "",
        company: seller,
      };

      //@ts-ignore
      payload.purchase_date = moment(entries.purchase_date).format(
        "DD-MM-YYYY"
      );

      const items = purchaseCart.items.map((item) => ({
        uuid: item.uuid,
        cost: item.price,
        selling_price: item.selling_price,
        batch_no: item.batch_no,
        markup: Number(item.markup),
        expiring_date: item.expiring_date ?? "",
        quantity: item.quantity,
        verified: item.verified,
      }));

      if (items.find((x) => x.verified === 0)) {
        toast.error("Please verify all product");
        return;
      }
      payload.items = items;

      const res = await createStock(payload);
      if ("data" in res) {
        if (res.data.status === 200) {
          toast.success("Purchase Addedd");
          dispatch(emptyPurchaseCart())
          setAttachment([]);
          setQty("1");
          formRef.current.reset();
        } else
          toast.error(res.data.data.join("--") ?? "Failed to add purchase");
      }
    }
  };

  const handleLowStock = async () => {
    if(!seller){
      toast.error("Please sellect supplier")
      return
    }
    const res = await getLowStockProducts({
      supplier: seller,
      company: defaultBranchUUid,
      limit: "0,60",
    });
    if(res.data?.data){
      for(const item of res.data.data){
        addItemInterceptor(item)
      }
    }else toast.error("No product found")
  };

  //@ts-ignore
  const total = purchaseCart.items.reduce((acc: number, obj: PurchaseItem) => {
    return acc + Number(obj.price ?? "1") * obj.quantity;
  }, 0);

  useEffect(() => {
    if (purchaseCart.items.length > 0) {
      let pickedItemCopy = structuredClone(purchaseCart.items);
      const lastdata = pickedItemCopy.shift();
      if (lastdata) {
        const el = document.getElementById(lastdata.uuid);
        setTimeout(() => el?.focus(), 1000);
      }
    }
  }, [purchaseCart.items.length]);

  return (
    <div>
      <Breadcrumb title="Add Purchase" />
      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Add Purchase.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Add new purchase record.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <MdLocalGroceryStore className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="bg-white px-3 py-5 rounded-sm dark:bg-gray-800">
        <form ref={formRef}>
          <div className="grid grid-cols-4 gap-3">
            <div>
              <Label value="Invoice Date" />
              <Datepicker
                name="purchase_date"
                datepicker-format={"DD-MM-YYYY"}
              />
            </div>
            <div>
              <Label value="Supplier" />
              <CustomSelect
                isClearable={false}
                disabled={purchaseCart.items.length > 0}
                onSelect={(val) => setSeller(val.value)}
                inputClasses="h-[43px] pt-1"
                options={
                  sellers?.data.map((item) => ({
                    label: item.name,
                    value: item.uuid,
                  })) ?? []
                }
                onSearch={(text) => setSupplierParams(text)}
              />
            </div>

            <div>
              <Label value="Branch" />
              <BranchSwitcher
                sizing="md"
                name="buyer"
                onChange={(val) => setBranch(val)}
              />
            </div>

            <div>
              <Label value="Invoice ID" />
              <TextInput type="text" name="invoice_id" />
            </div>
            <div>
              <Label value="Order Status" />
              <Select name="status">
                <option value={"Requested"}>Requested</option>
                <option value={"Delivered"}>Delivered</option>
              </Select>
            </div>
            <div>
              <Label value="Attachment" />
              <Attachment onUpload={(links, titles) => setAttachment(links)} />
            </div>
          </div>
        </form>
      </div>

      <div className="bg-white px-3 py-5 rounded-sm mt-4 dark:bg-gray-800 relative">
        <div className="flex items-center flex-wrap gap-3">
          <div className="absolute z-10 top-[-5px]">
            <Label value="Qty" className="text-xs" />
          </div>
          <TextInput
            style={{ borderRadius: 3 }}
            className="w-20"
            placeholder="qty"
            sizing="sm"
            type="number"
            step="any"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
          />
          <div className="flex-1 relative">
            <ProductSearch
              companyId={seller ?? ""}
              branchId={branch}
              onSelect={(item) => addItemInterceptor(item)}
              error="Please Select Supplier"
            />
          </div>
        </div>
      </div>

      <div className="mt-10 mb-3 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Button onClick={handleLowStock} disabled={ploading} isProcessing={ploading} size="xs" className="rounded-sm">
            <RiStockLine className="mr-2 size-4" /> Add low stock
          </Button>
          <Button
            onClick={createPurchase}
            size="xs"
            className="rounded-sm"
            disabled={purchaseCart.items.length === 0 ?? isLoading}
            isProcessing={isLoading}
          >
            Add Purchase
          </Button>
        </div>
        <div className="text-[15px]">
          Total Purchase:{" "}
          <span className="font-semibold mr-2">
            {utills._currency_format(total, "NGN")}
          </span>
        </div>
      </div>

      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-8">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Product
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                <th scope="col" className="px-6 py-3">
                  Cost
                </th>
                <th scope="col" className="px-6 py-3">
                  Selling
                </th>
                <th scope="col" className="px-6 py-3">
                  Markup / profit
                </th>
                <th scope="col" className="px-6 py-3">
                  Batch no
                </th>
                <th scope="col" className="px-6 py-3">
                  Expire date
                </th>
                <th scope="col" className="px-6 py-3">
                  Verified
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {purchaseCart.items.map((item) => (
                <tr
                  key={item.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="p-4">
                    <ImageView
                      className="w-8 h-8"
                      url={
                        utills._remove_invalid_link(item.image).length === 0
                          ? utills._default_img
                          : item.image[0]
                      }
                    />
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.name}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <button
                        className="inline-flex items-center justify-center p-1 me-3 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => {
                          if (item.quantity === 1) {
                            utills._play_error_sound();
                            return;
                          }
                          dispatch(decreasePurchaseCartQty({ uuid: item.uuid, amount: item.quantity - 1}))
                          utills._play_sound();
                        }}
                      >
                        <span className="sr-only">Quantity button</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          fill="none"
                          viewBox="0 0 18 2"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1h16"
                          />
                        </svg>
                      </button>
                      <div>
                        <input
                          type="number"
                          step="any"
                          id={item.uuid}
                          className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                          placeholder="1"
                          value={item.quantity}
                          min={1}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              const element =
                                document.getElementById("searchFields");
                              element?.focus();
                            }
                          }}
                          onFocus={(e) => {
                            e.target.select();
                          }}
                          onChange={(e) => {
                            if (e.target.value === "0") {
                              toast.error("Minimum is 1");
                              utills._play_error_sound();
                              return;
                            }
                            setPickedItem((prev) =>
                              prev.map((p) => {
                                if (item.uuid === p.uuid) {
                                  return {
                                    ...p,
                                    quantity: Number(e.target.value),
                                  };
                                } else return p;
                              })
                            );
                          }}
                        />
                      </div>
                      <button
                        className="inline-flex items-center justify-center h-6 w-6 p-1 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => {
                          dispatch(increasePurchaseCartQty({ uuid: item.uuid, amount: item.quantity + 1}))
                          utills._play_sound();
                        }}
                      >
                        <span className="sr-only">Quantity button</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          fill="none"
                          viewBox="0 0 18 18"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 1v16M1 9h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    <input
                      type="number"
                      step="any"
                      id="first_product"
                      className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                      value={item.price}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        let selling =
                          item.flat === 0
                            ? Number(e.target.value) + Number(item.markup)
                            : (Number(item.markup) / 100) *
                              Number(e.target.value);

                        setPickedItem((prev) =>
                          prev.map((x) => {
                            if (x.uuid === item.uuid) {
                              return {
                                ...x,
                                price: Number(e.target.value),
                                selling_price: selling.toString(),
                              };
                            } else return x;
                          })
                        );
                      }}
                    />
                  </td>
                  <td className="px-6 py-4 text-gray-900 dark:text-white">
                    {item.selling_price}
                  </td>
                  <td className="px-6 py-4">{item.markup}</td>

                  <td className="px-6 py-4">
                    <input
                      type="text"
                      id="first_product"
                      className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                      value={item.batch_no}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        setPickedItem((prev) =>
                          prev.map((x) => {
                            if (x.uuid === item.uuid) {
                              return { ...x, batch_no: e.target.value };
                            } else return x;
                          })
                        );
                      }}
                    />
                  </td>
                  <td className="px-6 py-4">
                    <input
                      type="date"
                      id="date"
                      className="bg-gray-50 w-36 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                      // value={item.quantity}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        setPickedItem((prev) =>
                          prev.map((x) => {
                            if (x.uuid === item.uuid) {
                              return {
                                ...x,
                                expiring_date: moment(e.target.value).format(
                                  "DD-MM-YYYY"
                                ),
                              };
                            } else return x;
                          })
                        );
                      }}
                    />
                  </td>
                  <td className="px-6 py-4">
                    {item.verified ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : (
                      <FaTimesCircle className="text-red-500" />
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-3">
                      <Tooltip content="Remove Item">
                        <span
                          onClick={() =>
                            setPickedItem((prev) =>
                              prev.filter((cart) => cart.uuid !== item.uuid)
                            )
                          }
                          className="font-medium text-red-600 dark:text-red-500"
                        >
                          <GoTrash />
                        </span>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))} */}

              {purchaseCart.items.map((item) => (
                <tr
                  key={item.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="p-4">
                    <ImageView
                      className="w-8 h-8"
                      url={
                        utills._remove_invalid_link(item.image).length === 0
                          ? utills._default_img
                          : item.image[0]
                      }
                    />
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.name}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <button
                        className="inline-flex items-center justify-center p-1 me-3 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => {
                          if (item.quantity === 1) {
                            utills._play_error_sound();
                            return;
                          }
                          dispatch(
                            decreasePurchaseCartQty({
                              uuid: item.uuid,
                              amount: 1,
                            })
                          );
                          utills._play_sound();
                        }}
                      >
                        <span className="sr-only">Decrease quantity</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          fill="none"
                          viewBox="0 0 18 2"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1h16"
                          />
                        </svg>
                      </button>
                      <div>
                        <input
                          type="number"
                          step="any"
                          id={item.uuid}
                          className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                          placeholder="1"
                          value={item.quantity}
                          min={1}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              const element =
                                document.getElementById("searchFields");
                              element?.focus();
                            }
                          }}
                          onFocus={(e) => {
                            e.target.select();
                          }}
                          onChange={(e) => {
                            const newQuantity = Number(e.target.value);
                            if (newQuantity < 1) {
                              toast.error("Minimum is 1");
                              utills._play_error_sound();
                              return;
                            }
                            dispatch(
                              setPurchaseCartQuantity({
                                uuid: item.uuid,
                                quantity: newQuantity,
                              })
                            );
                          }}
                        />
                      </div>
                      <button
                        className="inline-flex items-center justify-center h-6 w-6 p-1 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => {
                          dispatch(
                            increasePurchaseCartQty({
                              uuid: item.uuid,
                              amount: 1,
                            })
                          );
                          utills._play_sound();
                        }}
                      >
                        <span className="sr-only">Increase quantity</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          fill="none"
                          viewBox="0 0 18 18"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 1v16M1 9h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    <input
                      type="number"
                      step="any"
                      id="first_product"
                      className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                      value={item.price}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        const newPrice = Number(e.target.value);
                        let selling =
                          item.flat === 0
                            ? newPrice + Number(item.markup)
                            : (Number(item.markup) / 100) * newPrice;

                        dispatch(
                          updatePurchaseCartItem({
                            uuid: item.uuid,
                            price: newPrice,
                            selling_price: selling.toString(),
                          })
                        );
                      }}
                    />
                  </td>
                  <td className="px-6 py-4 text-gray-900 dark:text-white">
                    {item.selling_price}
                  </td>
                  <td className="px-6 py-4">{item.markup}</td>

                  <td className="px-6 py-4">
                    <input
                      type="text"
                      id="first_product"
                      className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                      value={item.batch_no}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        dispatch(
                          updatePurchaseCartItem({
                            uuid: item.uuid,
                            batch_no: e.target.value,
                          })
                        );
                      }}
                    />
                  </td>
                  <td className="px-6 py-4">
                    <input
                      type="date"
                      id="date"
                      className="bg-gray-50 w-36 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        dispatch(
                          updatePurchaseCartItem({
                            uuid: item.uuid,
                            expiring_date: moment(e.target.value).format(
                              "DD-MM-YYYY"
                            ),
                          })
                        );
                      }}
                    />
                  </td>
                  <td className="px-6 py-4">
                    {item.verified ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : (
                      <FaTimesCircle className="text-red-500" />
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-3">
                      <Tooltip content="Remove Item">
                        <span
                          onClick={() =>
                            dispatch(removePurchaseCartItem(item.uuid))
                          }
                          className="font-medium text-red-600 dark:text-red-500"
                        >
                          <GoTrash />
                        </span>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AddPurchase;
