import { api } from "../baseConfig";

interface PayloadFilter {
  limit?: string;
  name?: string;
  status?: number;
  company?: string;
  category?: string;
  user?: string;
  start_date?: string;
  end_date?: string;
  cheque_no?: number;
  payment_uuid?: string;
  date?: string;
  order_company_uuid?: string;
}

interface sendMoneyFilter {
  type: string;
  payment_mode: number;
  amount: string;
  receiver?: string;
  description: string;
  company: string | undefined;
  bank?: any;
}

interface bank {
  code: string;
  name: string;
}

interface BanksResponse {
  status: number;
  data: bank[];
  message: string;
}

interface SendPayment {
  type: string;
  amount: number;
  description?: string;
  token?: string;
  bank?: BankData;
  payment_mode: number;
  company?: string;
}

interface BankData {
  account_no: string;
  account_name: string;
  bank_id: number;
}

export const paymentSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getPayment: builder.query<PaymentResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/payments`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Payments"],
    }),
    getBanks: builder.query<BanksResponse, PayloadFilter>({
      query: () => ({
        url: `/banks`,
        method: "GET",
      }),
      providesTags: ["Payments"],
    }),
    getBankVerified: builder.query<any, any>({
      query: (filters) => ({
        url: `/banks/verify`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Payments"],
    }),
    sendMoney: builder.mutation<any, sendMoneyFilter>({
      query: (data) => ({
        url: "/payments/sendout/initialize",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Payments"],
    }),
    getCheques: builder.query<any, PayloadFilter>({
      query: (filters) => ({
        url: `v2/payments/cheque`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Payments"],
    }),
    markAsPaid: builder.mutation<any, PayloadFilter>({
      query: (data) => ({
        url: "v2/payments/mark_as_paid",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Payments"],
    }),
    getIncomeStatement: builder.query<IncomeStatementResponse, PayloadFilter>({
      query: (filters) => ({
        url: `v2/payments/income_statement`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Payments"],
    }),
    getWallets: builder.query<WalletsResponse, PayloadFilter>({
      query: (filters) => ({
        url: `v2/payments/wallet`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Payments"],
    }),
    sendPayment: builder.mutation<any, SendPayment>({
      query: (payload) => ({
        url: "/payments/sendout/transfer/finalize",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Stock", "Stocks", "Payments"],
    }),
    initializePayment: builder.mutation<any, any>({
      query: (payload) => ({
        url: "/payments/sendout/initialize",
        method: "POST",
        body:payload
      }),
      invalidatesTags: ["Stock", "Stocks", "Payments"],
    }),
    initiateTransfer: builder.mutation<any, { uuid: string }>({
      query: (payload) => ({
        url: "/payments/sendout/transfer",
        method: "POST",
        body:payload
      }),
      invalidatesTags: ["Stock", "Stocks", "Payments"],
    }),
    updateCheque: builder.mutation<any, any>({
      query: (data) => ({
        url: `/v2/payments/cheque/${data.uuid}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Payments"],
    }),
  }),
});

export const {
  useGetPaymentQuery,
  useGetBanksQuery,
  useLazyGetBankVerifiedQuery,
  useSendMoneyMutation,
  useGetChequesQuery,
  useMarkAsPaidMutation,
  useGetIncomeStatementQuery,
  useGetWalletsQuery,
  useSendPaymentMutation,
  useInitializePaymentMutation,
  useInitiateTransferMutation,
  useUpdateChequeMutation,
} = paymentSlice;
