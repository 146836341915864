import { Button, Label, Select, TextInput } from "flowbite-react";
import React from "react";

const duration = ["Day", "Week", "Month"];

const ReOrder = () => {
  return (
    <div className="flex items-center justify-center mt-10">
      <div className="xl:max-w-xl w-full px-2 xl:px-0">
        <form className="p-3 pb-5">
          <div className="flex-1 flex flex-col gap-5">
            <div className="flex flex-row justify-between gap-3 items-center">
              <Label>Reorder Level</Label>
              <div className="flex flex-row gap-3 items-center">
                <div>
                  <TextInput sizing="md" type="number" />
                </div>
                <div>
                  <Select sizing="md">
                    <option value="" hidden>
                      Select TimeFrame
                    </option>
                    {duration.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between gap-3 items-center">
              <Label>Reorder Qty</Label>
              <div className="flex flex-row gap-3 items-center">
                <div>
                  <TextInput sizing="md" type="number" />
                </div>
                <div>
                  <Select sizing="md">
                    <option value="" hidden>
                      Select TimeFrame
                    </option>
                    {duration.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between gap-3 items-center">
              <Label>Reorder Qty Limit</Label>
              <div className="flex flex-row gap-3 items-center">
                <div>
                  <TextInput sizing="md" type="number" />
                </div>
                <div>
                  <Select sizing="md">
                    <option value="" hidden>
                      Select TimeFrame
                    </option>
                    {duration.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center justify-end">
            <Button
              //   disabled={isLoading}
              //   isProcessing={isLoading}
              size="xs"
              className="rounded-sm"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReOrder;
