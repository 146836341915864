import { useState } from "react";
import {
  useDeleteProductMutation,
  useGetProductsQuery,
} from "../../redux/queries/products";
import { Select, TextInput, Button, Checkbox } from "flowbite-react";
import { CiCircleMore } from "react-icons/ci";
import { FaRegEdit, FaHistory } from "react-icons/fa";
import { IoTrashOutline } from "react-icons/io5";
import Breadcrumb from "../../components/Breadcrumb";
import { MdSearch } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";
import utills from "../../lib/functions";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../lib/hook";
import { useGetCategoriesQuery } from "../../redux/queries/settings";
import toast from "react-hot-toast";
import BranchSwitcher from "../../components/BranchSwitcher";
import ImageView from "../../components/ImageView";
import CustomDropdown from "../../components/CustomDropdown";
import Modal from "react-responsive-modal";
import ModelEditHistory from "./ModelEditHistory";
import { MdOutlineMotionPhotosPause } from "react-icons/md";
import { PiWarningDiamondFill } from "react-icons/pi";
import ModelScrap from "./ModelScrap";
import ModelExpired from "./ModelExpired";
import CustomPagination from "../../components/CustomPagination";

const Products = () => {
  const [page, setPage] = useState("");
  const [uuid, setUuid] = useState("");
  const navigate = useNavigate();
  const { user, defaultBranchUUid } = useAppSelector(
    (state) => state.appUserConfig
  );
  const [filters, setFilters] = useState({
    limit: "0,10",
    name: "",
    company: defaultBranchUUid,
  });
  const [deleteProduct] = useDeleteProductMutation();
  const { data: products, isLoading } = useGetProductsQuery(filters);
  const [filterFields, setFilterFields] = useState({
    category: "",
    orderby: "",
    company: defaultBranchUUid,
    base_unit: "",
  });
  const { data: categories } = useGetCategoriesQuery({
    type: user?.company.type,
    status: 1,
  });

  const applyFilter = (e: any) => {
    e.preventDefault();
    setFilters((prev) => ({ ...prev, ...filterFields, limit: "0,10" }));
  };

  const reset = () => {
    setFilterFields({
      category: "",
      orderby: "",
      company: "",
      base_unit: "",
    });
    setFilters({
      limit: "0,10",
      name: "",
      company: defaultBranchUUid,
    });
  };

  const handleDelete = async (id: string) => {
    const action = window.confirm("Are you sure you want to delete");
    if (action) {
      const res = await deleteProduct(id);
      if ("data" in res) {
        toast.success("Item Deleted");
      } else toast.error("Unable to delete product");
    }
  };

  return (
    <div>
      <Breadcrumb
        title="Product List"
        rightButton={
          <Button size="xs">
            <Link className="flex items-center" to="/add-product">
              <IoIosAdd className="mr-2 h-5 w-5" /> Add Product
            </Link>
          </Button>
        }
      />
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <form onSubmit={applyFilter} className="grid grid-cols-4 gap-3">
          <div>
            <TextInput
              sizing="sm"
              type="text"
              value={filters.name}
              icon={MdSearch}
              placeholder="search..."
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </div>
          <div>
            <Select
              sizing="sm"
              id="countries"
              value={filterFields.category}
              onChange={(e) =>
                setFilterFields((prev) => ({
                  ...prev,
                  category: e.target.value,
                }))
              }
            >
              <option value="">All categories</option>
              {categories?.data.map((cat) => (
                <option value={cat.uuid} key={cat.id}>
                  {cat.name}
                </option>
              ))}
            </Select>
          </div>

          <div>
            <Select
              value={filterFields.orderby}
              onChange={(e) =>
                setFilterFields((prev) => ({
                  ...prev,
                  orderby: e.target.value,
                }))
              }
              id="sort"
              sizing="sm"
            >
              <option value="" disabled hidden>
                Sort by default
              </option>
              <option value="name_asc">Ascending</option>
              <option value="name_desc">Descending</option>
              <option value="crdate_desc">Newest</option>
              <option value="update_desc">Latest Edits</option>
              <option value="quantity_desc">Quantity (highest)</option>
              <option value="quantity_asc">Quantity (Lowest)</option>
              <option value="price_desc">Price (highest)</option>
              <option value="price_asc">Price (Lowest)</option>
              <option value="<=">Low/Out of stock</option>
            </Select>
          </div>

          <div>
            <BranchSwitcher
              sizing="sm"
              defaultCompany={filterFields.company}
              onChange={(value) =>
                setFilterFields((prev) => ({ ...prev, company: value }))
              }
            />
          </div>
          <div>
            <TextInput
              sizing="sm"
              type="text"
              value={filterFields.base_unit}
              placeholder="Base Unit"
              onChange={(e) =>
                setFilterFields((prev) => ({
                  ...prev,
                  base_unit: e.target.value,
                }))
              }
            />
          </div>

          <div className="flex gap-2">
            <Button size="xs" type="submit">
              Apply
            </Button>
            <Button outline size="xs" type="reset" onClick={reset}>
              Reset
            </Button>
          </div>
        </form>
      </div>
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={products?.data}
            total={products?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <Checkbox id="checkbox-all-search" />
                      <label htmlFor="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    #
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Product name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Quantity
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Base Unit
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Cost Price &#40;&#8358;&#41;
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Selling Price &#40;&#8358;&#41;
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Profit &#40;&#8358;&#41;
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {products?.data?.map((item) => {
                  const imgurl =
                    item.images_links[0] ===
                    "https://dev-assets.gohealthy.ng/items/"
                      ? utills._default_img
                      : item.images_links[0];
                  return (
                    <tr
                      onClick={() => navigate(`/products/${item.uuid}`)}
                      key={item.id}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="w-4 p-4">
                        <div className="flex items-center">
                          <Checkbox id="checkbox-table-search-1" />
                          <label
                            htmlFor="checkbox-table-search-1"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td onClick={(e) => e.stopPropagation()}>
                        <ImageView url={imgurl} className="w-8 h-8" />
                      </td>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item.name}
                      </th>
                      <td className="px-6 py-4">{item.quantity}</td>
                      <td className="px-6 py-4">{item.base_unit}</td>
                      <td className="px-6 py-4">
                        {utills._currency_format(Number(item.price), "NGN")}
                      </td>
                      <td className="px-6 py-4">
                        {utills._currency_format(item.selling_price, "NGN")}
                      </td>
                      <td className="px-6 py-4">
                        {utills._currency_format(
                          item.selling_price - Number(item.price),
                          "NGN"
                        )}
                      </td>
                      <td className="pl-5" onClick={(e) => e.stopPropagation()}>
                        <CustomDropdown
                          trigger={
                            <div className="">
                              <CiCircleMore className="text-2xl" />
                            </div>
                          }
                          menu={[
                            {
                              icon: FaRegEdit,
                              title: "Edit",
                              action: () =>
                                navigate(`/products/edit/${item.uuid}`),
                            },
                            {
                              icon: FaHistory,
                              title: "Edit History",
                              action: () => {
                                setPage("history");
                                setUuid(item.uuid);
                              },
                            },
                            {
                              icon: IoTrashOutline,
                              title: "Delete",
                              action: () => handleDelete(item.uuid),
                            },
                            {
                              icon: MdOutlineMotionPhotosPause,
                              title: "Move to Scrap",
                              action: () => {
                                setPage("scrap");
                                setUuid(item.uuid);
                              },
                            },
                            {
                              icon: PiWarningDiamondFill,
                              title: "Move to Expired",
                              action: () => {
                                setPage("expired");
                                setUuid(item.uuid);
                              },
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CustomPagination>
        </div>

        <Modal
          showCloseIcon={false}
          blockScroll={false}
          classNames={{
            modalContainer:
              page === "history" ? "__history_modal_bg" : "__remove_modal_bg",
          }}
          center
          open={page.length ? true : false}
          onClose={() => setPage("")}
        >
          {page === "history" && (
            <ModelEditHistory close={() => setPage("")} item_uuid={uuid} />
          )}

          {page === "scrap" && (
            <ModelScrap close={() => setPage("")} item_uuid={uuid} />
          )}

          {page === "expired" && (
            <ModelExpired close={() => setPage("")} item_uuid={uuid} />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default Products;
