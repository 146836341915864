import { Button, Timeline, Tooltip, Spinner } from "flowbite-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { LuPlus, LuPrinter } from "react-icons/lu";
import { TiEdit } from "react-icons/ti";
import utills from "../../lib/functions";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { GoTrash } from "react-icons/go";
import { HiCalendar } from "react-icons/hi";
import { useGetSettingsQuery } from "../../redux/queries/settings";
import PayNow from "./PayNow";
import Invoice from "../../components/Documents/Invoice";
import Drawer from '@mui/material/Drawer';
import Modal from "react-responsive-modal";
import ReturnAndRefund from "./ReturnAndRefund";
import { LiaTimesSolid } from "react-icons/lia";
import BranchSwitcher from "../../components/BranchSwitcher";
import ProductSearch from "../../components/ProductSearch";
import { useAppSelector } from "../../lib/hook";
import toast from "react-hot-toast";
import { useUpdateOrderMutation } from "../../redux/queries/order";
import { useVerifyOrderItemMutation } from "../../redux/queries/stock";
import emptyIcon from "../../assets/empty.png";
import { useLazyGetUserQuery } from "../../redux/queries/users";
import { useGetPaymentQuery } from "../../redux/queries/Payments";
import PaginationControl from "../../components/PaginationControl";
import { useGetProductEditHistoryQuery } from "../../redux/queries/products";

interface IOrderDataScreen {
  order?: CompanyOrder;
  isLoading: boolean;
  orderLoading: boolean;
  screen: string;
  getOrder: (uuid: string) => void;
}

interface CartItem {
  uuid: string;
  item_uuid: string;
  price: number;
  quantity: number;
  company: string;
  name: string;
  verified: number;
  return?: number;
  reason?: string;
  newQuantity?: number;
}
const invoiceTypes = [13, 11, 3];

const OrderDataScreen = (props: IOrderDataScreen) => {
  const { order, isLoading, orderLoading, screen, getOrder } = props;

  const [getUserRecord, { data: buyerRecord }] = useLazyGetUserQuery();
  const { data: settings } = useGetSettingsQuery();
  const [verifyOrderItem] = useVerifyOrderItemMutation();
  const [updateOrder, { isLoading: uLoading }] = useUpdateOrderMutation();
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const order_status = settings?.data.order_status ?? [];

  const [isEdit, setIsEdit] = useState(false);
  const [items, setItems] = useState<CartItem[]>([]);
  const [returnId, setReturnId] = useState("");
  const [returnModal, setReturnModal] = useState(false);
  const [addItemModal, setAddItemModal] = useState<boolean>(false);
  const [record, setRecord] = useState({ companyId: "", itemId: "" });
  const [branchId, setBranchId] = useState(defaultBranchUUid);
  const [isPrintOpen, setIsPrintOpen] = useState(false);
  const [paymentMode, setPaymentMode] = useState(false);

  const [filters, setFilters] = useState({
    limit: "0,5",
    order_company_uuid: order?.uuid,
    user: order?.company?.uuid,
  });
  const [filtersOrders, setFiltersOrders] = useState({
    limit: "0,5",
    type: "order",
    item_uuid: order?.uuid,
  });

  const { data: orderEditHistory, isLoading: isOrderHistoryLoading } =
    useGetProductEditHistoryQuery(filtersOrders);

  useEffect(() => {
    if (order?.uuid && order?.company?.uuid) {
      setFilters((prev) => ({
        ...prev,
        order_company_uuid: order?.uuid,
        user: order?.company?.uuid,
      }));

      setFiltersOrders((prev) => ({
        ...prev,
        item_uuid: order?.uuid,
      }));
    }
  }, [order?.uuid, order?.company?.uuid]);
  const { data: paymentHistories, isLoading: ispaymentLoading } =
    useGetPaymentQuery(filters);

  const getTotalPrice = () => {
    const totals = utills._total_item_price(items as EdittingItem[]);
    const formatedPrice = utills._currency_format(
      totals.totalPriceInNaira,
      "NGN"
    );
    return formatedPrice;
  };

  const updateOrderRecords = async (type: number) => {
    const itemsPayload = items.map((x) => ({
      uuid: x.uuid,
      price: x.price,
      quantity: x.newQuantity as number,
      company: order?.company.uuid,
      name: x.name,
      verified: x.verified,
    }));
    if (itemsPayload.length === 0) {
      toast.error("Your item list can not be empty!!!");
      utills._play_error_sound();
      return;
    }
    const payload = {
      items: itemsPayload,
      // payment_method: 2,
      user: order?.buyer_details.uuid ?? "",
      type: type,
      order: order?.order_uuid,
      company: order?.company.uuid,
    };

    // console.log(payload)

    const res = await updateOrder(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Updated");
        setFiltersOrders({
          limit: "0,5",
          type: "order",
          item_uuid: order?.uuid,
        });
        reset();
      } else toast.error("Unable to update record");
    } else toast.error("Unable to update record");
  };

  const reset = () => {
    setIsEdit(false);
    const oldItems = order?.items.map((product) => ({
      uuid: product.uuid,
      price: Number(product.price),
      item_uuid: product.uuid,
      quantity: product.quantity,
      company: product.order_company_uuid,
      name: product.name,
      verified: product.verified as number,
      return: product.return,
      reason: product.reason ?? "",
      newQuantity: product.quantity,
    }));
    setItems(oldItems ?? []);
  };

  const handlePayNow = () => {
    if (items.find((x) => x.verified === 0)) {
      toast.error("Please Verify all items with barcode!!!");
      return;
    }
    setPaymentMode(true);
  };

  const verifyProduct = async (type: number, uuid: string) => {
    const findItem = items.filter((x) => x.uuid === uuid);

    if (findItem.length === 0) {
      toast.error("unable to find item in list");
      utills._play_error_sound();
      return;
    }
    const verfiedItems = findItem.map((x) => ({
      uuid: x.uuid,
      verified: 1,
    }));

    const payload = {
      items: verfiedItems,
      order: order?.order_uuid,
      company: order?.company,
      type,
    };

    const res = await verifyOrderItem(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Product Verified");
        setItems((prev) =>
          prev.map((rec) => {
            if (rec.uuid === uuid) return { ...rec, verified: 1 };
            else return rec;
          })
        );
      } else {
        toast.error("Verification failed");
      }
    }
  };

  useEffect(() => {
    if (order) {
      const oldItems = order.items.map((product) => ({
        uuid: product.item,
        item_uuid: product.uuid,
        price: Number(product.price),
        quantity: product.quantity,
        company: product.order_company_uuid,
        name: product.name,
        verified: product.verified as number,
        return: product.return,
        reason: product.reason ?? "",
        newQuantity: product.quantity,
      }));
      setItems(oldItems);
    }
    setIsEdit(false);
  }, [order]);

  useEffect(() => {
    if (order) getUserRecord(order.buyer_details.uuid);
  }, [order]);

  // console.log(order)

  if (isLoading || orderLoading) {
    return (
      <div className="flex-1 flex justify-center gap-2 h-20 my-5">
        <Spinner aria-label="Default status example" />
        <Spinner aria-label="Default status example" />
        <Spinner aria-label="Default status example" />
      </div>
    );
  }

  if (!order) {
    return (
      <div className="flex-1 my-5">
        <div className="flex flex-col items-center">
          <img src={emptyIcon} alt="empty record" className="w-20" />
          <span className="dark:text-gray-400 mt-2 text-xs">
            Record not found
          </span>
        </div>
      </div>
    );
  }
  return (
    <div className="flex-1 ml-3 bg-white dark:bg-gray-800 rounded-sm">
      <div className="sticky top-16">
        <div className="">
          <div className="bg-gray-200 dark:bg-gray-600 p-2 rounded-t-sm flex justify-between items-center">
            <span className="text-[13px] text-gray-700 font-semibold dark:text-white">
              #{order.id}
            </span>
            <span className="text-[11px] text-gray-700 font-semibold dark:text-white">
              {moment(order.created_at).format("dddd, MMMM Do YYYY, h:mm a")}
            </span>
          </div>
        </div>
        <div className="p-3">
          <div className="outline outline-1 outline-gray-300 dark:outline-gray-600 p-2 rounded-sm mb-4">
            <div className="flex gap-3 items-center">
              <span className="text-[13px] font-semibold dark:text-white">
                Customer Details
              </span>
            </div>
            <div className="mt-4">
              <div className="dark:text-gray-300 text-xs mb-1">
                <strong>Customer:</strong>{" "}
                <span>{buyerRecord?.data.company?.name}</span>
              </div>
              <div className="dark:text-gray-300 text-xs mb-1">
                <strong>Staff Name:</strong>{" "}
                <span>{order.buyer_details?.name}</span>
              </div>
              <div className="dark:text-gray-300 text-xs mb-1">
                <strong>Customer Email:</strong>{" "}
                <span>{order.buyer_details?.email}</span>
              </div>
              <div className="dark:text-gray-300 text-xs mb-1">
                <strong>Customer Phone:</strong>{" "}
                <span>{order.buyer_details?.phone}</span>
              </div>
            </div>
          </div>

          {isEdit && (
            <div className="flex justify-between items-center py-2">
              <Button
                size="xs"
                disabled={uLoading}
                isProcessing={uLoading}
                className="rounded-sm"
                outline
                onClick={reset}
              >
                Cancel
              </Button>
              <div className="flex items-center gap-3">
                <Button
                  color="gray"
                  className="rounded-sm"
                  size="xs"
                  onClick={() => {
                    setRecord({ companyId: "", itemId: "" });
                    setAddItemModal(true);
                  }}
                >
                  <LuPlus className="mr-2 h-5 w-5" />
                  Add Product
                </Button>
                <Button
                  disabled={uLoading}
                  isProcessing={uLoading}
                  size="xs"
                  className="rounded-sm"
                  onClick={() =>
                    updateOrderRecords(screen === "quotation" ? 10 : 11)
                  }
                >
                  Save Changes
                </Button>
                {order.section_type === 1 && (
                  <Button
                    disabled={uLoading}
                    isProcessing={uLoading}
                    size="xs"
                    className="rounded-sm"
                    onClick={() => updateOrderRecords(11)}
                  >
                    Save As Invoice
                  </Button>
                )}
              </div>
            </div>
          )}

          <div className="flex justify-between items-center py-2">
            {!isEdit && screen !== "orders" ? (
              <Button
                color="gray"
                className="rounded-sm"
                size="xs"
                onClick={() => setIsEdit(true)}
              >
                <TiEdit className="mr-2 h-5 w-5" />
                Edit
              </Button>
            ) : (
              <div></div>
            )}
            <div className="flex items-center gap-2">
              {!isEdit && (
                <Button
                  color="gray"
                  className="rounded-sm"
                  size="xs"
                  onClick={() => setIsPrintOpen(true)}
                >
                  <LuPrinter className="mr-2 h-5 w-5" />
                  Print Data
                </Button>
              )}

              {!isEdit && order.section_type === 2 && (
                <Button
                  disabled={uLoading}
                  isProcessing={uLoading}
                  size="xs"
                  className="rounded-sm"
                  onClick={handlePayNow}
                >
                  Pay Now
                </Button>
              )}
            </div>
          </div>

          <div className="outline outline-1 outline-gray-300 dark:outline-gray-600 p-2 rounded-sm mb-4">
            <div className="flex justify-between items-center">
              <span className="text-[13px] font-semibold dark:text-white">
                Items
              </span>
              {order && (
                <span className="text-[13px] font-semibold dark:text-white">
                  Balance Due:{" "}
                  {isEdit
                    ? getTotalPrice()
                    : utills._currency_format(
                        order.total_price - order.amount_paid,
                        "NGN"
                      )}
                </span>
              )}
            </div>
            <div className="mt-3">
              {items.map((product) => {
                return (
                  <div key={product.uuid}>
                    <div className="mb-2 flex justify-between items-center shadow rounded-sm p-2 dark:text-gray-300 text-xs bg-gray-100 dark:bg-gray-700">
                      <div className={product.return ? "text-red-400" : ""}>
                        <span className={product.return === 1 ? "italic" : ""}>
                          {product.name}
                          {product.return
                            ? ` - (Return reason: ${product.reason})`
                            : ""}
                        </span>
                        <span className="ml-2 font-semibold">
                          {utills._currency_format(
                            Number(product.price),
                            "NGN"
                          )}
                        </span>
                        <span className="ml-5">{`(qty: ${product.quantity})`}</span>
                      </div>
                      {/* {order.section_type === 3 && ( */}
                      <div className="flex items-center gap-3">
                        {!product.return && order.section_type === 3 && (
                          <button
                            onClick={() => {
                              setReturnModal(true);
                              // console.log("==========",order)
                              setReturnId(`${product.item_uuid}/${order.uuid}`);
                            }}
                            className="text-xs text-blue-600 font-semibold hover:underline"
                          >
                            Return Item
                          </button>
                        )}
                        {product.verified === 1 && (
                          <button className="text-green-500">
                            <FaCheckCircle />
                          </button>
                        )}
                        {product.verified === 0 && (
                          <button
                            onClick={() => {
                              if (!isEdit) return;
                              setRecord({
                                companyId: order.company.uuid,
                                itemId: product.uuid,
                              });
                              setAddItemModal(true);
                            }}
                            className="text-red-500"
                          >
                            <FaTimesCircle />
                          </button>
                        )}
                      </div>
                      {/* )} */}
                    </div>
                    {/* begininng */}
                    {isEdit && (
                      <div>
                        <div className="flex items-center mb-3 border-b border-x p-1 dark:border-gray-600 rounded-b-lg">
                          <span className="dark:text-gray-300 text-xs mx-3">
                            {product.name}
                          </span>

                          <Button
                            onClick={() => {
                              if (product.newQuantity === 1) return;
                              setItems((prev) =>
                                prev.map((x) => {
                                  if (x.uuid === product.uuid)
                                    return {
                                      ...x,
                                      newQuantity:
                                        (product.newQuantity as number) - 1,
                                    };
                                  else return x;
                                })
                              );
                              utills._play_sound();
                            }}
                            size="xs"
                            color="light"
                            className="rounded-full text-lg font-semibold w-7 h-7"
                          >
                            -
                          </Button>
                          <div className="mx-1">
                            <input
                              onFocus={(e) => {
                                e.target.select();
                              }}
                              onBlur={(e) => {}}
                              onChange={(e) => {}}
                              value={product.newQuantity ?? product.quantity}
                              type="number"
                              step="any"
                              min={1}
                              className="bg-gray-50 w-10 hide-spin-buttons border-0 text-gray-900 text-xs rounded-lg focus:ring-[#167490] focus:border-[#167490] block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            />
                          </div>
                          <Button
                            size="xs"
                            color="light"
                            className="rounded-full w-7 h-7"
                            onClick={() => {
                              setItems((prev) =>
                                prev.map((x) => {
                                  if (x.uuid === product.uuid)
                                    return {
                                      ...x,
                                      newQuantity:
                                        (product.newQuantity as number) + 1,
                                    };
                                  else return x;
                                })
                              );
                              utills._play_sound();
                            }}
                          >
                            +
                          </Button>
                          <div className="ml-3 flex items-center gap-3">
                            {order.order.type === 11 && (
                              <Tooltip content="content"></Tooltip>
                            )}

                            <Tooltip content="Remove Item">
                              <span
                                onClick={(e) => {
                                  setItems((prev) =>
                                    prev.filter((x) => x.uuid !== product.uuid)
                                  );
                                }}
                                className="text-red-600"
                              >
                                <GoTrash />
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="outline outline-1 outline-gray-300 dark:outline-gray-600 flex rounded-sm mb-4">
            <div className="flex-1 p-2">
              <span className="text-[13px] font-semibold dark:text-white">
                Payment Record
              </span>

              <PaginationControl
                setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
                isLoading={ispaymentLoading}
                data={paymentHistories?.data}
                total={paymentHistories?.count ?? 0}
                layout="navigation"
                count={5}
              >
                <div className="flex flex-col mt-2">
                  {paymentHistories?.data?.map((item) => {
                    return (
                      <div className="mb-2 flex flex-col gap-1 shadow rounded-sm p-2 dark:text-gray-300 text-xs bg-gray-100 dark:bg-gray-700">
                        <div className="flex justify-between items-center">
                          <span>Date:</span>
                          <span className="font-medium">
                            {moment(item?.crdate).format("YYYY-MM-DD")}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span>Time:</span>
                          <span className="font-medium">
                            {moment(item?.crdate).format("HH:mm")}
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span>
                            Payment Type:{" "}
                            <span className="font-semibold">
                              {item.gateway}
                            </span>
                          </span>
                          <span className="font-medium">
                            {utills._currency_format(
                              Number(item.amount),
                              "NGN"
                            )}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </PaginationControl>
            </div>
            <div className="min-h-fit outline outline-1 outline-gray-200 dark:outline-gray-100" />
            <div className="flex-1 p-2">
              <span className="text-[13px] font-semibold dark:text-white">
                Order Edit
              </span>
              <PaginationControl
                setPage={(limit) =>
                  setFiltersOrders((prev) => ({ ...prev, limit }))
                }
                isLoading={isOrderHistoryLoading}
                data={orderEditHistory?.data}
                total={orderEditHistory?.count ?? 0}
                layout="navigation"
                count={5}
              >
                <div className="flex flex-col mt-2">
                  {orderEditHistory?.data.length &&
                    orderEditHistory?.data?.map((item) => {
                      return (
                        <div className="mb-2 flex flex-col gap-1 shadow rounded-sm p-2 dark:text-gray-300 text-xs bg-gray-100 dark:bg-gray-700">
                          <div className="flex justify-between items-center">
                            <span>Product Name:</span>
                            <span className="font-medium">
                              {item?.item?.name}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span>Old Qty:</span>
                            <span className="font-medium">
                              {item?.old?.quantity}
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            <span>New Qty:</span>
                            <span className="font-medium">
                              {item?.new?.quantity}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </PaginationControl>
            </div>
          </div>
          <div className="outline outline-1 outline-gray-300 dark:outline-gray-600 p-2 rounded-sm mb-4">
            <span className="text-[13px] font-semibold dark:text-white">
              Order Record
            </span>

            <div className="mt-5 dark:text-white flex items-center gap-3 flex-wrap">
              <div className="font-semibold text-[13px]">
                Rider: {order.rider?.name}
              </div>
              <div className="font-semibold text-[13px]">
                Phone: {order.rider?.phone}
              </div>
            </div>

            <div className="py-6 ml-14">
              <Timeline>
                {order.statuses.map((delivery) => {
                  const st = settings?.data.order_status.find(
                    (x: any) => x.id === delivery.status
                  );
                  return (
                    <div key={delivery.status}>
                      <Timeline.Item>
                        <Timeline.Point icon={HiCalendar} />
                        <Timeline.Content>
                          <Timeline.Time>
                            {moment(delivery.created_at).format("LLL")}
                          </Timeline.Time>
                          <Timeline.Content className="dark:text-white">
                            {delivery?.staff?.name ?? "-"}
                          </Timeline.Content>
                          <Timeline.Title>{st?.name}</Timeline.Title>
                        </Timeline.Content>
                      </Timeline.Item>
                    </div>
                  );
                })}
              </Timeline>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={returnModal}
        onClose={() => setReturnModal(false)}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <ReturnAndRefund
          close={() => setReturnModal(false)}
          returnId={returnId}
        />
      </Modal>
      <Modal
        open={addItemModal}
        onClose={() => {
          setRecord({ companyId: "", itemId: "" });
          setAddItemModal(false);
        }}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <div className="w-[700px] bg-white dark:bg-gray-800">
          <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
            <span className="text-white font-semibold text-sm">
              {record.itemId ? "Verify Product" : "Add More Product"}
            </span>
            <span
              className="text-white cursor-pointer"
              onClick={() => {
                setRecord({ companyId: "", itemId: "" });
                setAddItemModal(false);
              }}
            >
              <LiaTimesSolid />
            </span>
          </div>
          <div className="flex items-center px-3 py-5">
            {!record.itemId && (
              <div className="max-w-[300px]">
                <BranchSwitcher
                  onChange={(val) => setBranchId(val)}
                  styling={{ borderRadius: 3 }}
                />
              </div>
            )}
            <div className="flex-1">
              <ProductSearch
                companyId={record.companyId ? record.companyId : branchId}
                onSelect={(val) => {
                  if (record.itemId) {
                    if (!val.search_by_barcode) {
                      toast.error("Please Scan Barcode!!!");
                      return;
                    }
                    verifyProduct(11, val.uuid);
                    return;
                  } else {
                    setItems((prev) => [
                      ...prev,
                      {
                        uuid: val.uuid,
                        name: val.name,
                        item_uuid: "",
                        price: Number(val.price),
                        company: val.company.uuid,
                        quantity: 1,
                        item: val.uuid,
                        verified: val.search_by_barcode ? 1 : 0,
                        newQuantity: 1,
                      },
                    ]);
                  }
                  utills._play_sound();
                  setAddItemModal(false);
                  setRecord({ companyId: "", itemId: "" });
                }}
              />
            </div>
          </div>
          <div className="h-60 px-6 overflow-auto">
            {/* {items.map((item) => (
              <div
                key={item.uuid}
                className="flex justify-between text-xs items-center bg-gray-100 dark:text-white dark:bg-gray-700 mb-2 p-2 rounded-sm"
              >
                <span>{item.name}</span>
                <span>
                  {" "}
                  {item.verified ? (
                    <FaCheckCircle className="text-green-500" />
                  ) : (
                    <FaTimesCircle className="text-red-500" />
                  )}
                </span>
              </div>
            ))} */}
          </div>
        </div>
      </Modal>

      <Drawer
        open={paymentMode}
        onClose={() => setPaymentMode((prev) => !prev)}
        anchor="right"
        ModalProps={{
          keepMounted: false
        }}
        sx={{ zIndex: 10 }}
      >
        <PayNow
          total={order ? order.total_price - order.amount_paid : 0}
          customer={{
            label: order?.buyer_details?.name ?? "",
            value: order?.buyer_details?.uuid ?? "",
          }}
          uuid={order.uuid}
          order={order}
          close={() => setPaymentMode(false)}
        />
      </Drawer>

      <Invoice
        isOpen={isPrintOpen}
        close={() => setIsPrintOpen(false)}
        order={order}
      />
    </div>
  );
};

export default OrderDataScreen;
