import { Select } from "flowbite-react";
import React, { useEffect } from "react";
import { useGetUserCompaniesQuery } from "../redux/queries/company";
import { useAppSelector, useAppDispatch } from "../lib/hook";
import utills from "../lib/functions";
import { setDefaultBranch } from "../redux/slices/appUser";

interface BranchSwitch {
  onChange?: (value: string) => void;
  sizing?: "sm" | "md" | "lg";
  styling?: React.CSSProperties;
  name?: string
  defaultCompany?: string
  disabled?: boolean
}
const BranchSwitcher = (props: BranchSwitch) => {
  const dispatch = useAppDispatch();
  const { onChange, sizing, styling, name, defaultCompany, disabled=false } = props;
  const { user } = useAppSelector((state) => state.appUserConfig);
  const { data: userBranches } = useGetUserCompaniesQuery({ user: user?.uuid });

  // console.log(user)
  const defaultBranch = utills._get_default_company(
    user as AppUser,
    userBranches?.data
  );

  useEffect(() => {
    if (defaultBranch) dispatch(setDefaultBranch(defaultBranch));
  }, [defaultBranch]);

  // console.log(defaultCompany, defaultBranch)
  return (
    <Select
      name={name}
      // value={defaultCompany ?? defaultBranch}
      defaultValue={defaultCompany ?? defaultBranch}
      sizing={sizing ?? "sm"}
      id="countries"
      style={styling}
      disabled={disabled}
      onChange={(e) => onChange && onChange(e.target.value)}
    >
      {userBranches?.data.map((item) => (
        <option key={item.id} value={item.company.uuid}>
          {item.company.name}
          {` ${item.company.type !== "branch" ? " (Main branch)" : ""}`}
        </option>
      ))}
    </Select>
  );
};

export default BranchSwitcher;
