import React from 'react'

const Performance = () => {
  return (
    <div className="mt-7 rounded-t-lg">
      <div className=" mb-3 h-11 rounded-t-lg flex justify-start items-center px-4">
        <span className="font-semibold">
          Performance cut off mark
        </span>
      </div>

      <div className="grid grid-cols-3 gap-3 p-4"></div>
      <div className="flex justify-end mb-3 p-3"></div>
    </div>
  );
};

export default Performance