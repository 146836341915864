import { useState } from "react";
import { useGetCustomerOverviewQuery } from "../../redux/queries/dashboard";
import { useAppSelector } from "../../lib/hook";
import Breadcrumb from "../../components/Breadcrumb";
import { Button, Datepicker, Label, TextInput } from "flowbite-react";
import { MdSearch } from "react-icons/md";
import moment from "moment";
import { RiListUnordered } from "react-icons/ri";
import { RiExchangeFill } from "react-icons/ri";
import { MdOutlineDoneOutline } from "react-icons/md";
import { FaWallet } from "react-icons/fa";
import utills from "../../lib/functions";
import { BiSolidBank } from "react-icons/bi";
import CustomPagination from "../../components/CustomPagination";

const CustomerOverView = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: user?.company?.uuid,
  });
  const [filterFields, setFilterFields] = useState({
    name: "",
    start_date: "",
    end_date: "",
  });
  const { data: customersOverview, isLoading } =
    useGetCustomerOverviewQuery(filters);

  const applyFilter = () => {
    setFilters((prev) => ({ ...prev, ...filterFields, limit: "0,10" }));
  };

  const reset = () => {
    setFilterFields({
      name: "",
      start_date: "",
      end_date: "",
    });
    setFilters({
      limit: "0,10",
      company: user?.company?.uuid,
    });
  };

  return (
    <div>
      <Breadcrumb title="Customer Overview" />
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-center gap-5">
        <div className="h-20 rounded-lg p-3 flex flex-col justify-between flex-1">
          <div className="flex gap-3 items-center">
            <BiSolidBank className="text-[#167490] text-lg" />

            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Loan
            </span>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[18px]">
            <span>₦18,083.00</span>
          </div>
        </div>
        <div className="h-20 rounded-lg p-3 flex flex-col justify-between flex-1">
          <div className="flex gap-3 items-center">
            <FaWallet className="text-[#167490] text-xl" />

            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Wallet
            </span>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[18px]">
            <span>₦18,083.00</span>
          </div>
        </div>
        <div className="h-20 rounded-lg p-3 flex flex-col justify-between flex-1">
          <div className="flex gap-3 items-center">
            <RiListUnordered className="text-[#167490] text-xl" />

            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Orders
            </span>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[18px]">
            <span>₦18,083.00</span>
          </div>
        </div>
        <div className="h-20 rounded-lg p-3 flex flex-col justify-between flex-1">
          <div className="flex gap-3 items-center">
            <RiExchangeFill className="text-[#167490] text-xl" />

            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Barter
            </span>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[18px]">
            <span>₦18,083.00</span>
          </div>
        </div>
        <div className="h-20 rounded-lg p-3 flex flex-col justify-between flex-1">
          <div className="flex gap-3 items-center">
            <MdOutlineDoneOutline className="text-[#167490] text-xl" />

            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Grand Total Paid
            </span>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[18px]">
            <span>₦18,083.00</span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="flex justify-between items-end gap-3">
          <div>
            <Label
              htmlFor="customer-name"
              value="Customer Name"
              className="text-sm"
            />
            <TextInput
              sizing="md"
              type="text"
              value={filterFields.name}
              icon={MdSearch}
              placeholder="search..."
              onChange={(e) =>
                setFilterFields((prev) => ({ ...prev, name: e.target.value }))
              }
            />
          </div>
          <div>
            <Label
              htmlFor="start-date"
              value="Start Date"
              className="text-sm"
            />
            <Datepicker
              value={filterFields.start_date}
              onSelectedDateChanged={(date) =>
                setFilterFields((prev) => ({
                  ...prev,
                  start_date: moment(date).format("YYYY-MM-DD"),
                }))
              }
              name="date"
              datepicker-format={"YYYY-MM-DD"}
            />
          </div>

          <div>
            <Label htmlFor="end-date" value="End Date" className="text-sm" />
            <Datepicker
              value={filterFields.end_date}
              onSelectedDateChanged={(date) =>
                setFilterFields((prev) => ({
                  ...prev,
                  end_date: moment(date).format("YYYY-MM-DD"),
                }))
              }
              name="date"
              datepicker-format={"YYYY-MM-DD"}
            />
          </div>

          <div className="flex gap-2">
            <Button size="sm" onClick={applyFilter}>
              Apply
            </Button>
            <Button outline size="sm" onClick={reset}>
              Reset
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={[]}
            total={20}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Customer
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Loan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Wallet
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Orders
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Barter
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Total Paid
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Last Purchase Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Staff Assigned
                  </th>
                </tr>
              </thead>
              <tbody>
                {customersOverview?.data?.map((item, i) => (
                  <tr
                    key={i}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">{item.company.name}</td>
                    <td className="px-6 py-4">{item.company.loan}</td>
                    <td className="px-6 py-4">{item.wallet}</td>
                    <td className="px-6 py-4">{item.orders}</td>
                    <td className="px-6 py-4">{item.barter_wallet}</td>
                    <td className="px-6 py-4">{item.total_paid}</td>
                    <td className="px-6 py-4">
                      {item.last_purchase_date
                        ? moment(item.last_purchase_date).format("YYYY-MM-DD")
                        : "-"}
                    </td>
                    <td className="px-6 py-4"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>
    </div>
  );
};

export default CustomerOverView;
