import { FileInput } from "flowbite-react";
import React, { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { useUploadFileMutation } from "../redux/queries/upload";
import { LuLoader } from "react-icons/lu";

interface IAttachment {
  //   children: JSX.Element;
  onUpload: (link: string[], title: string[]) => void;
}
const Attachment = (props: IAttachment) => {
  const { onUpload } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [uploadFile, { isLoading }] = useUploadFileMutation();

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setIsOpen(true)
    const res = await uploadFile(file);
    setIsOpen(false)
    if ("data" in res) {
      const { links, data } = res.data;
      onUpload(links, data);
      toast.success("File Attached Successfully...");
    } else toast.error("Unable to attach document");
  };

  return (
    <div className="relative">
      {(isOpen || isLoading) && (
        <div className="absolute z-30 right-0 rounded-md bg-black/50 w-full h-full flex items-center justify-end text-white">
          <span className=" text-xs font-semibold mr-3">Please wait...</span>
          <LuLoader className="animate-spin mr-3" />
        </div>
      )}
      <FileInput
        id="file"
        onChange={(e) => handleFileUpload(e)}
        disabled={isLoading}
      />

    </div>
  );
};

export default Attachment;
