import { useState } from "react";

interface ScrapProps {
  close: () => void;
  item_uuid: string;
}

const ModelScrap = ({ close, item_uuid }: ScrapProps) => {
  const [filters, setFilters] = useState({
    item_uuid: item_uuid,
  });

  return (
    <div className="w-[100%] pt-4 pb-2 px-3 bg-gray-200 dark:bg-gray-900"></div>
  );
};

export default ModelScrap;
