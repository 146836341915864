import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Badge,
  Banner,
  Button,
  Checkbox,
  Label,
  Spinner,
  Table,
  TextInput,
} from "flowbite-react";
import { IoIosAdd } from "react-icons/io";
import { SiAuth0 } from "react-icons/si";
import Modal from "react-responsive-modal";
import { sideBarMenu } from "../../layout/data";
import { LiaTimesSolid } from "react-icons/lia";
import {
  useCreatePermissionMutation,
  useDeletePermissionMutation,
  useGetPermissionsQuery,
  useUpdatePermissionMutation,
} from "../../redux/queries/permissions";
import BranchSwitcher from "../../components/BranchSwitcher";
import { useAppSelector } from "../../lib/hook";
import toast from "react-hot-toast";
import emptyIcon from "../../assets/empty.png";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import moment from "moment";
import CustomDropdown from "../../components/CustomDropdown";
import { CiCircleMore } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import { IoTrashOutline } from "react-icons/io5";

const Permissions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [branch, setBranch] = useState(defaultBranchUUid);
  const [deletePermission] = useDeletePermissionMutation();
  const [row, setRow] = useState<IPermission | undefined>();

  const { data: permissions, isLoading } = useGetPermissionsQuery({
    company: branch,
  });

  const deleteHandler = (id: string) => {
    const action = window.confirm("Are you sure you want to delete");
    if (action)
      utills._asynchronous_toast(
        deletePermission,
        "Record Deleted",
        "Unable to delete",
        id
      );
  };

  return (
    <div>
      <Breadcrumb
        title="Permissions"
        rightButton={
          <Button size="xs" onClick={() => setIsOpen(true)}>
            <IoIosAdd className="mr-2 h-5 w-5" /> New Permission
          </Button>
        }
      />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Permissions.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                See and manage user permissions.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <SiAuth0 className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="bg-white mb-3 p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="grid grid-cols-4 gap-3">
          <div>
            <BranchSwitcher
              sizing="sm"
              onChange={(value) => setBranch(value)}
            />
          </div>
        </div>
      </div>

      <div className="">
        <Table hoverable className="">
          <Table.Head>
            <Table.HeadCell className="w-8">#</Table.HeadCell>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>status</Table.HeadCell>
            <Table.HeadCell>Created</Table.HeadCell>
            <Table.HeadCell>
              Action
              <span className="sr-only">Edit</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {permissions?.data.map((perm) => (
              <Table.Row
                key={perm.uuid}
                className="bg-white border-b dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell>
                  <ImageView url={utills._default_img} className="w-6 h-6" />
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {perm.name}
                </Table.Cell>
                <Table.Cell>
                  <Badge
                    className="w-max"
                    color={perm.status ? "success" : "warning"}
                  >
                    {perm.status ? "active" : "inactive"}
                  </Badge>
                </Table.Cell>
                <Table.Cell>{moment(perm.crdate).format("LLL")}</Table.Cell>
                <Table.Cell onClick={(e) => e.stopPropagation()}>
                  <CustomDropdown
                    trigger={
                      <div className="">
                        <CiCircleMore className="text-2xl" />
                      </div>
                    }
                    menu={[
                      {
                        icon: FaRegEdit,
                        title: "Edit",
                        action: () => {
                          setRow(perm);
                          setIsOpen(true);
                        },
                      },
                      {
                        icon: IoTrashOutline,
                        title: "Delete",
                        action: () => deleteHandler(perm.uuid),
                      },
                    ]}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {isLoading && (
          <div className="flex items-center justify-center gap-2 h-20 mt-6">
            <Spinner />
            <Spinner />
            <Spinner />
          </div>
        )}
        {permissions?.count === 0 && (
          <div className="flex items-center justify-center my-5">
            <div className="flex flex-col items-center">
              <img src={emptyIcon} alt="empty record" className="w-20" />
              <span className="dark:text-gray-400 mt-2 text-xs">No record</span>
            </div>
          </div>
        )}
      </div>

      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setRow(undefined);
        }}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <CreatePermission
          close={() => {
            setIsOpen(false);
            setRow(undefined);
          }}
          row={row}
        />
      </Modal>
    </div>
  );
};

export default Permissions;

const CreatePermission = ({
  close,
  row,
}: {
  close: () => void;
  row?: IPermission;
}) => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [createPermission, { isLoading }] = useCreatePermissionMutation();
  const [updatePermission, { isLoading:ploading}] = useUpdatePermissionMutation();
  const [perms, setPerms] = useState([""]);
  const [branch, setBranch] = useState(row?.company ?? defaultBranchUUid);
  const [name, setName] = useState("");

  const loading = row?.uuid ? ploading : isLoading
  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    code: string
  ) => {
    if (e.target.checked) {
      if (code.includes("-")) {
        const [parent] = code.split("-");
        setPerms((prev) => [...prev, code, parent]);
      } else setPerms((prev) => [...prev, code]);
    } else {
      if (!code.includes("-")) {
        setPerms((prev) => prev.filter((item) => !item.includes(code)));
      } else setPerms((prev) => prev.filter((item) => item !== code));
    }
  };

  const submit = async () => {
    if (!name) {
      toast.error("Please enter permission name!!!");
      return;
    }
    const res = row?.uuid
      ? await updatePermission({
          permissions: perms,
          company: branch,
          name,
          status: 1,
          uuid: row.uuid
        })
      : await createPermission({
          permissions: perms,
          company: branch,
          name,
          status: 1,
        });
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success(row?.uuid ? "Permission Updated" : "Permission Created");
        close();
      } else toast.error("Unable to create permission!!!!");
    } else toast.error("Unable to create permission!!!!");
  };

  useEffect(() => {
    if (row?.uuid) {
      const { name, permissions, company } = row;
      setName(name);
      setBranch(company);
      setPerms(permissions.split(",").filter((item) => item));
    }
  }, [row]);
  return (
    <div className="bg-white w-4/5 min-w-[800px] dark:bg-gray-800">
      <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
        <span className="text-white font-semibold text-sm">
          {row?.uuid ? "Update" : "Create"} Permission
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <div className="py-2 px-5 grid grid-cols-2 gap-3">
        <div className="flex flex-col">
          <Label value="Permission name" />
          <TextInput
            disabled={loading}
            sizing="sm"
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <Label value="Branch" />
          <BranchSwitcher defaultCompany={branch} onChange={(val) => setBranch(val)} />
        </div>
      </div>

      <div className="grid grid-cols-3 p-5 gap-3">
        {sideBarMenu.map((menu) => {
          if (menu.children) {
            return (
              <div
                key={menu.title}
                className="text-xs shadow-md bg-gray-200 dark:bg-gray-700 p-2 rounded-md"
              >
                <div>
                  <label
                    id={menu.code}
                    className="flex items-center gap-2 cursor-pointer"
                  >
                    <Checkbox
                      id={menu.code}
                      checked={perms.includes(menu.code)}
                      onChange={(e) => handleCheck(e, menu.code)}
                    />
                    <span className="dark:text-white">{menu.title}</span>
                  </label>
                </div>
                <div className="ml-6 flex flex-col gap-2 mt-2">
                  {menu.children.map((submenu) => (
                    <label
                      id={submenu.code}
                      key={submenu.title}
                      className="flex items-center gap-2 cursor-pointer"
                    >
                      <Checkbox
                        id={submenu.code}
                        checked={perms.includes(submenu.code)}
                        onChange={(e) => handleCheck(e, submenu.code)}
                      />
                      <span className="dark:text-white">{submenu.title}</span>
                    </label>
                  ))}
                </div>
              </div>
            );
          }
          return (
            <div
              key={menu.title}
              className="text-xs shadow-md bg-gray-200 dark:bg-gray-700 p-2 rounded-md"
            >
              <label
                className="dark:text-white flex items-center gap-2 cursor-pointer"
                id={menu.code}
              >
                <Checkbox
                  id={menu.code}
                  checked={perms.includes(menu.code)}
                  onChange={(e) => handleCheck(e, menu.code)}
                />
                <span>{menu.title}</span>
              </label>
            </div>
          );
        })}
      </div>

      <div className="flex justify-end gap-2 items-center py-2 px-5">
        <Button
          disabled={loading}
          className="rounded-sm"
          size="xs"
          outline
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          isProcessing={loading}
          className="rounded-sm"
          size="xs"
          onClick={submit}
        >
          {row?.uuid ? "Update": "Save"}
        </Button>
      </div>
    </div>
  );
};
