import React, { useState, useRef, useEffect } from "react";
import utills from "../lib/functions";
import {
  useGetProductsQuery,
  useLazyGetProductQuery,
  useLazyGetProductsQuery,
} from "../redux/queries/products";
import {
  Button,
  Select,
  TextInput,
  Spinner,
  Modal,
  Label,
  Radio,
  Table,
} from "flowbite-react";
import { CgMenuGridO } from "react-icons/cg";
import { useAppDispatch, useAppSelector } from "../lib/hook";
import { addToCart } from "../redux/slices/cart";
import moment from "moment";
import { FcSearch } from "react-icons/fc";
import { debounce } from "lodash";
import { useGetCategoriesQuery } from "../redux/queries/settings";
import emptyIcon from "../assets/empty.png";
import { IoIosCheckmarkCircle, IoMdEye } from "react-icons/io";
import ImageViewer from "react-simple-image-viewer";
import { boolean } from "yup";

const CartProducts = ({ setQuantityFocus }: { setQuantityFocus: (id: string)=> void }) => {
  const dispatch = useAppDispatch();
  const stopSearch = useRef<boolean>(false)
  // const autocompleteRef = useRef<HTMLDivElement>(null);
  const searchField = useRef<HTMLInputElement>(null);
  const { user, defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const { company  } = useAppSelector((state) => state.utills);

  const [variantModal, setVariantsModal] = useState<boolean>(false);
  const [id, setId] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [openModal, setOpenModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [record, setRecord] = useState<Product[] | []>([]);
  const [imageInView, setImageInView] = useState("");
  const [filters, setFilters] = useState({
    limit: "0,60",
    category: "",
    name: ""
  });

  const [getProduct] = useLazyGetProductsQuery();
  const { data: products, isLoading, isSuccess } = useGetProductsQuery({...filters, company: company === "" ?  defaultBranchUUid : company });

  const { data: categories } = useGetCategoriesQuery({
    type: user?.company.type,
    status: 1,
  });

  const viewItem = (img: string) => {
    setImageInView(img);
    setOpenModal(true);
  };

  interface Payload {
    itemId: string;
    quantity: number;
    lastEdited: string;
    created: string;
    price_in_naira: number;
    price_in_dollar: number;
    variant_id?: number;
  }

  const addItemToCart = (
    id: string,
    price_in_naira: string | number,
    price_in_dollar: string | number,
    verified: boolean,
    variant_id?: number
  ) => {
    let payload: Payload = {
      itemId: id,
      quantity: Number(quantity),
      lastEdited: moment().format("LLL"),
      created: moment().format("LLL"),
      price_in_naira: Number(price_in_naira),
      price_in_dollar: Number(price_in_dollar)
    };

    if (variant_id) payload.variant_id = variant_id;
    dispatch(
      addToCart({
        itemId: id,
        quantity: Number(quantity),
        lastEdited: moment().format("LLL"),
        created: moment().format("LLL"),
        price_in_naira: Number(price_in_naira),
        price_in_dollar: Number(price_in_dollar),
        variant_id,
        verified: verified ? 1 : 0
      })
    );
    setSearchKey("");
    setFilters((prev) => ({ ...prev, name: "" }));
    setQuantityFocus(id)
    // if (searchField.current) searchField.current.focus();
    utills._play_sound();
    setQuantity("1");
  };

  const debouncedHandleInput = debounce(async (text: string) => {
    if (text.length < 2) return;
    const res = await getProduct({
      limit: "0,10",
      status: 1,
      name: text.toLowerCase(),
    });
    if (res.data?.data.length === 1) {
      const [item] = res.data?.data;
      addItemToCart(item.uuid, item.selling_price, item.pricein_dollar, item.search_by_barcode as boolean);
      setRecord([]);
      setSearchKey("");
      debouncedHandleInput.cancel();
    } else {
      if (text.length > 1) setRecord(res.data?.data ?? []);
    }
  }, 2000);

  const closeImageViewer = () => setOpenModal(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchKey(value);
    setFilters((prev) => ({ ...prev, name: value.trim() }));
    // if (value.length < 2) setRecord([]);

    // debouncedHandleInput.cancel();

    // debouncedHandleInput(value);
  };

  useEffect(() => {
    if (searchField.current) searchField.current.focus();
  }, []);

  useEffect(()=> {
      if(products?.data.length === 1){
        const [ firstProduct ] = products.data
        if (utills._display_variant(firstProduct.variants)) {
          setId(firstProduct.uuid);
          setVariantsModal(true);
        } else {
          addItemToCart(
            firstProduct.uuid,
            firstProduct.selling_price,
            firstProduct.pricein_dollar,
            firstProduct.search_by_barcode as boolean
          );
        }
      }
  },[products?.data])

  return (
    <div className="flex-1">
      {record.length > 0 && (
        <div
          onClick={() => setRecord([])}
          className="fixed w-full h-full z-20"
        ></div>
      )}
      <div className="bg-white dark:bg-gray-700 w-full h-full rounded-md p-2">
        <div className="flex items-center gap-2">
          <Button size="xs" className="rounded-sm py-1" color="light">
            <CgMenuGridO className="text-sm" />
          </Button>
          <Select
            sizing="sm"
            style={{ borderRadius: 2 }}
            className="w-40"
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, category: e.target.value }))
            }
          >
            <option value="">All categories</option>
            {categories?.data.map((cat) => (
              <option value={cat.uuid} key={cat.id}>
                {cat.name}
              </option>
            ))}
          </Select>
          <div className="relative flex-1 flex items-center">
            <TextInput
              className="w-12 mr-2"
              sizing="sm"
              type="number"
              step="any"
              min={1}
              onChange={(e) => setQuantity(e.target.value)}
              value={quantity}
              placeholder="qty.."
              style={{ borderRadius: 2 }}
            />
            <TextInput
              ref={searchField}
              className="flex-1"
              sizing="sm"
              id="pos_search_field"
              type="text"
              icon={FcSearch}
              // onPaste={}
              onChange={handleChange}
              value={searchKey}
              placeholder="search..."
              style={{ borderRadius: 2 }}
            />
          </div>
        </div>
        <div
          className="border border-gray-200 dark:border-gray-500 rounded-md mt-2 p-2 overflow-auto "
          style={{ height: "calc(100vh - 115px)" }}
        >
          {isLoading && (
            <div className="text-center pt-8">
              <Spinner size="xl" aria-label="Default status example" />
            </div>
          )}
          {!isLoading && products?.data.length === 0 && (
            <div className="flex items-center justify-center my-5">
              <div className="flex flex-col items-center">
                <img src={emptyIcon} alt="empty record" className="w-20" />
                <span className="dark:text-gray-400 mt-2 text-xs">
                  No record found
                </span>
              </div>
            </div>
          )}
          <div className="grid grid-cols-6 gap-3">
            {products?.data.map((item, index) => {
              const imgurl =
                item.images_links[0] ===
                "https://dev-assets.gohealthy.ng/items/"
                  ? utills._default_img
                  : item.images_links[0];
              return (
                <div
                  tabIndex={index}
                  key={item.id}
                  className="rounded-lg cursor-pointer"
                  onClick={() => {
                    if (utills._display_variant(item.variants)) {
                      setId(item.uuid);
                      setVariantsModal(true);
                    } else {
                      addItemToCart(
                        item.uuid,
                        item.selling_price,
                        item.pricein_dollar,
                        item.search_by_barcode as boolean
                      );
                    }
                  }}
                >
                  <div className="relative">
                    <div className="bg-yellow-500 absolute top-1 left-2 rounded-xs flex items-center justify-center px-1 rounded-sm">
                      <span className="text-[6px] text-white font-semibold">
                        {item.quantity} left
                      </span>
                    </div>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        viewItem(imgurl);
                      }}
                      className="cursor-pointer bg-yellow-500 absolute top-1 right-2 rounded-full p-[2px]"
                    >
                      <IoMdEye className="text-white" />
                    </span>
                    <div></div>
                    <div
                      className="w-full h-28 rounded-sm bg-white  border border-gray-300 dark:border-gray-600"
                      style={{
                        backgroundImage: `url(${imgurl})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                  </div>
                  <div className="flex flex-col mt-2 w-full">
                    <span
                      className="text-[9px] font-semibold dark:text-white"
                      style={{ lineHeight: "11px" }}
                    >
                      {item.name}
                    </span>
                    <span className="text-[9px] dark:text-white mt-0">
                      {utills._currency_format(Number(item.price), "NGN")}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {openModal && (
        <ImageViewer
          src={[imageInView]}
          currentIndex={0}
          // disableScroll={ false }
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
      <Modal
        show={variantModal}
        size="md"
        dismissible
        position="top-center"
        onClose={() => setVariantsModal(false)}
        style={{ borderRadius: 0 }}
      >
        <Modal.Header className="p-2">
          <span className="text-[14px] font-semibold">Select Variants</span>
        </Modal.Header>
        <Modal.Body className="p-0 rounded-xs">
          <VariantComponent
            product={products?.data.find((item) => item.uuid === id)}
            addToCart={addItemToCart}
            close={() => setVariantsModal(false)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CartProducts;

interface VariantsComponent {
  product: Product | undefined;
  addToCart: (
    id: string,
    price_in_naira: string | number,
    price_in_dollar: string | number,
    verified: boolean,
    variant_id?: number
  ) => void;
  close: () => void;
  cart? : InvoiceCart
}
export const VariantComponent = (props: VariantsComponent) => {
  const { product:receivedProduct, addToCart, close, cart } = props;
  const [selectedUnitId, setSelectedUnitId] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [ getProduct, { data:fetchedProduct }] = useLazyGetProductQuery()

  const selectVariant = (id: number, itemPrice: number) => {
    setPrice(itemPrice);
    setSelectedUnitId(id);
  };

  const getProductToEdit = (id:string) => getProduct(id)


  useEffect(() => {
    if(cart){
      setSelectedUnitId(cart.variant_id ?? 0);
      setPrice(cart.price);
      getProductToEdit(cart.uuid)
    }else {
      setSelectedUnitId(receivedProduct?.variants[0].id ?? null);
      setPrice(receivedProduct?.variants[0].price ?? null);
    }
  }, []);


  const product = cart ? fetchedProduct?.data : receivedProduct

  const img = product?.images_links[0];

  return (
    <div className="flex p-3">
      <div className="max-w-[165px]">
        <div
          className="w-40 h-40 rounded-sm border border-gray-300"
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <div>
          <p className="text-[12px] dark:text-white font-semibold mt-2 leading-4">
            {product?.name}
          </p>
          <div className="text-[14px] font-semibold mt-3 dark:text-white">
            {utills._currency_format(product?.selling_price ?? 0, "NGN")}
          </div>
          <div className="flex justify-between items-center mt-2 font-semibold text-[12px]">
            <span
              className={`${
                product?.stock === 0 ? "text-red-700" : "text-green-700"
              } font-bold`}
            >
              {product?.stock === 0 ? "Out of stock" : "In stock"}
            </span>
            <span className="text-gray-600 dark:text-gray-300">
              {product?.stock} left
            </span>
          </div>
        </div>
      </div>
      <div className="flex-1 ml-4 flex flex-col justify-between">
        <div className="mb-3">
          {product?.variants.map((item) => {
            if(item.is_required === 0) return null
            return (
              <div
              onClick={() => selectVariant(item.id, item.price)}
              key={item.id}
              className={`relative overflow-x-auto mb-2 sm:rounded-md ${
                selectedUnitId === item.id && "outline outline-1 outline-gray-500"
              } p-1 cursor-pointer`}
            >
              {selectedUnitId === item.id && (
                <span className="absolute right-0 z-40">
                  <IoIosCheckmarkCircle className="text-green-500" />
                </span>
              )}
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-[10px] text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="">
                      Unit
                    </th>
                    <th scope="col" className="">
                      Qty
                    </th>
                    <th scope="col" className="">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b text-[10px] dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="p-1 font-medium truncate w-20 text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {item.name}
                    </th>
                    <td className="p-1">{item.quantity}</td>
                    <td className="p-1">
                      {utills._currency_format(item.price ?? 0, "NGN")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            )
          })}
          {/* <div className="flex items-center gap-3">
            <div className="flex items-center gap-2">
              <Radio
                id="united-state"
                name="countries"
                value="USA"
                defaultChecked
                className="hidden"
              />
              <Label htmlFor="united-state">
                <div className="border bg-[#1f91b2] border-gray-400 rounded-sm w-16 h-8 flex justify-center items-center">
                  <span className="text-white text-xs font-semibold">Blue</span>
                </div>
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="germany"
                name="countries"
                value="Germany"
                className="hidden"
              />
              <Label htmlFor="germany">
                <div className="border border-gray-400 rounded-sm w-16 h-8 flex justify-center items-center">
                  <span className="text-xs font-semibold">Red</span>
                </div>
              </Label>
            </div>
          </div> */}
        </div>
        <Button
          onClick={() => {
            addToCart(
              product?.uuid ?? "",
              price ?? 0,
              product?.pricein_dollar ?? 0,
              product?.search_by_barcode as boolean,
              selectedUnitId
            );
            close();
          }}
          size="xs"
          color="dark"
          className="w-full rounded-sm"
        >
          Add to cart
        </Button>
      </div>
    </div>
  );
};
