import { Alert, Button, Modal, Spinner, Tooltip } from "flowbite-react";
import { useAppDispatch } from "../lib/hook";
import { useGetProductQuery } from "../redux/queries/products";
import utills from "../lib/functions";
import { VscTrash } from "react-icons/vsc";
import { Dispatch } from "redux";
import { changeQty, deleteItem, updateCart } from "../redux/slices/cart";
import ImageViewer from "react-simple-image-viewer";
import { useEffect, useState } from "react";
import { HiInformationCircle } from "react-icons/hi";
import { FaRegEdit } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import toast from "react-hot-toast";

const Cart = ({
  cart,
  needFocus,
}: {
  cart: Cart[] | [];
  needFocus: string;
}) => {
  const dispatch = useAppDispatch();

  return (
    <div className="overflow-auto" style={{ height: "calc(100vh - 315px)" }}>
      {cart.map((cartItem) => (
        <CartItem
          key={cartItem.itemId}
          id={cartItem.itemId}
          qty={cartItem.quantity}
          variantId={cartItem.variant_id}
          price={cartItem.price_in_naira}
          dispatch={dispatch}
          needFocus={needFocus}
        />
      ))}
    </div>
  );
};

export default Cart;

const CartItem = ({
  id,
  qty,
  variantId,
  price,
  dispatch,
  needFocus,
}: {
  id: string;
  qty: number;
  price: number;
  variantId?: number;
  dispatch: Dispatch;
  needFocus: string;
}) => {
  const { data: product, isLoading } = useGetProductQuery(id);
  const [openModal, setOpenModal] = useState(false);
  const [variantModal, setVariantsModal] = useState<boolean>(false);

  const viewItem = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (product?.data) {
      if (product.data.has_quantity_abt_expire) {
        toast.error(`${product.data.name} has about to expire quantity`);
      }
    }
  }, [product]);

  useEffect(() => {
    if (product?.data.uuid === needFocus) {
      const element = document.getElementById(needFocus);
      element?.focus();
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <div>
        <Spinner size="lg" />
      </div>
    );
  }
  // if(product.has)
  if (product?.status === 404) {
    return (
      <Alert color="failure" icon={HiInformationCircle} className="my-1">
        <span className="font-medium">Error!</span> Unable to get item data.
      </Alert>
    );
  }

  // TODO   check if product has been deleted or is out of stock

  const imgurl =
    product?.data?.images_links[0] === "https://dev-assets.gohealthy.ng/items/"
      ? utills._default_img
      : product?.data.images_links[0];

  const closeImageViewer = () => setOpenModal(false);

  return (
    <div className="border relative border-gray-100 dark:border-gray-500 shadow-sm mt-3 px-2 py-1 rounded-md flex items-center">
      {product?.data.has_quantity_abt_expire && <span className="absolute text-[10px] z-20 text-red-500 top-[-3px] right-1 font-semibold">Product has expired quanntity</span>}
      <span
        className="cursor-pointer"
        onClick={() => {
          dispatch(deleteItem(id));
          utills._play_sound();
        }}
      >
        <Tooltip content="Remove item from cart">
          <VscTrash className="text-red-600" />
        </Tooltip>
      </span>
      <div>
        <div
          onClick={viewItem}
          className="w-10 h-10 rounded-md bg-white dark:bg-gray-600 border border-gray-300 dark:border-gray-600 cursor-pointer"
          style={{
            backgroundImage: `url(${imgurl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
      <div className="ml-2 flex justify-between items-center w-full">
        <div className="">
          <div className="text-xs text-gray-700 dark:text-white">
            {product?.data.name}
          </div>
          <div className="text-[10px] text-gray-700 dark:text-white font-semibold mt-1">
            {utills._currency_format(Number(price), "NGN")}
            <span className="ml-2 mr-2">X {qty}</span>
            <span>{utills._currency_format(Number(price) * qty, "NGN")}</span>
          </div>
        </div>
        <div className="flex items-center">
          {variantId && (
            <span
              onClick={() => setVariantsModal(true)}
              className="cursor-pointer mr-1"
            >
              <Tooltip content="Edit Variants">
                <FaRegEdit className="text-xs text-gray-700 hover:text-gray-400 dark:text-gray-300 dark:hover:text-gray-500" />
              </Tooltip>
            </span>
          )}
          <Button
            onClick={() => {
              if (qty === 1) return;
              dispatch(changeQty({ id: id, qty: qty - 1 }));
              utills._play_sound();
            }}
            size="xs"
            color="light"
            className="rounded-full text-lg font-semibold w-7 h-7"
          >
            -
          </Button>
          <div className="mx-1">
            <input
              onFocus={(e) => {
                e.target.select();
              }}
              id={product?.data.uuid}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  document.getElementById("pos_search_field")?.focus();
                }
              }}
              onBlur={(e) => {
                if (e.target.value === "0") {
                  dispatch(changeQty({ id: id, qty: 1 }));
                  utills._play_sound();
                }
              }}
              onChange={(e) => {
                dispatch(changeQty({ id: id, qty: Number(e.target.value) }));
                utills._play_sound();
              }}
              type="number"
              step="any"
              // min={1}
              value={qty}
              className="bg-gray-50 w-10 hide-spin-buttons border-0 text-gray-900 text-xs rounded-lg focus:ring-[#167490] focus:border-[#167490] block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            />
          </div>
          {/* <span className="mx-1 text-xs dark:text-white cursor-pointer px-1">{qty}</span> */}
          <Button
            size="xs"
            color="light"
            className="rounded-full w-7 h-7"
            onClick={() => {
              dispatch(changeQty({ id: id, qty: qty + 1 }));
              utills._play_sound();
            }}
          >
            +
          </Button>
        </div>
      </div>
      {openModal && (
        <ImageViewer
          src={[imgurl as string]}
          currentIndex={0}
          // disableScroll={ false }
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
      <Modal
        show={variantModal}
        size="md"
        dismissible
        position="top-center"
        onClose={() => setVariantsModal(false)}
        style={{ borderRadius: 0 }}
      >
        <Modal.Header className="p-2">
          <span className="text-[14px] font-semibold">Update Variants</span>
        </Modal.Header>
        <Modal.Body className="p-0 rounded-xs">
          <VariantComponent
            product={product?.data}
            close={() => setVariantsModal(false)}
            variantId={variantId}
            price={price}
            dispatch={dispatch}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

interface VariantsComponent {
  product: Product | undefined;
  close: () => void;
  variantId?: number;
  price: number;
  dispatch: Dispatch;
}
const VariantComponent = (props: VariantsComponent) => {
  const { product, close, variantId, price: cartPrice, dispatch } = props;
  const [selectedUnitId, setSelectedUnitId] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const img = product?.images_links[0];

  const selectVariant = (id: number, itemPrice: number) => {
    setPrice(itemPrice);
    setSelectedUnitId(id);
  };

  useEffect(() => {
    setSelectedUnitId(variantId ?? 0);
    setPrice(cartPrice);
  }, []);
  return (
    <div className="flex p-3">
      <div className="max-w-[165px]">
        <div
          className="w-40 h-40 rounded-sm border border-gray-300"
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <div>
          <p className="text-[12px] dark:text-white font-semibold mt-2 leading-4">
            {product?.name}
          </p>
          <div className="text-[14px] font-semibold mt-3 dark:text-white">
            {utills._currency_format(product?.selling_price ?? 0, "NGN")}
          </div>
          <div className="flex justify-between items-center mt-2 font-semibold text-[12px]">
            <span
              className={`${
                product?.stock === 0 ? "text-red-700" : "text-green-700"
              } font-bold`}
            >
              {product?.stock === 0 ? "Out of stock" : "In stock"}
            </span>
            <span className="text-gray-600 dark:text-gray-300">
              {product?.stock} left
            </span>
          </div>
        </div>
      </div>
      <div className="flex-1 ml-4 flex flex-col justify-between">
        <div>
          {product?.variants.map((item) => {
            if (item.is_required === 0) return null;
            return (
              <div
                onClick={() => selectVariant(item.id, item.price)}
                key={item.id}
                className={`relative overflow-x-auto sm:rounded-md ${
                  selectedUnitId === item.id &&
                  "outline outline-1 outline-gray-500"
                } p-1 cursor-pointer`}
              >
                {selectedUnitId === item.id && (
                  <span className="absolute right-0 z-40">
                    <IoIosCheckmarkCircle className="text-green-500" />
                  </span>
                )}
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead className="text-[10px] text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="">
                        Unit
                      </th>
                      <th scope="col" className="">
                        Qty
                      </th>
                      <th scope="col" className="">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b text-[10px] dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th
                        scope="row"
                        className="p-1 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item.name}
                      </th>
                      <td className="p-1">{item.quantity}</td>
                      <td className="p-1">
                        {utills._currency_format(item.price ?? 0, "NGN")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
        <Button
          onClick={() => {
            dispatch(
              updateCart({
                id: product?.uuid ?? "",
                price: price,
                variant_id: selectedUnitId,
              })
            );
            utills._play_sound();
            close();
          }}
          size="xs"
          color="dark"
          className="w-full rounded-sm"
        >
          Add to cart
        </Button>
      </div>
    </div>
  );
};
