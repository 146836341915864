import { api } from "../baseConfig";

interface Filters {
  type?: string
  status?: number
}
export const settingsSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<SettingsResponse, void>({
      query: () => ({
        url: "/settings",
        method: "GET",
      }),
      providesTags: ["Settings"],
    }),
    getCountries: builder.query<ICountryResponse, void>({
      query: () => ({
        url: "/settings/countries",
        method: "GET",
      }),
      providesTags: ["Settings"],
    }),
    getCategories: builder.query<CategoryResponse, Filters>({
      query: (filters) => ({
        url: "/items-categories",
        method: "GET",
        params: filters
      }),
      providesTags: ["Settings"],
    }),
  }),
});

export const { useGetSettingsQuery, useGetCategoriesQuery, useGetCountriesQuery } = settingsSlice;
