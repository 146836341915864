import { useEffect } from "react";
import { useLazyGetOrderQuery } from "../../redux/queries/order";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import PaginationControl from "../../components/PaginationControl";
import utills from "../../lib/functions";
import ImageView from "../../components/ImageView";
import moment from "moment";

interface IStockHistoryView {
  isOpen: boolean;
  close: () => void;
  orderUuid?: string;
}
const ViewItems = (props: IStockHistoryView) => {
  const { isOpen, close, orderUuid } = props;

  const [getOrder, { data: order, isLoading }] = useLazyGetOrderQuery();

  useEffect(() => {
    if (orderUuid) getOrder(orderUuid);
  }, [orderUuid]);
  return (
    <Modal
      showCloseIcon={false}
      blockScroll={false}
      classNames={{
        modalContainer: "__remove_modal_bg",
      }}
      open={isOpen}
      onClose={close}
    >
      <div className="w-[800px] rounded-t-lg">
        <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
          <span className="text-white text-sm font-samibold">Items List</span>
          <span className="text-white cursor-pointer" onClick={close}>
            <LiaTimesSolid />
          </span>
        </div>
        <div className="p-5 bg-white dark:bg-gray-900">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-9">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Product name
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                <th scope="col" className="px-6 py-3">
                  Selling Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
              </tr>
              </thead>
              <tbody>
                {order?.data.items.map(item=> (
                  <tr
                  key={item.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td
                    onClick={(e) => e.stopPropagation()}
                    className="px-6 py-4"
                  >
                    <ImageView url={utills._default_img} className="w-8 h-8" />
                  </td>
                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4">{item.quantity}</td>
                  <td className="px-6 py-4">
                    {utills._currency_format(Number(item.price ?? 0), "NGN")}
                  </td>
                  <td className="px-6 py-4">
                    {moment(item.created_at).format("LLL")}
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewItems;
