import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Button,
  Label,
  Pagination,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
import { IoIosAdd } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import { MdSearch } from "react-icons/md";
import {
  useAddUserToCompanyMutation,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompaniesQuery,
  useUpdateCompanyMutation,
} from "../../redux/queries/company";
import Modal from "react-responsive-modal";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ImageView from "../../components/ImageView";
import { CiCircleMore } from "react-icons/ci";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { IoTrashOutline } from "react-icons/io5";
import moment from "moment";
import emptyIcon from "../../assets/empty.png";
import toast from "react-hot-toast";
import utills from "../../lib/functions";
import {
  useGetUsersQuery,
  useLazyGetUserQuery,
} from "../../redux/queries/users";
import CustomDropdown from "../../components/CustomDropdown";
import AddressSearch from "../../components/AddressSearch";

const Branches = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    name: "",
    type: "branch",
    parent_uuid: user?.company.uuid as string,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [assignUser, setAssignUser] = useState<boolean>(false);

  const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: branches, isLoading } = useGetCompaniesQuery(filters);
  const [deleteCompany] = useDeleteCompanyMutation();

  const onPageChange = (page: number) => {
    setCurrentPage(page);
    setFilters((prev) => ({ ...prev, limit: `${(page - 1) * 10},10` }));
  };

  const handleDelete = async (id: string) => {
    const action = window.confirm("Are you sure you want to delete");
    if (action) {
      utills._asynchronous_toast(
        deleteCompany,
        "Branch Deleted",
        "Unable to delete branch",
        id
      );
    }
  };

  const editBranch = (branch: Branch, assign?: string) => {
    setSelectedBranch(branch);
    if (assign) setAssignUser(true);
    else setEditModal(true);
  };

  const close = () => {
    setEditModal(false);
    setAssignUser(false);
    setIsOpen(false);
    setSelectedBranch(null);
  };
  return (
    <div>
      <Breadcrumb
        title="Branch List"
        rightButton={
          <Button size="xs" onClick={() => setIsOpen(true)}>
            <IoIosAdd className="mr-2 h-5 w-5" /> Add Branch
          </Button>
        }
      />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="grid grid-cols-4 gap-3">
          <div>
            <TextInput
              type="text"
              icon={MdSearch}
              placeholder="search..."
              onChange={(e) => {
                setFilters((prev) => ({
                  ...prev,
                  name: e.target.value,
                  limit: "0,10",
                }));
                // setCurrentPage(1);
              }}
            />
          </div>
          <div>
            <Select
              id="countries"
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, category: e.target.value }))
              }
            >
              <option value="">All categories</option>
            </Select>
          </div>
        </div>
      </div>

      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Branch name
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Created
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {user && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="px-6">
                    <ImageView
                      url={user?.company.avatar_link}
                      className="w-8 h-8"
                    />
                  </td>
                  <td className="px-6 py-4">
                    {user?.company.name} + {` (main branch)`}
                  </td>
                  <td className="px-6 py-4">{user?.company.phone}</td>
                  <td className="px-6 py-4">{user?.company.email}</td>
                  <td className="px-6 py-4">
                    {moment(user?.company.crdate).format("LLL")}
                  </td>
                  <td
                    className="px-6 py-4"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <CustomDropdown
                      trigger={
                        <div className="">
                          <CiCircleMore className="text-2xl" />
                        </div>
                      }
                      menu={[
                        {
                          icon: FaPlus,
                          title: "Add User",
                          action: () =>
                            editBranch(user.company as any, "assign"),
                        },
                        {
                          icon: FaRegEdit,
                          title: "Edit",
                          action: () => editBranch(user.company as any),
                        },
                        {
                          icon: IoTrashOutline,
                          title: "Delete",
                          action: () => handleDelete(user.company.uuid),
                        },
                      ]}
                    />
                  </td>
                </tr>
              )}

              {branches?.data.map((branch) => (
                <tr
                  key={branch.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6">
                    <ImageView url={branch.avatar_link} className="w-8 h-8" />
                  </td>
                  <td className="px-6 py-4">{branch.name}</td>
                  <td className="px-6 py-4">{branch.phone}</td>
                  <td className="px-6 py-4">{branch.email}</td>
                  <td className="px-6 py-4">
                    {moment(branch.crdate).format("LLL")}
                  </td>
                  <td
                    className="px-6 py-4"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <CustomDropdown
                      trigger={
                        <div className="">
                          <CiCircleMore className="text-2xl" />
                        </div>
                      }
                      menu={[
                        {
                          icon: FaPlus,
                          title: "Add User",
                          action: () => editBranch(branch, "assign"),
                        },
                        {
                          icon: FaRegEdit,
                          title: "Edit",
                          action: () => editBranch(branch),
                        },
                        {
                          icon: IoTrashOutline,
                          title: "Delete",
                          action: () => handleDelete(branch.uuid),
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && (
            <div className="flex items-center justify-center my-5">
              <Spinner aria-label="Default status example" />
            </div>
          )}
          {branches && branches?.count > 10 ? (
            <div className="flex mt-3 mb-5 mr-2 px-2 justify-end">
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(branches?.count ?? 0 / 10)}
                onPageChange={onPageChange}
              />
            </div>
          ) : (
            <div className="h-20" />
          )}
          {branches?.status === 404 && (
            <div className="flex items-center justify-center my-5">
              <div className="flex flex-col items-center">
                <img src={emptyIcon} alt="empty record" className="w-20" />
                <span className="dark:text-gray-400 mt-2 text-xs">
                  No record found
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        classNames={{
          modalContainer: "__remove_modal_bg",
          closeButton: "dark:bg-gray-400",
        }}
        center
        open={isOpen || editModal}
        onClose={close}
      >
        <CreateBranch close={close} selectedBranch={selectedBranch} />
      </Modal>

      <Modal
        classNames={{
          modalContainer: "__remove_modal_bg",
          closeButton: "dark:bg-gray-400",
        }}
        center
        open={assignUser}
        onClose={close}
      >
        <AssignUser close={close} selectedBranch={selectedBranch} />
      </Modal>
    </div>
  );
};

export default Branches;

const AssignUser = (props: BranchModal) => {
  const { close, selectedBranch } = props;

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.appUserConfig);
  const { data: users } = useGetUsersQuery({
    limit: "0,60",
    company: user?.company.uuid,
  });
  const [getUser] = useLazyGetUserQuery();
  const [addUserToCompany, { isLoading }] = useAddUserToCompanyMutation();
  const [userId, setUserId] = useState("");

  const saveUserToCompany = async () => {
    if (!userId) {
      toast.error("Please select a user");
      return;
    }
    const res = await addUserToCompany({
      user: userId,
      status: 1,
      company: selectedBranch?.uuid,
    });
    if ("data" in res) {
      if (res.data.status !== 200) {
        toast.error(res.data.message ?? res.data.error);
      } else {
        toast.success("User Addedd To Company");
        utills._update_user_branch(userId, getUser, dispatch);
        close();
      }
    } else toast.error("Server Error...");
  };
  return (
    <div className="px-4 py-8 bg-white dark:bg-gray-800 rounded-sm min-w-80">
      <div className="mb-5">
        <div className="mb-2 block">
          <Label htmlFor="stu" value="Select User" />
        </div>

        <Select
          disabled={isLoading}
          style={{ borderRadius: 3 }}
          sizing="sm"
          id="stu"
          onChange={(e) => setUserId(e.target.value)}
        >
          <option value="">Select a user</option>
          {users?.data.map((user) => (
            <option
              key={user.uuid}
              value={user.uuid}
            >{`${user.name} (${user.email})`}</option>
          ))}
        </Select>
      </div>
      <Button
        disabled={isLoading}
        isProcessing={isLoading}
        onClick={saveUserToCompany}
        size="xs"
        className="rounded-sm w-full"
      >
        Assign
      </Button>
    </div>
  );
};

interface Address {
  lat: string;
  lng: string;
  name: boolean;
}

interface IFormInput {
  name: string;
  phone: string;
  email: string;
  status?: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Branch name is a required field"),
  phone: yup.string().required("Phone is a required field"),
  status: yup.string(),
  email: yup.string().required("Email is a required field"),
});

interface BranchModal {
  close: () => void;
  selectedBranch: Branch | null;
}

interface Payload extends IFormInput {
  uuid?: string;
  parent_uuid: string;
  type: string;
  address: {
    name: string;
    lat: number;
    lng: number;
  };
}
const CreateBranch = (props: BranchModal) => {
  const { close, selectedBranch } = props;
  const [createCompany, { isLoading: cLoading }] = useCreateCompanyMutation();
  const [updateCompany, { isLoading: loading }] = useUpdateCompanyMutation();
  const { user } = useAppSelector((state) => state.appUserConfig);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });
  const [address, setAddress] = useState({
    name: "",
    lat: 0,
    lng: 0,
  });

  const isLoading = selectedBranch ? loading : cLoading;
  const onSubmit = async (data: IFormInput) => {
    if (!address.name) {
      toast.error("Please enter address!!!");
      return;
    }
    const payload: Payload = {
      ...data,
      parent_uuid: user?.company.uuid as string,
      type: "branch",
      address,
    };
    if (selectedBranch) payload.uuid = selectedBranch.uuid;
    const res = selectedBranch
      ? await updateCompany(payload)
      : await createCompany(payload);
    if ("data" in res) {
      toast.success(`Branch ${selectedBranch ? "Updated" : "Created"}`);
      close();
    } else
      toast.error(`Unable to ${selectedBranch ? "update" : "create"} branch`);
  };

  useEffect(() => {
    if (selectedBranch) {
      setValue("name", selectedBranch.name);
      setValue("phone", selectedBranch.phone);
      setValue("email", selectedBranch.email);
      setValue("status", selectedBranch.status.toString());
    }
  }, []);

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white dark:bg-gray-800 rounded-lg">
          <div className="dark:bg-gray-800 rounded-t-lg p-2">
            <span className="dark:text-white">Enter Branch Information</span>
          </div>

          <div className="grid grid-cols-2 gap-4 p-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="Branch Name" />
              </div>
              <TextInput
                disabled={isLoading}
                {...register("name")}
                id="name"
                type="text"
                className="min-w-[200px]"
                sizing="sm"
                placeholder="Branch Name"
                style={{ borderRadius: 3 }}
                color={errors?.name ? "failure" : "gray"}
                helperText={
                  errors.name && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.name?.message}!
                    </span>
                  )
                }
              />
            </div>

            <div className="">
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email" />
              </div>
              <TextInput
                disabled={isLoading}
                {...register("email")}
                id="email"
                type="email"
                sizing="sm"
                className="min-w-[200px]"
                style={{ borderRadius: 3 }}
                placeholder="Email"
                color={errors?.email ? "failure" : "gray"}
                helperText={
                  errors.name && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.email?.message}!
                    </span>
                  )
                }
              />
            </div>

            <div className="">
              <div className="mb-2 block">
                <Label htmlFor="phone" value="Phone" />
              </div>
              <TextInput
                disabled={isLoading}
                {...register("phone")}
                id="phone"
                type="text"
                sizing="sm"
                className="min-w-[200px]"
                style={{ borderRadius: 3 }}
                placeholder="Phone"
                color={errors?.phone ? "failure" : "gray"}
                helperText={
                  errors.name && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.phone?.message}!
                    </span>
                  )
                }
              />
            </div>

            <div>
              <div className="mb-2 block">
                <Label htmlFor="stu" value="Status" />
              </div>

              <Select
                disabled={isLoading}
                style={{ borderRadius: 3 }}
                sizing="sm"
                id="stu"
                {...register("status")}
              >
                <option value={1}>Active</option>
                <option value={0}>Inactive</option>
              </Select>
            </div>
          </div>

          <div className="px-4">
            <div className="mb-2 block">
              <Label
                htmlFor="address"
                value={`${selectedBranch ? "Current " : ""}Address ${
                  selectedBranch ? `: ${selectedBranch.address.name ?? ""}` : ""
                }`}
              />
            </div>
            <AddressSearch
              onSelect={(val) => setAddress(val)}
              sizing="sm"
              style={{ borderRadius: 3 }}
            />
          </div>

          <div className="p-4">
            <Button
              disabled={isLoading}
              isProcessing={isLoading}
              type="submit"
              className="rounded-sm w-full"
              size="xs"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
