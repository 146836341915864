import { useEffect, useState } from "react";
import { useGetKpisQuery } from "../../redux/queries/tasks";
import utills from "../../lib/functions";
import { useAppDispatch } from "../../lib/hook";
import { applyTaskParams } from "../../redux/slices/taskParams";
import { monthsList } from "../../route/data";
import CustomPagination from "../../components/CustomPagination";

interface BundleTableTaskProps {
  userId: string;
  staffId: string
}

const BundleTableTask = ({ userId, staffId }: BundleTableTaskProps) => {
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState({
    limit: "0,5",
    company_uuid: userId,
    staff: staffId
  });

  const { data: bundleTask, isLoading } = useGetKpisQuery(filters);
  const [childTasks, setChildTasks] = useState<TaskList | undefined>(
    bundleTask?.data[0]
  );

  const onPassTasks = (tasks: TaskList) => {
    setChildTasks(tasks);
  };

  useEffect(() => {
    setChildTasks(bundleTask?.data[0]);
  }, [isLoading])

  useEffect(() => {
    dispatch(
      applyTaskParams({
        loading: isLoading,
        tasks: childTasks?.tasks,
      })
    );
  }, [isLoading, childTasks]);

  return (
    <CustomPagination
      isLoading={isLoading}
      setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
      total={bundleTask?.count ?? 0}
      data={bundleTask?.data ?? []}
    >
      <div className="flex w-full flex-col gap-3">
        {bundleTask?.data?.map((item) => {
          return (
            <EachCard
              key={item.id}
              childTasks={childTasks}
              item={item}
              onPassTasks={onPassTasks}
            />
          );
        })}
      </div>
    </CustomPagination>
  );
};

export default BundleTableTask;

interface EachCardProps {
  item: TaskList;
  childTasks: TaskList | undefined;
  onPassTasks: (tasks: TaskList) => void;
}

const EachCard = ({ item, onPassTasks, childTasks }: EachCardProps) => {

  const getMonth = (num: number) => {
    return monthsList.filter((type) => type.value === num);
  };

  return (
    <div
      onClick={() => onPassTasks(item)}
      className={`bg-white cursor-pointer relative dark:bg-gray-800 shadow-sm outline-green-400 rounded-sm p-2 text-xs mb-3 ${
        item.id === childTasks?.id && "outline outline-2"
      }`}
    >
      <div className="flex justify-between items-center mb-2">
        <span className="text-gray-700 font-semibold dark:text-white">
          #{item.id}
        </span>
      </div>

      <ul className="divide-y divide-gray-200 dark:divide-gray-500">
        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Salary
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              {utills._currency_format(item.salary, "NGN")}
            </div>
          </div>
        </li>

        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Month
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              {getMonth(item.month)[0].month}
            </div>
          </div>
        </li>
        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Year
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              {item.year}
            </div>
          </div>
        </li>
        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Staff
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              {item.staff?.name}
            </div>
          </div>
        </li>
        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Type
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              {item?.staff?.type}
            </div>
          </div>
        </li>
        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Tasks
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              {item.tasks.length}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
