import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export const getBaseUrl = (offline: boolean) => {
  return window.location.hostname === "pos.gohealthy.ng"
    ? process.env.REACT_APP_BASE_URL
    : window.location.hostname === "pos1.gohealthy.ng"
    ? offline
      ? process.env.REACT_APP_OFF_BASE_URL
      : process.env.REACT_APP_DEV_BASE_URL
    : process.env.REACT_APP_DEV_BASE_URL;
};

const baseQueryWithReAuth: BaseQueryFn = async (args, api, extraOptions) => {
  const state = api.getState() as RootState;
  const base_Url = getBaseUrl(state?.appUserConfig.offline);

  return await fetchBaseQuery({
    baseUrl: base_Url,
    prepareHeaders: (headers, { getState }) => {
      const authUserRecord = getState() as RootState;

      // @ts-ignore
      if (authUserRecord?.appUserConfig.token) {
        // @ts-ignore
        headers.set(
          "goh-access-token",
          `${process.env.REACT_APP_API_KEY}.${authUserRecord.appUserConfig.token}`
        );
      } else
        headers.set("goh-access-token", `${process.env.REACT_APP_API_KEY}.`);
      headers.set("Content-Type", "application/json");
      headers.set("Accept", "application/json");
      headers.set("platform", "web-pos/1.0.0");
      return headers;
    },
  })(args, api, extraOptions);
};

export const api = createApi({
  baseQuery: baseQueryWithReAuth,

  reducerPath: "apiPath",
  tagTypes: [
    "Products",
    "Orders",
    "Users",
    "Settings",
    "Companies",
    "Stock",
    "Shelfs",
    "Stocks",
    "Permissions",
    "SellingGroups",
    "Payments",
    "Statistics",
    "Dashboard",
    "Tasks",
    "Carts",
    "Expenses",
    "Support",
    "Location",
    "Loans",
    "offline",
  ],
  endpoints: (builder) => ({}),
});
