import { api, getBaseUrl } from "../baseConfig";
import axios from "axios";

export const uploadSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<UploadResponse, any>({
      async queryFn(file, { dispatch, getState }) {
        
        try {
          const authUserRecord = getState() as RootState;
          const base_url = getBaseUrl(authUserRecord?.appUserConfig?.offline);
          const headers = {
            //@ts-ignore
            "goh-access-token": `${process.env.REACT_APP_API_KEY}.${authUserRecord?.appUserConfig?.token}`,
            platform: "web-pos/1.0.0",
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          };
          const formData = new FormData();
          formData.append("folder", "attachments");
          formData.append("name", "item");
          formData.append("file", file);

          const response = await axios({
            url: `${base_url}/upload`,
            method: "POST",
            headers,
            data: formData,
          });
          return { data: response.data as UploadResponse };
        } catch (error) {
          return { error: "failed" };
        }
      },
    }),
  }),
});

export const { useUploadFileMutation } = uploadSlice;
