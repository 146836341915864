import React, { useEffect, useState } from 'react'
import { LiaTimesSolid } from 'react-icons/lia'
import ProductSearch from './ProductSearch'
import { useAppSelector } from '../lib/hook';
import utills from '../lib/functions';
import BranchSwitcher from './BranchSwitcher';

const PriceChecker = ({ close }: { close: () => void }) => {
    const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
    const [item, setItem] = useState<Product | undefined>()
    const [company, setCompany] = useState(defaultBranchUUid)

useEffect(()=> {
    return () => {
        setItem(undefined)
    }
},[])

  return (
    <div className=" rounded-t-lg">
       <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Price Checker</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <div className='dark:bg-gray-800 py-5 bg-white px-5'>
        <div className='flex items-center gap-3'>
          <div className='min-w-[320px]'>
          <BranchSwitcher defaultCompany={company} styling={{ height: 43, borderRadius: 3}} onChange={(value)=> setCompany(value)}  />
          </div>
          <div className='flex-1'>
          <ProductSearch sizing="md" companyId={company} onSelect={(product)=> setItem(product)} />
        </div>
        </div>

            <div className='p-3 mt-10'>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                   <thead className="text-xs text-gray-700 uppercase bg-[#167490]/20 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th  scope="col" className="px-6 py-3">Product Name</th>
                        <th  scope="col" className="px-6 py-3">Barcode</th>
                        <th  scope="col" className="px-6 py-3">Selling Price</th>
                    </tr>
                   </thead>
                   <tbody>
                   {item && <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">{item?.name}</td>
                        <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">{item?.barcode}</td>
                        <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">{utills._currency_format(item?.selling_price ?? 0, 'NGN')}</td>
                    </tr>}
                   </tbody>
                </table>

                <div className='mt-5'>
                    <div className='font-semibold text-[18px] text-center my-2 dark:text-white'>Variants</div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                   <thead className="text-xs text-gray-700 uppercase bg-[#167490]/20 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th  scope="col" className="px-6 py-3">Variant Name</th>
                        <th  scope="col" className="px-6 py-3">Quantity</th>
                        <th  scope="col" className="px-6 py-3">Price</th>
                    </tr>
                   </thead>
                   <tbody>
                    {item?.variants.map(prod=> (
                        <tr key={prod.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">{prod.name}</td>
                        <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">{prod.quantity}</td>
                        <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">{utills._currency_format(prod.price, 'NGN')}</td>
                    </tr>
                    ))}
                   </tbody>
                </table>
                </div>
            </div>
      </div>
    </div>
  )
}

export default PriceChecker
