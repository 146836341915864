import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { settingsNav } from "../../route/data";
import BranchTable from "./BranchTable";
import Profile from "./Profile";
import CompanyProfile from "./CompanyProfile";
import Store from "./Store";
import PinSetting from "./PinSetting";
import Performance from "./Performance";
import Permission from "./Permission";
import Loan from "./Loan";
import SyncData from "./SyncData";
import { useAppSelector } from "../../lib/hook";
import ReOrder from "./ReOrder";

const Settings = () => {
  const [tag, setTag] = useState(1);
  const { offline } = useAppSelector((state) => state.appUserConfig);
  const hide = window.location.hostname === "pos.gohealthy.ng";

  return (
    <div>
      <Breadcrumb title="Settings" />
      <div className="w-full flex gap-5">
        <div className="w-[20%] min-h-[600px] bg-white dark:bg-gray-700 text-gray-600 dark:text-white py-14">
          <div className="flex flex-col gap-5 ">
            {settingsNav.map((item) =>
              item.tag === 9 && !offline && hide ? null : (
                <div
                  onClick={() => setTag(item.tag)}
                  className={`py-1 pl-1 xl:pl-3 2xl:pl-5 border-r-4 flex items-center gap-3 font-medium cursor-pointer text-sm xl:text-base ${
                    tag === item.tag
                      ? "border-[#1f91b2] text-[#1f91b2]"
                      : "border-transparent"
                  }`}
                >
                  <item.icon />
                  <span>{item.name}</span>
                </div>
              )
            )}
          </div>
        </div>

        <div className="w-[80%] min-h-[600px] bg-white dark:bg-gray-700 dark:text-white">
          {tag === 1 ? (
            <Profile />
          ) : tag === 2 ? (
            <CompanyProfile />
          ) : tag === 3 ? (
            <BranchTable />
          ) : tag === 4 ? (
            <Store />
          ) : tag === 5 ? (
            <PinSetting />
          ) : tag === 6 ? (
            <Performance />
          ) : tag === 7 ? (
            <Permission />
          ) : tag === 8 ? (
            <Loan />
          ) : tag === 9 ? (
            <SyncData />
          ) : tag === 10 ? (
            <ReOrder />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Settings;
