import {
  Button,
  Datepicker,
  Label,
  Select,
  Textarea,
  TextInput,
} from "flowbite-react";
import Breadcrumb from "../../components/Breadcrumb";
import { IoIosSend } from "react-icons/io";
import { useAppSelector } from "../../lib/hook";
import {
  useGetBanksQuery,
  useGetWalletsQuery,
  useLazyGetBankVerifiedQuery,
  useSendMoneyMutation,
} from "../../redux/queries/Payments";
import { useEffect, useState } from "react";
import moment from "moment";
import { FaWallet } from "react-icons/fa";
import utills from "../../lib/functions";
import { GrTransaction } from "react-icons/gr";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { useGetCompaniesQuery } from "../../redux/queries/company";
import toast from "react-hot-toast";
import { _figure_format } from "../../lib/utills";
import CustomPagination from "../../components/CustomPagination";
import BranchSwitcher from "../../components/BranchSwitcher";

const Wallet = () => {
  const { user, defaultBranchUUid } = useAppSelector(
    (state) => state.appUserConfig
  );
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: user?.company?.uuid,
  });
  const [filterFields, setFilterFields] = useState({
    start_date: "",
    end_date: "",
    company: defaultBranchUUid,
  });
  const { data: walletHistory, isLoading } = useGetWalletsQuery(filters);
  //console.log('walet', filters.limit, walletHistory)

  const applyFilter = () => {
    setFilters((prev) => ({ ...prev, ...filterFields, limit: "0,10" }));
  };

  useEffect(() => {
    if (walletHistory?.data.length) {
      setFilterFields((prev) => ({
        ...prev,
        start_date: moment(
          walletHistory?.data[walletHistory?.data.length - 1]?.crdate
        ).format("YYYY-MM-DD"),
        end_date: moment(walletHistory?.data[0]?.crdate).format("YYYY-MM-DD"),
      }));
    }
  }, [walletHistory?.data]);

  const reset = () => {
    setFilterFields({
      start_date: moment(
        walletHistory?.data[walletHistory?.data.length - 1]?.crdate
      ).format("YYYY-MM-DD"),
      end_date: moment(walletHistory?.data[0]?.crdate).format("YYYY-MM-DD"),
      company: defaultBranchUUid,
    });
    setFilters({
      limit: "0,10",
      company: user?.company?.uuid,
    });
  };
  return (
    <div>
      <Breadcrumb
        title="Wallet History"
        rightButton={
          <Button
            size="xs"
            className="flex items-center"
            onClick={() => setIsOpen(true)}
          >
            <IoIosSend className="mr-2 h-5 w-5" /> Send Money
          </Button>
        }
      />
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-center gap-5">
        <div className="bg-gray-100 dark:bg-gray-800 h-32 rounded-lg p-3 flex flex-col justify-between w-72">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Transactions
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <GrTransaction className="text-[#167490] text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>{walletHistory?.count ?? 0}</span>
          </div>
          <div className="text-xs text-gray-700 dark:text-white">
            <span>*updated every transactions</span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-32 rounded-lg p-3 flex flex-col justify-between w-72">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Wallet
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <FaWallet className="text-[#167490] text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>
              {utills._currency_format(
                Number(walletHistory?.total) ?? 0,
                "NGN"
              )}
            </span>
          </div>
          <div className="text-xs text-gray-700 dark:text-white">
            <span>*updated every transactions</span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="flex justify-between items-end gap-3">
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-2 border border-gray-200 dark:bg-gray-800 rounded-md px-3 text-gray-700 dark:text-gray-400">
            <span className="text-sm font-medium">
              Select Start Date and End Date :
            </span>
            <Datepicker
              value={filterFields.start_date}
              onSelectedDateChanged={(date) =>
                setFilterFields((prev) => ({
                  ...prev,
                  start_date: moment(date).format("YYYY-MM-DD"),
                }))
              }
              style={{
                borderWidth: "0",
                backgroundColor: "transparent",
                width: "130px",
                outline: "none",
              }}
              name="date"
              datepicker-format={"YYYY-MM-DD"}
            />

            <Datepicker
              value={filterFields.end_date}
              onSelectedDateChanged={(date) =>
                setFilterFields((prev) => ({
                  ...prev,
                  end_date: moment(date).format("YYYY-MM-DD"),
                }))
              }
              style={{
                borderWidth: "0",
                backgroundColor: "transparent",
                width: "130px",
              }}
              name="date"
              datepicker-format={"YYYY-MM-DD"}
            />
          </div>
          <BranchSwitcher
            sizing="md"
            defaultCompany={filterFields.company}
            onChange={(value) =>
              setFilterFields((prev) => ({ ...prev, company: value }))
            }
          />
          </div>

          <div className="flex gap-2">
            <Button size="sm" onClick={applyFilter}>
              Apply
            </Button>
            <Button outline size="sm" onClick={reset}>
              Reset
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={walletHistory?.data}
            total={walletHistory?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Debit(₦)
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Credit(₦)
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Balance
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Transaction Type
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Processed by
                  </th>
                </tr>
              </thead>
              <tbody>
                {walletHistory?.data?.map((item) => (
                  <tr
                    key={item.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">
                      {moment(item.crdate).format("LLL")}
                    </td>
                    <td className="px-6 py-4">
                      {Number(item.amount) < 0
                        ? utills._figure_format(
                            Number(item.amount.replace("-", ""))
                          )
                        : "-"}
                    </td>
                    <td className="px-6 py-4">
                      {Number(item.amount) >= 0
                        ? utills._figure_format(Number(item.amount))
                        : "-"}
                    </td>
                    <td className="px-6 py-4">
                      {_figure_format(Number(item.balance))}
                    </td>
                    <td className="px-6 py-4">{item.type}</td>
                    <td className="px-6 py-4">{item.staff?.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <SendMoney
          close={() => setIsOpen(false)}
          companyUuid={user?.company?.uuid}
        />
      </Modal>
    </div>
  );
};

export default Wallet;

interface UserCard {
  close: () => void;
  companyUuid: string | undefined;
}

const SendMoney = ({ close, companyUuid }: UserCard) => {
  const [method, setMethod] = useState("1");
  const [walletMethod, setWalletMethod] = useState({
    type: "transfer",
    payment_mode: 3,
    receiver: "",
    amount: "",
    description: "",
    company: companyUuid,
  });

  const [bankMethod, setBankMethod] = useState({
    type: "bank_transfer",
    payment_mode: 2,
    amount: "",
    description: "",
    company: companyUuid,
    number: "",
    bank_code: "",
    acc_details: {
      account_name: "",
      account_number: "",
      bank_id: null,
    },
  });

  const [filters, setFilters] = useState({
    type: "",
    status: 1,
  });
  const { data: companies } = useGetCompaniesQuery(filters);
  const { data: banks } = useGetBanksQuery({});
  const [SendMoney, { isLoading: isSendMoney }] = useSendMoneyMutation();
  const [getBankVerified, { isLoading: isVerifying }] =
    useLazyGetBankVerifiedQuery();

  const onWalletTranfer = async () => {
    const payload = walletMethod;
    const res = (await SendMoney(payload)) as any;

    if (res.data.status === 200) {
      toast.success(res.data.message);
      close();
    } else if (res.data.status === 100) {
      toast.error(res.data.message);
    } else {
      toast.error(res.data.data[0]);
    }
  };

  const onBankVerify = async () => {
    const payload = {
      number: bankMethod.number,
      bank_code: bankMethod.bank_code,
    };

    const res = await getBankVerified(payload);

    if (res.data.status === 200) {
      setBankMethod((prev) => ({ ...prev, acc_details: res.data.data }));
    } else {
      toast.error(res.data.message);
    }
  };

  const onBankTransfer = async () => {
    const payload = {
      company: bankMethod.company,
      description: bankMethod.description,
      type: bankMethod.type,
      payment_mode: bankMethod.payment_mode,
      amount: bankMethod.amount,
      bank: {
        account_no: bankMethod.acc_details.account_number,
        account_name: bankMethod.acc_details.account_name,
        bank_id: bankMethod.acc_details.bank_id,
      },
    };

    const res = (await SendMoney(payload)) as any;

    if (res.data.status === 200) {
      toast.success(res.data.message);
      close();
    } else if (res.data.status === 100) {
      toast.error(res.data.message);
    } else {
      toast.error(res.data.data[0]);
    }
  };

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-semibold">Send Money</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <div className="bg-white p-3 pb-5">
        {method === "1" && (
          <div className="my-6">
            <Label
              htmlFor="mode"
              value="Payment Method"
              className="text-sm dark:text-gray-800"
            />
            <Select
              name="payment-method"
              required
              sizing="md"
              style={{ borderRadius: 0 }}
              className="mt-1"
              onChange={(e) => {
                setMethod(e.target.value);
              }}
            >
              <option value="1" hidden>
                Select Payment Method
              </option>
              <option value="3">Wallet Transfer</option>
              <option value="2">Bank Transfer</option>
            </Select>
          </div>
        )}
        {method === "2" && (
          <div>
            <div className="">
              <Label
                htmlFor="bank"
                value="Bank"
                className="text-sm dark:text-gray-800"
              />
              <Select
                disabled={
                  isSendMoney ||
                  isVerifying ||
                  bankMethod.acc_details.account_name.length > 0
                }
                name="bank"
                required
                sizing="md"
                className="mt-1"
                style={{ borderRadius: 0 }}
                onChange={(e) =>
                  setBankMethod((prev) => ({
                    ...prev,
                    bank_code: e.target.value,
                  }))
                }
              >
                <option value="" hidden>
                  Select Bank
                </option>

                {banks?.data?.map((item) => (
                  <option key={item.code} value={item.code}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>

            {bankMethod.acc_details.account_name.length > 0 && (
              <div className="mt-3">
                <Label
                  htmlFor="name"
                  value="Name"
                  className="text-sm dark:text-gray-800"
                />

                <TextInput
                  disabled={bankMethod.acc_details.account_name.length > 0}
                  sizing="md"
                  type="text"
                  placeholder="Enter Account Number"
                  value={bankMethod?.acc_details?.account_name}
                  style={{ borderRadius: 0 }}
                  className="mt-1"
                  readOnly
                />
              </div>
            )}

            <div className="mt-3">
              <Label
                htmlFor="acc-num"
                value="Account Number"
                className="text-sm dark:text-gray-800"
              />
              <TextInput
                disabled={
                  isSendMoney ||
                  isVerifying ||
                  bankMethod.acc_details.account_name.length > 0
                }
                sizing="md"
                type="number"
                placeholder="Enter Account Number"
                value={bankMethod.number}
                style={{ borderRadius: 0 }}
                className="mt-1"
                onChange={(e) =>
                  setBankMethod((prev) => ({
                    ...prev,
                    number: e.target.value,
                  }))
                }
              />
            </div>

            {bankMethod.acc_details.account_name.length > 0 && (
              <div className="mt-3">
                <Label
                  htmlFor="bank-amount"
                  value="Amount"
                  className="text-sm dark:text-gray-800"
                />
                <TextInput
                  disabled={isSendMoney || isVerifying}
                  sizing="md"
                  type="text"
                  placeholder="Enter Amount"
                  value={bankMethod.amount}
                  style={{ borderRadius: 0 }}
                  className="mt-1"
                  onChange={(e) =>
                    setBankMethod((prev) => ({
                      ...prev,
                      amount: e.target.value,
                    }))
                  }
                />
              </div>
            )}

            {bankMethod.acc_details.account_name.length > 0 && (
              <div className="mt-3">
                <Label
                  htmlFor="bank-des"
                  value="Description"
                  className="text-sm dark:text-gray-800"
                />
                <Textarea
                  disabled={isSendMoney || isVerifying}
                  id="bank-description"
                  placeholder="Put down a description..."
                  rows={3}
                  value={bankMethod.description}
                  style={{ borderRadius: 0 }}
                  className="mt-1"
                  onChange={(e) =>
                    setBankMethod((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                />
              </div>
            )}
          </div>
        )}
        {method === "3" && (
          <div>
            <div className="grid grid-cols-2 gap-3 mt-2">
              <div className="">
                <Label
                  htmlFor="wallet-amount"
                  value="Amount"
                  className="text-sm dark:text-gray-800"
                />
                <TextInput
                  disabled={isSendMoney || isVerifying}
                  sizing="md"
                  type="text"
                  placeholder="Enter Amount"
                  value={walletMethod.amount}
                  style={{ borderRadius: 0 }}
                  className="mt-1"
                  onChange={(e) =>
                    setWalletMethod((prev) => ({
                      ...prev,
                      amount: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="">
                <Label
                  htmlFor="type"
                  value="Type"
                  className="text-sm dark:text-gray-800"
                />
                <Select
                  disabled={isSendMoney || isVerifying}
                  name="type"
                  required
                  sizing="md"
                  className="mt-1"
                  style={{ borderRadius: 0 }}
                  onChange={(e) =>
                    setFilters((prev) => ({ ...prev, type: e.target.value }))
                  }
                >
                  <option value="" hidden>
                    Select Type
                  </option>
                  <option value="pharmacy">Pharmacy</option>
                  <option value="manufacturer">Manufacturer</option>
                  <option value="hospital">Hospital</option>
                </Select>
              </div>
            </div>
            <div className="mt-3">
              <Label
                htmlFor="wallet-des"
                value="Description"
                className="text-sm dark:text-gray-800"
              />
              <Textarea
                disabled={isSendMoney || isVerifying}
                id="description"
                placeholder="Put down a description..."
                rows={3}
                value={walletMethod.description}
                style={{ borderRadius: 0 }}
                className="mt-1"
                onChange={(e) =>
                  setWalletMethod((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
            </div>

            <div className="mt-3">
              <Label
                htmlFor="receiver"
                value="Receiver"
                className="text-sm dark:text-gray-800"
              />
              <Select
                disabled={isSendMoney || isVerifying}
                name="receiver"
                required
                sizing="md"
                className="mt-1"
                style={{ borderRadius: 0 }}
                onChange={(e) =>
                  setWalletMethod((prev) => ({
                    ...prev,
                    receiver: e.target.value,
                  }))
                }
              >
                <option value="" hidden>
                  Select Company Name
                </option>

                {companies?.data?.map((item) => (
                  <option key={item.id} value={item.uuid}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        )}

        {(method === "2" || method === "3") && (
          <div className="mt-7 flex items-center justify-end">
            <Button
              disabled={isSendMoney || isVerifying}
              isProcessing={isSendMoney || isVerifying}
              size="sm"
              className="rounded-sm"
              onClick={() => {
                if (method === "3") {
                  onWalletTranfer();
                } else if (
                  method === "2" &&
                  bankMethod.acc_details.account_name.length === 0
                ) {
                  onBankVerify();
                } else if (
                  method === "2" &&
                  bankMethod.acc_details.account_name.length > 0
                ) {
                  onBankTransfer();
                }
              }}
            >
              {method === "3" || bankMethod.acc_details.account_name.length > 0
                ? "Send"
                : "Verify"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
