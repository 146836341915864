import moment from "moment";
import { GoBell } from "react-icons/go";
import { TbChecklist } from "react-icons/tb";
import { IoMdAlarm } from "react-icons/io";
import { Button, Spinner } from "flowbite-react";
import ImageView from "../../components/ImageView";
import { useAppSelector } from "../../lib/hook";
import { useGetDashboardQuery } from "../../redux/queries/dashboard";
import { MdOutlineArrowRightAlt } from "react-icons/md";

const Dashboard = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const { data: dashboardItems, isLoading } = useGetDashboardQuery({
    company: user?.company?.uuid,
  });

  return (
    <div>
      <div className="flex justify-between items-center my-2">
        <span className="font-semibold dark:text-gray-200">
          Nice! We have a lot of orders
        </span>
        <span className="text-xs dark:text-gray-200">
          {moment().format("dddd, MMMM Do YYYY")}
        </span>
      </div>
      <div className="flex flex-col lg:flex-row gap-3">
        <div className="lg:w-[65%]">
          <div className="flex flex-col md:flex-row gap-3 w-full">
            <div className="bg-[#167490] flex-1 h-40 rounded-lg p-3 flex flex-col justify-between">
              <div className="flex justify-between items-center">
                <span className="text-sm font-semibold text-white">
                  New Orders
                </span>
                <div className="bg-white rounded-lg w-10 h-10 flex items-center justify-center">
                  <GoBell className="text-[#167490] text-xl" />
                </div>
              </div>
              <div className="text-white text-bold text-[30px]">
                <span>{dashboardItems?.data?.new_order ?? 0}</span>
              </div>
              <div className="text-xs text-white">
                <span>*updated every new order</span>
              </div>
            </div>

            <div className="bg-white dark:bg-gray-700 flex-1 h-40 rounded-lg p-3 flex flex-col justify-between">
              <div className="flex justify-between items-center">
                <span className="text-sm font-semibold dark:text-white">
                  Orders Assigned
                </span>
                <div className="bg-[#167490]/10 rounded-lg w-10 h-10 flex items-center justify-center">
                  <TbChecklist className="text-[#167490] text-xl" />
                </div>
              </div>
              <div className="dark:text-white text-bold text-[30px]">
                <span>{dashboardItems?.data?.order_assigned ?? 0}</span>
              </div>
              <div className="text-xs dark:text-white">
                <span>*updated every new order</span>
              </div>
            </div>

            <div className="bg-white dark:bg-gray-700 flex-1 h-40 rounded-lg p-3 flex flex-col justify-between">
              <div className="flex justify-between items-center">
                <span className="text-sm font-semibold dark:text-white">
                  Orders Delivered
                </span>
                <div className="bg-yellow-500/10 rounded-lg w-10 h-10 flex items-center justify-center">
                  <IoMdAlarm className="text-yellow-500 text-xl" />
                </div>
              </div>
              <div className="dark:text-white text-bold text-[30px]">
                <span>{dashboardItems?.data?.order_delivered ?? 0}</span>
              </div>
              <div className="text-xs dark:text-white">
                <span>*updated every new order</span>
              </div>
            </div>
          </div>

          <div className="flex gap-3 mt-3 w-full dark:text-gray-100">
            <div className="flex-[0.5] bg-white dark:bg-gray-800 rounded-lg px-3 py-2">
              <span className="text-sm font-semibold">Order Unverified</span>

              {dashboardItems?.data?.order_unverified_data.length ? (
                <div className="flex flex-col min-h-40 gap-6 mt-6">
                  {dashboardItems?.data?.order_unverified_data?.map((item) => (
                    <div
                      key={item.uuid}
                      className="flex justify-between items-start"
                    >
                      <div className="flex gap-2">
                        <ImageView
                          url={
                            item.buyer_details.avatar_link
                              ? item.buyer_details.avatar_link
                              : "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                          }
                          className="w-10 h-10 rounded-lg"
                        />
                        <div className="flex flex-col justify-between flex-1">
                          <span className="text-xs font-semibold">
                            {item.buyer_details.name}
                          </span>
                          <span className="text-[11px]">
                            {moment(item.buyer_details.created_at).format(
                              "LLL"
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="flex items-end">
                        <div className="flex items-center gap-1 text-sm">
                          <span className="text-xs">Items :</span>
                          <span className="font-semibold">{item.items}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : isLoading ? (
                <div className="flex items-center justify-center h-40">
                  <Spinner aria-label="Default status example" />
                </div>
              ) : (
                <div className="flex items-center justify-center h-40">
                  <p className="text-sm">No Best Selling Products Available</p>
                </div>
              )}
            </div>
            <div className="flex-[0.5] bg-white dark:bg-gray-700 rounded-lg p-3">
              <span className="text-sm font-semibold">Order Unpaid</span>

              {dashboardItems?.data?.order_unpaid_data.length ? (
                <div className="flex flex-col min-h-40 gap-6 mt-6">
                  {dashboardItems?.data?.order_unpaid_data?.map((item) => (
                    <div
                      key={item.uuid}
                      className="flex justify-between items-start"
                    >
                      <div className="flex gap-2">
                        <ImageView
                          url={
                            item.buyer_details.avatar_link
                              ? item.buyer_details.avatar_link
                              : "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                          }
                          className="w-10 h-10 rounded-lg"
                        />
                        <div className="flex flex-col justify-between flex-1">
                          <span className="text-xs font-semibold">
                            {item.buyer_details.name}
                          </span>
                          <span className="text-[11px]">
                            {moment(item.buyer_details.created_at).format(
                              "LLL"
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="flex items-end">
                        <Button size="xs" className="">
                          Pay now
                          <MdOutlineArrowRightAlt className="ml-2 h-3 w-3" />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : isLoading ? (
                <div className="flex items-center justify-center h-40">
                  <Spinner aria-label="Default status example" />
                </div>
              ) : (
                <div className="flex items-center justify-center h-40">
                  <p className="text-sm">No Best Selling Products Available</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="lg:w-[35%]">
          <div className="flex lg:flex-col gap-3 mb-2 w-full dark:text-gray-100">
            <div className="flex-[0.5] bg-white dark:bg-gray-800 rounded-lg px-3 py-4">
              <div className="flex items-center justify-between">
                <span className="text-sm font-semibold">
                  Best Selling Products
                </span>

                <Button size="xs" outline gradientDuoTone="purpleToBlue">
                  View all
                </Button>
              </div>

              {dashboardItems?.data?.best_selling_product.length ? (
                <div className="min-h-40 mt-5">
                  <div className="flex items-center gap-6">
                    <span className="text-sm font-semibold">Rank</span>
                    <span className="text-sm font-semibold">Products</span>
                  </div>
                  <div className="flex flex-col gap-4 mt-4">
                    {dashboardItems?.data?.best_selling_product?.map(
                      (item, i) => (
                        <div key={item.id} className="flex items-center gap-8">
                          <div className="ml-2">
                            <span className="text-md font-semibold">
                              {i >= 9 ? i + 1 : `0${i + 1}`}
                            </span>
                          </div>
                          <div className="flex gap-3">
                            <ImageView
                              url={
                                item.images.length
                                  ? item.images[0]
                                  : "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                              }
                              className="h-10 w-10"
                            />
                            <div className="flex flex-col justify-between flex-1">
                              <h6 className="font-semibold text-sm">
                                {item.name}
                              </h6>
                              <div className="flex items-center gap-1 text-sm">
                                <span className="text-xs">Sales :</span>
                                <span className="font-semibold">
                                  {item.sold}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : isLoading ? (
                <div className="flex items-center justify-center h-40">
                  <Spinner aria-label="Default status example" />
                </div>
              ) : (
                <div className="flex items-center justify-center h-40">
                  <p className="text-sm">No Best Selling Products Available</p>
                </div>
              )}
            </div>
            <div className="flex-[0.5] bg-white dark:bg-gray-700 rounded-lg p-3">
              <span className="text-sm font-semibold">Best Employees</span>

              <div className="flex gap-2 mt-3"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
