import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  offline: boolean;
  token: string;
  user: AppUser | null;
  defaultBranchUUid: string;
  isPinSet: boolean;
}
const initialState: InitialState = {
  offline: false,
  token: "",
  user: null,
  defaultBranchUUid: "",
  isPinSet: false,
};
const appUserSlice = createSlice({
  name: "APP",
  initialState,
  reducers: {
    registerUser: (
      state,
      action: PayloadAction<{ token: string; user: AppUser }>
    ) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.defaultBranchUUid = action.payload.user.company.uuid;
    },
    exitUser: (state) => {
      state.token = "";
      state.user = null;
    },
    setOffline: (state) => {
      state.offline = !state.offline;
    },
    updateUserBranch: (state, action) => {
      state.user = action.payload;
    },
    setDefaultBranch: (state, action: PayloadAction<string>) => {
      state.defaultBranchUUid = action.payload;
    },
    setPinStatus: (state, action: PayloadAction<boolean>) => {
      state.isPinSet = action.payload;
    },
    updateUserRecord: (state, action: PayloadAction<AppUser>) => {
      state.user = action.payload;
    },
  },
});

export const {
  registerUser,
  exitUser,
  setOffline,
  updateUserBranch,
  setDefaultBranch,
  setPinStatus,
  updateUserRecord,
} = appUserSlice.actions;
export default appUserSlice.reducer;
