import { Button, Label, Select, TextInput, useThemeMode } from "flowbite-react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useUpdateUserMutation } from "../../redux/queries/users";
import toast from "react-hot-toast";
import { updateUserRecord } from "../../redux/slices/appUser";
import PhoneInput from "react-phone-input-2";
import { lowerCase } from "lodash";
import BranchSwitcher from "../../components/BranchSwitcher";

interface IFormInput {
  name: string;
  email?: string;
  phone: string;
  status: string;
  nin?: string;
  bvn?: string;
  religion?: string;
  whatsapp: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup.string(),
  phone: yup
    .string()
    .min(4, "Phone number is a required field")
    .required("Phone number is a required field"),
  nin: yup.string(),
  bvn: yup.string(),
  religion: yup.string(),
  status: yup.string().required("Status is a required field"),
  whatsapp: yup.string().required("Whatsapp is a required field"),
});

const Profile = () => {
  const { user, defaultBranchUUid } = useAppSelector(
    (state) => state.appUserConfig
  );
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const { mode } = useThemeMode();
  const [phoneData, setPhoneData] = useState<any>();
  const [whatsappData, setWhatsappData] = useState<any>();
  const dispatch = useAppDispatch();
  const [branch, setBranch] = useState(defaultBranchUUid);

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: IFormInput) => {
    const payload = {
      ...data,
      company: branch,
      phone_code: phoneData?.countryCode ?? "ng",
      whatsapp_code: whatsappData?.countryCode ?? "ng",
    };
    const res = await updateUser({ ...payload, uuid: user?.uuid });
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Updated");
        dispatch(updateUserRecord(res.data.data));
      } else toast.error(`${res.data.data} - ${res.data.message as string}`);
    } else toast.error("Unable to update record");
  };

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("phone", user.phone);
      setValue("nin", user.nin);
      setValue("religion", user.religion);
      setValue("bvn", user.bvn);
      setValue("status", user.status.toString());
      setValue("whatsapp", user.whatsapp ?? "");
    }
  }, []);

  const religionOption = [
    "christianity",
    "Muslim",
    "Traditional worshiper",
    "Others",
  ];

  return (
    <div className="flex items-center justify-center mt-10">
      <div className="xl:max-w-xl w-full px-2 xl:px-0">
        <form className="p-3 pb-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-1">
            <div
              className="grid grid-cols-2 gap-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Label value="Name" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Name"
                  {...register("name")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Email" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Email"
                  {...register("email")}
                  color={errors?.email ? "failure" : "gray"}
                  helperText={
                    errors.email && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.email?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Phone" />
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <PhoneInput
                        value={value}
                        onChange={(phone, countryData) => {
                          onChange(phone);
                          setPhoneData(countryData);
                        }}
                        country={lowerCase(user?.country?.code)}
                        disabled={isLoading}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                        }}
                        inputStyle={{
                          width: "100%",
                          paddingRight: "5px",
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                          color: mode === "light" ? "black" : "white",
                        }}
                        dropdownStyle={{
                          position: "absolute",
                          top: -20,
                          left: 0,
                        }}
                      />
                      {errors.phone && (
                        <span className="font-medium text-[10px] text-red-500">
                          {" "}
                          {errors.phone?.message}!
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>
              <div>
                <Label value="status" />
                <Select
                  sizing="sm"
                  disabled={isLoading}
                  {...register("status")}
                  color={errors?.status ? "failure" : "gray"}
                  helperText={
                    errors.status && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.status?.message}!
                      </span>
                    )
                  }
                >
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                </Select>
              </div>
              <div>
                <Label value="Nin" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="NIN"
                  {...register("nin")}
                />
              </div>
              <div>
                <Label value="BVN" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="BVN"
                  {...register("bvn")}
                />
              </div>
              <div>
                <Label value="Religion" />
                <Select
                  sizing="sm"
                  disabled={isLoading}
                  {...register("religion")}
                >
                  <option value="" disabled hidden>
                    Select a Religion
                  </option>
                  {religionOption.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <Label value="Whatsapp" />
                <Controller
                  name="whatsapp"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <PhoneInput
                        value={value}
                        onChange={(phone, countryData) => {
                          onChange(phone);
                          setWhatsappData(countryData);
                        }}
                        country={lowerCase(user?.country?.code)}
                        disabled={isLoading}
                        inputProps={{
                          name: "whatsapp",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          backgroundColor: errors?.whatsapp
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.whatsapp
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                        }}
                        inputStyle={{
                          width: "100%",
                          paddingRight: "5px",
                          backgroundColor: errors?.whatsapp
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.whatsapp
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                          color: mode === "light" ? "black" : "white",
                        }}
                        dropdownStyle={{
                          position: "absolute",
                          top: -20,
                          left: 0,
                        }}
                      />
                      {errors.whatsapp && (
                        <span className="font-medium text-[10px] text-red-500">
                          {" "}
                          {errors.whatsapp?.message}!
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>
              <div>
                <Label value="Company" />
                <BranchSwitcher
                  sizing="sm"
                  defaultCompany={branch}
                  onChange={(value) => setBranch(value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center justify-end">
            <Button
              disabled={isLoading}
              isProcessing={isLoading}
              size="xs"
              className="rounded-sm"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
