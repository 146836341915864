import React, { useState } from "react";
import { useGetPaymentQuery } from "../../redux/queries/Payments";
import PaginationControl from "../../components/PaginationControl";
import moment from "moment";
import utills from "../../lib/functions";
import { GrTransaction } from "react-icons/gr";

interface CustomerTransactionsProps {
  companyId: string;
}

const CustomerTransactions = ({ companyId }: CustomerTransactionsProps) => {
  const [filters, setFilters] = useState({
    limit: "0,10",
    user: companyId,
  });
  const { data: paymentHistories, isLoading } = useGetPaymentQuery(filters);
  console.log(paymentHistories);

  return (
    <div>
      <div className="bg-white mt-4 p-2 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-center gap-5">
        <div className="bg-gray-100 dark:bg-gray-800 h-18 rounded-lg p-3 flex flex-col justify-between w-48">
          <div className="flex justify-between items-center">
            <span className="text-xs font-semibold text-gray-700 dark:text-white">
              Total Balance
            </span>
            <div className="bg-white rounded p-1 flex items-center justify-center">
              <GrTransaction className="text-red-500" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-xl">
            <span>
              {utills._currency_format(
                Number(paymentHistories?.total ?? 0),
                "NGN"
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <PaginationControl
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={paymentHistories?.data}
          total={paymentHistories?.count ?? 0}
        >
          <table className="w-full text-[10px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Trans. Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Debit(₦)
                </th>
                <th scope="col" className="px-6 py-3">
                  Credit(₦)
                </th>
                <th scope="col" className="px-6 py-3">
                  Payment type
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentHistories?.data?.map((item) => (
                <tr
                  key={item.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4">
                    {moment(item.crdate).format("LLL")}
                  </td>
                  <td className="px-6 py-4">{item.description}</td>
                  <td className="px-6 py-4">
                    {Number(item.amount) < 0
                      ? utills._figure_format(
                          Number(item.amount.replace("-", ""))
                        )
                      : "-"}
                  </td>
                  <td className="px-6 py-4">
                    {Number(item.amount) >= 0
                      ? utills._figure_format(Number(item.amount))
                      : "-"}
                  </td>
                  <td className="px-6 py-4">{item.gateway}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </PaginationControl>
      </div>
    </div>
  );
};

export default CustomerTransactions;
