import { Navigate, Route, Routes } from "react-router-dom";
import { openRoutes, authRoutes } from "./data";
import Layout from "../layout";
import ProtectedRoute from "./ProtectedRoute";
import AuthChecker from "./AuthChecker";
import POS from "../POS";

const index = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      {openRoutes.map((rout) => (
        <Route
          key={rout.route}
          element={
            <AuthChecker>
              <rout.component />
            </AuthChecker>
          }
          path={rout.route}
        />
      ))}
      {authRoutes.map((rout) => (
        <Route
          key={rout.route}
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route element={<rout.component />} path={rout.route} />
        </Route>
      ))}
      <Route
        path="pos"
        element={
          <ProtectedRoute>
            <POS />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default index;
