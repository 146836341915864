import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Alert,
  Button,
  Label,
  Select,
  Spinner,
  TextInput,
  Tooltip,
  Textarea,
} from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import ImageView from "../../components/ImageView";
import moment from "moment";
import { FaEdit, FaTasks } from "react-icons/fa";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../redux/queries/users";
import { useGetPermissionsQuery } from "../../redux/queries/permissions";
import { useAppSelector } from "../../lib/hook";
import toast from "react-hot-toast";
import TasksTable from "./TasksTable";
import {
  useCreateTaskMutation,
  useGetTaskNamesQuery,
} from "../../redux/queries/tasks";
import BundleTableTask from "./BundleTableTask";
import { kpiValueTypeArr, monthsList, yearList } from "../../route/data";
import { MdPayments } from "react-icons/md";
import EachPayout from "./EachPayout";

const cards = [
  { title: "Edit", icon: FaEdit, route: "edit", modal: true },
  { title: "Tasks", icon: FaTasks, route: "tasks", modal: false },
  { title: "Payout", icon: MdPayments, route: "payout", modal: false },
];

const ManageEmployee = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: user, isLoading } = useGetUserQuery(id ?? "");
  const [page, setPage] = useState("index");
  const [screen, setScreen] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  if (isLoading) {
    return (
      <div className="flex justify-center pt-7">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Breadcrumb title={`Employee - ${user?.data.name}`} />

      {user?.status !== 200 ? (
        <div>
          <Alert color="failure" icon={HiInformationCircle}>
            <span className="font-medium">Error!</span> User not found.
          </Alert>
        </div>
      ) : (
        <div className="flex items-start gap-4 mt-5">
          {page == "tasks" ? (
            <div className="w-[340px]">
              <BundleTableTask
                userId={user?.data?.company?.uuid}
                staffId={user?.data?.uuid}
              />
            </div>
          ) : (
            <div className="w-[340px] bg-white dark:bg-gray-800 rounded-t-lg">
              <div className="bg-gray-200 dark:bg-gray-700 h-12 flex justify-center items-Idcenter rounded-t-lg">
                <span className="text-[14px] font-semibold text-[#167490] flex justify-center items-center">
                  {user.data.name}
                </span>
              </div>
              <div className="p-3">
                <div className="flex justify-center py-4">
                  <ImageView
                    url={user.data.avatar_link}
                    className="w-28 h-28"
                  />
                </div>
                <div>
                  <div className="relative overflow-x-auto shadow-sm">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <tbody>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Company</b>
                          </td>
                          <td className="px-5 py-3">
                            {user.data.company.name ?? "-"}
                          </td>
                        </tr>

                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Name</b>
                          </td>
                          <td className="px-5 py-3">{user.data.name ?? "-"}</td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Email</b>
                          </td>
                          <td className="px-5 py-3 truncate ... max-w-8">
                            <Tooltip content={user.data.email ?? "-"}>
                              {user.data.email ?? "-"}
                            </Tooltip>
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Phone No.</b>
                          </td>
                          <td className="px-5 py-3">
                            {user.data.phone ?? "-"}
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Country</b>
                          </td>
                          <td className="px-5 py-3">
                            {user.data.country?.name ?? "-"}
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Status</b>
                          </td>
                          <td className="px-5 py-3">
                            {user.data.status === 1 ? "Active" : "Inactive"}
                          </td>
                        </tr>
                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                          <td className="px-5 py-3">
                            <b>Date</b>
                          </td>
                          <td className="px-5 py-3">
                            {moment(user.data.crdate).format("LLL")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex-1">
            <div className="bg-white dark:bg-gray-800 p-2 rounded-t-lg flex items-center justify-between">
              <Button
                size="xs"
                className="rounded-sm"
                color="gray"
                onClick={() => {
                  if (page === "index") navigate("/employees");
                  else setPage("index");
                }}
              >
                Go Back
              </Button>

              {page === "tasks" && (
                <Button
                  className="rounded-sm"
                  size="sm"
                  onClick={() => {
                    setScreen("addTask");
                    setIsOpen(true);
                  }}
                >
                  Add Task
                </Button>
              )}
            </div>

            {page === "index" && (
              <div className="mt-5 grid grid-cols-4 gap-5 px-3">
                {cards.map((card) => (
                  <div
                    onClick={() => {
                      if (card.modal) {
                        setScreen(card.route);
                        setIsOpen(true);
                      } else setPage(card.route);
                    }}
                    key={card.route}
                    className="cursor-pointer bg-white dark:bg-gray-800 hover:scale-[1.1] transition-all w-32 h-32 flex justify-center items-center rounded-lg"
                  >
                    <div className="flex flex-col justify-center items-center">
                      <card.icon className="text-3xl text-[#167490]" />
                      <span className="text-xs text-gray-700 font-semibold dark:text-gray-200">
                        {card.title}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {page === "tasks" && <TasksTable />}
            {page === "payout" && <EachPayout staff={user?.data?.uuid} />}
          </div>
        </div>
      )}

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {screen === "edit" && (
          <EditUser user={user?.data} close={() => setIsOpen(false)} />
        )}
        {screen === "addTask" && (
          <AddTask user={user?.data} close={() => setIsOpen(false)} />
        )}
      </Modal>
    </div>
  );
};

export default ManageEmployee;

interface UserCard {
  user: User | undefined;
  close: () => void;
}

interface IFormInput {
  name: string;
  email: string;
  phone: string;
  status: string;
  access: string;
  nin: string;
  bvn: string;
  religion: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup.string().required("Email is a required field"),
  phone: yup.string().required("Phone number is a required field"),
  nin: yup.string().required("Nin is a required field"),
  bvn: yup.string().required("Bvn is a required field"),
  religion: yup.string().required("Religion is a required field"),
  status: yup.string().required("Status is a required field"),
  access: yup.string().required("Access is a required field"),
});
const EditUser = (props: UserCard) => {
  const { close, user } = props;
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const { data: permissions } = useGetPermissionsQuery({
    company: user?.company.uuid ?? defaultBranchUUid,
  });
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: IFormInput) => {
    const res = await updateUser({ ...data, uuid: user?.uuid });
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Updated");
        close();
      } else
        toast.error(
          `${res.data.data.join("-")} - ${res.data.message as string}`
        );
    } else toast.error("Unable to update record");
  };

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("phone", user.phone);
      setValue("nin", user.nin);
      setValue("religion", user.religion);
      setValue("bvn", user.bvn);
      setValue("status", user.status.toString());
    }
  }, []);

  const religionOption = [
    "christianity",
    "Muslim",
    "Traditional worshiper",
    "Others",
  ];

  return (
    <div className="w-[600px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">
          Edit Customer Info
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="bg-white p-3 pb-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-3">
          <div>
            <ImageView url={user?.avatar_link} className="w-20 h-20" />
          </div>
          <div className="flex-1">
            <div
              className="grid grid-cols-2 gap-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Label value="Name" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Name"
                  {...register("name")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Email" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Email"
                  {...register("email")}
                  color={errors?.email ? "failure" : "gray"}
                  helperText={
                    errors.email && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.email?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Phone" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Phone"
                  {...register("phone")}
                  color={errors?.phone ? "failure" : "gray"}
                  helperText={
                    errors.phone && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.phone?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="status" />
                <Select
                  sizing="sm"
                  disabled={isLoading}
                  {...register("status")}
                  color={errors?.status ? "failure" : "gray"}
                  helperText={
                    errors.status && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.status?.message}!
                      </span>
                    )
                  }
                >
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                </Select>
              </div>
              <div>
                <Label value="Access" />
                <Select
                  sizing="sm"
                  disabled={isLoading}
                  {...register("access")}
                  color={errors?.access ? "failure" : "gray"}
                  helperText={
                    errors.access && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.access?.message}
                      </span>
                    )
                  }
                >
                  {permissions?.data.map((item) => (
                    <option key={item.uuid} value={item.uuid}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <Label value="Nin" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="NIN"
                  {...register("nin")}
                  color={errors?.nin ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.nin?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="BVN" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="BVN"
                  {...register("bvn")}
                  color={errors?.bvn ? "failure" : "gray"}
                  helperText={
                    errors.bvn && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.bvn?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Religion" />
                <Select
                  sizing="sm"
                  disabled={isLoading}
                  {...register("religion")}
                  color={errors?.religion ? "failure" : "gray"}
                  helperText={
                    errors.religion && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.religion?.message}
                      </span>
                    )
                  }
                >
                  <option value="" disabled hidden>
                    Select a Religion
                  </option>
                  {religionOption.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 flex items-center justify-end">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            size="xs"
            className="rounded-sm"
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

interface AddCardProps {
  user: User | undefined;
  close: () => void;
}

interface IFormInputAdd {
  task_id: number;
  description: string;
  kpi_value_type: number;
  kpi_target?: number | null;
  kpi_target_assigned?: number | null;
  //kpi_performance_quantity?: number | null;
  salary: number | null;
  month: number | null;
  year: string;
}

const AddTask = (props: AddCardProps) => {
  const { close, user } = props;
  const { data: kpiTaskNames } = useGetTaskNamesQuery();
  const [createTask, { isLoading }] = useCreateTaskMutation();
  const [tasksList, setTasksList] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [type, setType] = useState("3");
  const [basedTypes, setBasedTypes] = useState<any>([]);

  const { getValues, setValue, register, handleSubmit, watch } =
    useForm<IFormInputAdd>();
  const task_name_id = Number(watch()?.task_id);

  useEffect(() => {
    setBasedTypes([]);
    const filteredItem = kpiTaskNames?.data?.find(
      (item) => item.id === task_name_id
    );

    if (filteredItem) {
      const rules = filteredItem.rules;

      const filteredItems = kpiValueTypeArr.filter((item) =>
        rules.includes(item.value)
      );
      setBasedTypes((prev: any) => [...prev, ...filteredItems]);
    }
  }, [task_name_id, kpiTaskNames]);

  useEffect(() => {
    setValue("task_id", 0);
    setValue("description", "");
    setValue("kpi_value_type", 3);
    setValue("kpi_target", null);
    setValue("kpi_target_assigned", null);
    //setValue("kpi_performance_quantity", null);
    setValue("salary", null);
    setValue("month", null);
    setValue("year", "");
  }, []);

  const ValidateForm = () => {
    const newErrors: {
      task_id?: string;
      desc?: string;
      type?: string;
      target?: string;
      assigned?: string;
      perform?: string;
      salary?: string;
      month?: string;
      year?: string;
    } = {};
    const {
      description,
      task_id,
      kpi_value_type,
      kpi_target,
      kpi_target_assigned,
      salary,
      month,
      year,
    } = getValues();
    const allowPer = kpi_value_type == 1;
    const allowTime = kpi_value_type == 2;
    if (task_id == 0) {
      newErrors.task_id = "kpiTask is required";
    }

    if (description == "") {
      newErrors.desc = "description field is required";
    }

    if (kpi_value_type == 3) {
      newErrors.type = "kpi value type is required";
    }

    if (!allowTime) {
      if (kpi_target === null) {
        newErrors.target = "kpi target is required";
      } else if (
        allowPer &&
        typeof kpi_target === "number" &&
        kpi_target > 100
      ) {
        newErrors.target = "kpi target is should be in percentage";
      }
    }

    if (!allowPer && kpi_target_assigned === null) {
      newErrors.assigned = "kpi target assigned is required";
    }

    if (salary === null) {
      newErrors.salary = "salary is required";
    }

    if (month === null) {
      newErrors.month = "month is required";
    }

    if (year == "") {
      newErrors.year = "year field is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const ValidateFormTwo = () => {
    const newErrors: {
      salary?: string;
      month?: string;
      year?: string;
    } = {};
    const { salary, month, year } = getValues();

    if (salary === null) {
      newErrors.salary = "salary is required";
    }

    if (month === null) {
      newErrors.month = "month is required";
    }

    if (year == "") {
      newErrors.year = "year field is required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const addTask = async () => {
    const data = getValues();

    const {
      description,
      //kpi_performance_quantity,
      kpi_target,
      kpi_target_assigned,
      kpi_value_type,
      task_id,
    } = data;

    const task = {
      kpi_uuid: task_id,
      description,
      //kpi_performance_quantity,
      kpi_target,
      kpi_target_assigned,
      kpi_value_type,
      task_id,
    };

    if (ValidateForm()) {
      setTasksList([...tasksList, task]);
      setValue("task_id", 0);
      setValue("description", "");
      setValue("kpi_value_type", 2);
      setValue("kpi_target", null);
      setValue("kpi_target_assigned", null);
      //setValue("kpi_performance_quantity", null);
    }
  };

  const onSubmit = async (data: IFormInputAdd) => {
    const payload = {
      year: data?.year,
      staff: user?.uuid,
      month: data?.month,
      salary: data?.salary,
      company_uuid: user?.company?.uuid,
      tasks: tasksList,
    };

    if (ValidateFormTwo()) {
      const res = (await createTask(payload)) as any;
      console.log(res);
      if (res?.data?.status === 200) {
        toast.success("Task created successfully!!!");
        close();
      } else {
        //console.log(data);
        toast.error("Something went wrong");
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="w-[600px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Create Task</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="bg-white p-3 pb-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-3">
          <div className="mt-3 border-b border-grey-800 pb-5">
            <span className="font-semibold dark:text-gray-800">
              Header Task
            </span>
            <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-3">
              <div>
                <Label className="dark:text-gray-800" value="Salary" />
                <TextInput
                  placeholder="add salary"
                  {...register("salary")}
                  sizing="md"
                  type="number"
                  step="any"
                  color={errors.salary ? "failure" : "gray"}
                  helperText={
                    errors.salary && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.salary}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label className="dark:text-gray-800" value="Month" />
                <Select
                  sizing="md"
                  {...register("month")}
                  color={errors?.month ? "failure" : "gray"}
                  helperText={
                    errors.month && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.month}
                      </span>
                    )
                  }
                >
                  <option value={0} hidden>
                    Select a month
                  </option>
                  {monthsList.map((item: any) => (
                    <option key={item.value} value={item.value}>
                      {item.month}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <Label className="dark:text-gray-800" value="Year" />
                <Select
                  sizing="md"
                  {...register("year")}
                  color={errors?.year ? "failure" : "gray"}
                  helperText={
                    errors.year && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.year}
                      </span>
                    )
                  }
                >
                  <option value="" hidden>
                    Select a year
                  </option>
                  {yearList.map((item: string) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          {tasksList.length > 0 && (
            <div className="border-b border-grey-800 flex gap-3 items-center mb-4 pt-2 pb-5 flex-wrap">
              {tasksList?.map((_: any, i: number) => (
                <span
                  key={i}
                  className="bg-gray-300 text-black p-1 text-xs font-medium border border-gray-400 rounded"
                >
                  {"Task " + i}
                </span>
              ))}
            </div>
          )}
          <div>
            <Label className="dark:text-gray-800" value="KPI Task" />
            <Select
              sizing="md"
              {...register("task_id")}
              color={errors?.task_id ? "failure" : "gray"}
              helperText={
                errors.task_id && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.task_id}!
                  </span>
                )
              }
            >
              <option value={0} hidden>
                Select a KPI Task
              </option>
              {kpiTaskNames?.data?.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>
          <div>
            <Label className="dark:text-gray-800" value="Task desc" />
            <Textarea
              placeholder="KPI Task description"
              {...register("description")}
              rows={4}
              color={errors?.desc ? "failure" : "gray"}
              helperText={
                errors.desc && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.desc}!
                  </span>
                )
              }
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div>
              <Label className="dark:text-gray-800" value="KPI value type" />
              <Select
                sizing="md"
                {...register("kpi_value_type")}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                color={errors?.type ? "failure" : "gray"}
                helperText={
                  errors.type && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.type}!
                    </span>
                  )
                }
              >
                <option value={3} hidden>
                  Select a KPI value type
                </option>
                {basedTypes?.map((item: any) => (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>
            <div>
              <Label className="dark:text-gray-800" value="kpi Target" />
              <TextInput
                sizing="md"
                disabled={type === "2"}
                type="number"
                step="any"
                placeholder="kpi Target"
                {...register("kpi_target")}
                color={errors?.target ? "failure" : "gray"}
                helperText={
                  errors.target && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.target}!
                    </span>
                  )
                }
              />
            </div>
            <div>
              <Label
                className="dark:text-gray-800"
                value="kpi Target Assigned"
              />
              <TextInput
                disabled={type === "1"}
                sizing="md"
                type="number"
                step="any"
                placeholder="kpi Target Assigned"
                {...register("kpi_target_assigned")}
                color={errors?.assigned ? "failure" : "gray"}
                helperText={
                  errors.assigned && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.assigned}!
                    </span>
                  )
                }
              />
            </div>
            {/* <div>
              <Label className="dark:text-gray-800" value="kpi Performance" />
              <TextInput
                sizing="md"
                type="number"
                placeholder="kpi Performance"
                {...register("kpi_performance_quantity")}
                color={errors.perform ? "failure" : "gray"}
                helperText={
                  errors.perform && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.perform}!
                    </span>
                  )
                }
              />
            </div> */}
          </div>
        </div>
        <div className="mt-3 flex items-center justify-between">
          <Button size="xs" className="rounded-sm" onClick={() => addTask()}>
            ADD
          </Button>

          <Button
            isProcessing={isLoading}
            size="xs"
            className="rounded-sm"
            type="submit"
            disabled={tasksList.length === 0}
          >
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};
