import { FormEvent, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import {
  useGetChequesQuery,
  useMarkAsPaidMutation,
  useUpdateChequeMutation,
} from "../../redux/queries/Payments";
import { useAppSelector } from "../../lib/hook";
import moment from "moment";
import { FcCheckmark } from "react-icons/fc";
import { Button, Datepicker, Label, TextInput } from "flowbite-react";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import toast from "react-hot-toast";
import { CiEdit } from "react-icons/ci";
import CustomPagination from "../../components/CustomPagination";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import type { CustomFlowbiteTheme } from "flowbite-react";

interface PayloadFilter {
  cheque_no?: number;
  payment_uuid?: string;
}

const Cheques = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [open, setOpen] = useState(false);
  const [uuid, setUuid] = useState("");
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: user?.company?.uuid,
  });

  const { data: chequeData, isLoading } = useGetChequesQuery(filters);
  const [markAsPaid, { isLoading: isMarkingPaid }] = useMarkAsPaidMutation();

  const handleMark = async (payload: PayloadFilter) => {
    const { data } = (await markAsPaid(payload)) as any;
    if (
      data?.status === 100 ||
      data?.message === "Already marked as payment successfull"
    ) {
      toast.success("Paid successfully!!!");
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <div>
      <Breadcrumb title="Cheques" />
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={chequeData?.data}
            total={chequeData?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Cheque no
                  </th>
                  <th scope="col" className="px-6 py-3 flex items-center gap-2">
                    Staff
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Pharmacy
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Cheque Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Cheque Post-Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Make As Paid
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {chequeData?.data?.map((item: ChequeDetails) => (
                  <tr
                    key={item.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">#{item.json_init.cheque_no}</td>
                    <td className="px-6 py-4">
                      {item.staff === null ? "-" : item.staff?.name}
                    </td>
                    <td className="px-6 py-4">{item.company.name}</td>
                    <td className="px-6 py-4">
                      {moment(item.crdate).format("LLL")}
                    </td>
                    <td className="px-6 py-4">
                      {moment(item.json_init.cheque_post_date).format("LLL")}
                    </td>
                    <td className="px-6 py-4">
                      {item.status === 1 ? (
                        <FcCheckmark className="text-2xl font-bold" />
                      ) : (
                        <Button
                          size="xs"
                          isProcessing={isMarkingPaid}
                          onClick={() => {
                            handleMark({
                              cheque_no: item.json_init.cheque_no,
                              payment_uuid: item.uuid,
                            });
                          }}
                        >
                          Pay now
                          <MdOutlineArrowRightAlt className="ml-2 h-3 w-3" />
                        </Button>
                      )}
                    </td>
                    <td className="px-6 py-4">
                      <Button
                        size="xs"
                        isProcessing={isMarkingPaid}
                        onClick={() => {
                          setOpen(true);
                          setUuid(item.uuid);
                        }}
                      >
                        <CiEdit className="mr-2 h-3 w-3" />
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <EditCheque
          close={() => {
            setOpen(false);
            setUuid("");
          }}
          uuid={uuid}
        />
      </Modal>
    </div>
  );
};

export default Cheques;

interface IRefunds {
  close: () => void;
  uuid: string;
}

const EditCheque = (props: IRefunds) => {
  const { close, uuid } = props;
  const [fields, setFields] = useState({
    cheque_no: "",
    cheque_post_date: "",
  });
  const [updateCheque, { isLoading }] = useUpdateChequeMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = {
      payment_uuid: uuid,
      cheque_no: fields.cheque_no,
      cheque_post_date: fields.cheque_post_date,
      uuid: uuid,
    };
    const res = await updateCheque(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Updated");
        close();
      } else if (res.data.status === 100) {
        toast(res.data.message);
        close();
      }
    } else toast.error("Unable to update cheque!!!");
  };

  const customTheme: CustomFlowbiteTheme["datepicker"] = {
    popup: {
      root: {
        base: "absolute z-50 block pt-2",
        inline: "relative top-[-20px] z-50",
        inner:
          "inline-block rounded-lg bg-white p-4 shadow-lg bg-red-500 dark:bg-gray-700",
      },
    },
  };
  return (
    <div className="w-[400px] bg-white dark:bg-gray-800">
      <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
        <span className="text-white font-semibold text-sm">Return Item</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="p-5" onSubmit={handleSubmit}>
        <div className="">
          <div className="mb-2 block">
            <Label htmlFor="chq_post_date" value="Post Date" />
          </div>
          <Datepicker
            theme={customTheme}
            value={fields.cheque_post_date}
            onSelectedDateChanged={(date) =>
              setFields((prev) => ({
                ...prev,
                cheque_post_date: moment(date).format("YYYY-MM-DD"),
              }))
            }
            name="date"
            datepicker-format={"YYYY-MM-DD"}
            className="z-10 overflow-visible"
          />
        </div>

        <div className="mt-14">
          <div className="mb-2 block">
            <Label htmlFor="chq_no" value="Cheque Number" />
          </div>

          <TextInput
            value={fields.cheque_no}
            type="number"
            step="any"
            onChange={(e) =>
              setFields((prev) => ({ ...prev, cheque_no: e.target.value }))
            }
          />
        </div>
        <Button
          disabled={isLoading}
          isProcessing={isLoading}
          type="submit"
          className="rounded-sm w-full mt-40"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};
