import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Label, TextInput } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import { useUpdateUserMutation } from "../../redux/queries/users";
import { setPinStatus, updateUserRecord } from "../../redux/slices/appUser";

interface IFormInput {
  new_pin: string;
  verify_new_pin: string;
  password: string;
}

const schema = yup.object().shape({
  new_pin: yup
    .string()
    .matches(/^\d{4}$/, "New pin must be a 4-digit number")
    .required("New pin is required"),
  password: yup.string().required("Password is required"),
  verify_new_pin: yup
    .string()
    .required("Pin do not match")
    .test("match", "Pin do not match", function (value) {
      return value === this.parent.new_pin;
    }),
});

const PinSetting = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: IFormInput) => {
    const res = await updateUser({
      uuid: user?.uuid,
      pin: data.new_pin,
      password: data.password,
    });
    if ("data" in res) {
      if (res.data.status !== 200) {
        toast.error(res.data.message);
      } else {
        toast.success("Pin set successfully!!!");
        dispatch(updateUserRecord(res.data.data));
        dispatch(setPinStatus(true));
      }
    } else toast.error("Error- Action Failed!!!");
  };
  
  return (
    <form
      className="mt-7 rounded-t-lg"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid grid-cols-3 gap-3 p-4">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="old" value="New Pin" />
          </div>
          <TextInput
            id="old"
            type="password"
            disabled={isLoading}
            autoComplete="off"
            className="hide-spin-buttons"
            {...register("new_pin")}
            color={errors?.new_pin ? "failure" : "gray"}
            helperText={
              errors.new_pin && (
                <span className="font-medium text-[10px]">
                  {" "}
                  {errors.new_pin?.message}!
                </span>
              )
            }
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="new1" value="Re-Type Pin" />
          </div>
          <TextInput
            id="new1"
            className="hide-spin-buttons"
            type="password"
            disabled={isLoading}
            autoComplete="off"
            {...register("verify_new_pin")}
            color={errors?.verify_new_pin ? "failure" : "gray"}
            helperText={
              errors.verify_new_pin && (
                <span className="font-medium text-[10px]">
                  {" "}
                  {errors.verify_new_pin?.message}!
                </span>
              )
            }
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="new2" value="Password" />
          </div>
          <TextInput
            id="new2"
            className="hide-spin-buttons"
            type="password"
            disabled={isLoading}
            autoComplete="off"
            {...register("password")}
            color={errors?.password ? "failure" : "gray"}
            helperText={
              errors.password && (
                <span className="font-medium text-[10px]">
                  {" "}
                  {errors.password?.message}!
                </span>
              )
            }
          />
        </div>
      </div>
      <div className="flex justify-end mb-3 p-3">
        <Button
          disabled={isLoading}
          isProcessing={isLoading}
          type="submit"
          className="rounded-md"
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default PinSetting;
