import { api } from "../baseConfig";

interface PayloadFilter {
  limit: string;
  name?: string;
  status?: number;
  company?: string;
  category?: string;
  item_uuid?: string;
  supplier?: string
}

interface SingleProductResponse {
  status: number;
  message: string;
  data: Product;
}

interface EditProductHistoryResponse {
  data: EditProductHistory[];
  status: number;
  count: number;
}

export const productSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<ProductResponse, PayloadFilter>({
      query: (filters) => ({
        url: "/items",
        method: "GET",
        params: filters,
      }),
      providesTags: ["Products"],
    }),
    getProduct: builder.query<SingleProductResponse, string>({
      query: (id) => ({
        url: `/items/${id}`,
        method: "GET",
      }),
      providesTags: ["Products"],
    }),
    updateProduct: builder.mutation<SingleProductResponse, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/items/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Products"],
    }),
    createProduct: builder.mutation<CreatProductResponse, any>({
      query: (data) => ({
        url: "/items",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Products"],
    }),
    deleteProduct: builder.mutation<CreatProductResponse, string>({
      query: (id) => ({
        url: `/items/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Products"],
    }),
    getProductEditHistory: builder.query<
      EditProductHistoryResponse,
      PayloadFilter
    >({
      query: (filters) => ({
        url: `/items/histories`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Products"],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useLazyGetProductsQuery,
  useLazyGetProductQuery,
  useUpdateProductMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductEditHistoryQuery,
} = productSlice;
